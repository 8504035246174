import React, { useEffect } from "react";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import CartImage from "../../images/trending1.png";

function Return() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {/* <Header></Header> */}
      <div className="container">
        <div className="row">
          <div className="policyPages p-both-50">
          <h2>Returns & Exchanges</h2>
<h2>Introduction:</h2>
    1. The domain http://www.vastrakala.com including any web-pages thereof is the web-site ("Website") of VastraKala having its Registered Office at Vastrakala, Mandi Road , Gandhi Chowk , Phagwara-144401, Punjab . <br />
    2. This policy sets out the terms and conditions, on fulfillment of which Vastrakala may permit you to either (i) return the products and/or (ii) exchange them for other products.<br />
    3. This policy applies to all the products sold by VastraKala through the Website<br />
    4. You are requested to read our Terms and Conditions and other policies available on the Website in addition to this policy as the same are binding upon you.<br />
     <br /><br />

<h2>Conditions for Returns or Exchange:</h2>
VastraKala has a simple and hassle-free return and/or exchange policy for certain products purchased from the Website provided the following conditions are fulfilled:<br />
    1. For apparel, we offer a 6-day return policy. However, customized styles are non-returnable/non-exchangeable. Jewellery and accessories are non-returnable/non-exchangeable. Custom Made are also non-returnable/non-exchangeable.<br />
    2. Products which are on warehouse sale are also non-returnable/non-exchangeable.<br />
    3. For Returns or Exchange timelines, Please check the return policy under the shipping details of any product. VastraKala may update this from time to time and without any prior intimation to you.<br />
    4. The product must be returned in its original condition along with the invoice, all labels, tags, authenticity cards, dust bags, designer boxes, hangers, freebies, if any, and other packaging intact sold as a part of the product.<br />
    5. Any product sought to be returned or exchanged, should be unworn, unused, or unwashed. Products that are found to be worn, used, damaged, or soiled will not be accepted for return or exchange.<br />
    6. Any footwear sought to be returned or exchanged, should be unused. Thus, footwear worn for trial purposes must be done on a carpeted surface. Footwear with worn soles will not be accepted for return or exchange.<br />
    7. Products returned under this policy will be subject to quality testing/inspection.<br />
    8. Product's original tags, if attached, must be intact for a successful return. If the original tags are missing, VastraKala may decline the return request and send the product back to the customer.<br />
    9. In case of any return/exchange/alteration request for international orders, a restocking or return-handling-fee of USD 50 or 20% of the product-selling value, whichever is lower will be applicable.<br />
    10. In case of any return/exchange/alteration request for India orders, certain return-requests may incur a restocking or return-handling-fee of INR 200 or 5% of product-selling-price. The exact return-handling-fee for a specific user will depend on the frequency of returns on Vastrakala. Returns will be blocked for those users whose return rate is greater than 60%.<br />
    11. Return-handling-fee will be deducted from the amount that is refunded to the Vastrakala wallet/original source of payment when executing the refund.<br />
    12. Complaints for missing/damaged/wrong items delivered should be raised within 48 hours of delivery.<br />
    13. In the event that the product to be returned satisfies the Statutory Refund Criteria, you will be entitled to a refund of the consideration for such product which will be credited to either your Vastrakala Wallet or the original account/mode of payment. Whereas "Statutory Refund Criteria” means any one of the following cases –<br />
    14. The product purchased by you does not conform to the characteristics or features listed on the website or otherwise agreed to in writing; or<br />
    15. The product purchased by you is delivered late from the stated delivery schedule, except where such delay is on account of unforeseeable circumstances out of vastraKala control or any other force majeure.<br />
    16. In the event that the product to be returned does not satisfy Statutory Refund Criteria in the clause above, but satisfies Store Refund Criteria, you may be entitled to a refund of the consideration for such product in the form of store credit which will be credited to your VastraKala Wallet.<br />
    17. Whereas “Store Refund Criteria” means any one of the following cases –<br />
    18. The product purchased by you is not included in the list of non-returnable or non-exchangeable products under this policy; or<br />
    19. Products customized to your requirements are not liable for refunds unless under the clause above. Under such circumstances, the refunds will be done as store credit only to your VastraKala Wallet.<br />
    20. Notwithstanding anything contained in this policy, Vastrakala reserves the right to reject any request for the return and/or exchange of any products without giving any reasons for the same. In such cases, products will be sent back to the customer at the original shipping address.<br />
    21. VastraKala Wallet shall not exceed the value of the product returned and/or exchanged but may be subject to deductions of any benefits for example any discounts availed, shipping or delivery charges waiver, etc., if any.<br />
    22. Customized or special delivery orders cannot be returned/exchanged.
     <br /><br />
<h2>Why Return handling fee for India orders?</h2>
    1. VastraKala allows ease of shopping with hassle-free return and exchange options.<br />
    2. However, some customers take undue advantage of this service and attempt to abuse it. They take this service for granted and start returning their products more frequently, without realising how their decision is going to adversely affect their return rate.<br />
    3. When customers opt to return a product, the system records their return rate and if the return rate is higher than average, then customers are charged a restocking or return-handling-fee of INR 200 or 5% of product-selling-price, whichever is lower.
     <br /><br />
 
<h2>Returns or Exchange Procedure:</h2>
    1. The returns procedure can also be initiated through the Website by following the below-mentioned steps: <br />
    2. If you purchased from the Website as a guest, please register on the Website using the same email address provided while purchasing the product so that your products appear in your order history. You can initiate the process of return only if the said product appears in your order history.<br />
    3. Alternatively, you can initiate the returns/exchange procedure by writing an e-mail to Info@vastrakala.com .<br />
    4. If you are not the actual purchaser but received the product from some Vastrakala customer, (for example as a gift), you will have to request the concerned VastraKala customer to initiate the return process.<br />
    5. You can return the product by selecting any one of the below options<br />
    6. Ship it back to VastraKala by sending it to the address provided by us. You will be solely responsible to ensure that the product is delivered to Vastrakala in good condition and that VastraKala will not be held liable for any damage caused during transit.<br />
    7. VastraKala will send you a confirmatory e-mail upon receiving the products. Upon undertaking quality test and inspecting the product, VastraKala will inform you about its decision with respect to acceptance or rejection of the products within 5 (Five) working days of receiving the product.<br />
    8. If VastraKala accepts the return/exchange request, value of the product returned/exchanged will be credited to either your VastraKala Wallet or the original account / mode of payment in 7(Seven) to 10 (Ten) working days of receiving the product. However, if VastraKala rejects the return/exchange we will ship the product back to you.<br />
    9. If you have any questions, issues, or concerns about your return, or are having trouble with the returns process, you can email us at info@vastrakala.com.

          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Return;

import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Loader from "../layout/Loader/Loader";

import Header from "./header";
import Footer from "./footer";
function OrdersDetail({ location }) {
  const [orders, setOrders] = useState([]);
  const [address, setAddress] = useState([]);
  // ---------------
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const [dollar, setDollar] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getorders();
  }, []);

  async function getorders() {
    try {
      var res2 = await fetch(`/api/admin/marque`);
      let data2 = await res2.json();
      setDollar(data2[4].value);
      if (location?.state?.id) {
        setLoading(true);
        var res = await fetch(`/api/admin/orders/${location?.state?.id}`);
        if (res.ok) {
          let data = await res.json();
          console.log("data", data);
          setOrders(data.orders);
          setAddress(data.shipping);
        } else {
          let data = await res.json();
          setOrders([]);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getTotalAmount = () => {
    return orders.reduce((total, row) => total + row.amount_charged, 0);
  };
  const getShippingAmount = () => {
    if (orders.length)
      return orders.reduce(
        (total, row) => Math.floor(total + (row.pay_type === "PayPal" ? row.amount_charged * dollar : row.amount_charged) - row.selling_price),
        0
      );
  };

  return (
    <>
      <Header></Header>
      {loading ? <Loader /> : <></>}
      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-6">
              <div className="pagetitle">
                <h1>Orders Detail</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Orders Details</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 justify-content-end d-flex align-items-center gap-2">
              <button
                type="button"
                className="whiteBtn"
                onClick={handleClickOpen}
              >
                Add Shipping Details
              </button>
              {orders.length > 0 ? (
                <>
                  <a
                    type="button"
                    className="whiteBtn"
                    target="_blank"
                    href={orders[0]["invoice"]}
                    onclick="window.print();"
                  >
                    <i className="fa-solid fa-print"></i> Print Invoice
                  </a>
                  <a
                    type="button"
                    className="whiteBtn"
                    target="_blank"
                    href={orders[0]["dhl"]}
                    onclick="window.print();"
                  >
                    <i className="fa-solid fa-print"></i> Print DHL Receipt
                  </a>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="order-details-table">
                    <div className="tableWrapper">
                      <table>
                        <tr>
                          <td colspan="4">
                            <h3>Order Details</h3>
                          </td>
                          <td colspan="3" className="text-right">
                            <h3>Order Number: {orders.length ? orders[0]["id"] : ""}</h3>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <h3>Customer Details</h3>
                            <p>Customer Name: {address.full_name}</p>
                            {address.s_mobile ? (
                              <p>Customer Phone:
                                {address.s_mobile}</p>
                            ) : (
                              ""
                            )}

                            <p>Email: {address.s_email}</p>
                          </td>
                          <td colspan="3" className="text-right">
                            <h3>Shipping Address</h3>
                            <p>{address.address1} {address.address2}</p>
                            <p>{address.city}, {address.state},{address.zip},{address.country}</p>
                            {/* <p>
                              {`${address.address1} ${address.address2}, 
                                ${address.mobile}, 
                                ${address.city}, 
                                ${address.state},
                                ${address.zip}, ${address.country}`}
                            </p> */}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Item No.</b>
                          </td>
                          <td>
                            <b>Order Date</b>
                          </td>
                          <td>
                            <b>Item</b>
                          </td>
                          <td>
                            <b>Product Quantity</b>
                          </td>
                          {/* <td>
                            <b>Payment Method</b>
                          </td> */}
                          <td>
                            <b>Payment Status</b>
                          </td>
                          <td>
                            <b>Product Price</b>
                          </td>
                        </tr>
                        {orders.length > 0 ? (
                          orders.map((order, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{order.modified}</td>
                                <td>
                                  <div className="cartProductDetail">
                                    <div className="cpImage">
                                      <a>
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}${order.image}`}
                                        />
                                      </a>
                                    </div>
                                    <div className="cpdata">
                                      <a className="ppTitle">
                                        <h3>{order.name}</h3>
                                      </a>
                                      <p>
                                        <b>Category:</b> {order.category}
                                      </p>
                                      <p>
                                        <b>Color:</b> {order.color}
                                      </p>
                                      <p>
                                        <b>Size:</b> {order.size}
                                      </p>
                                      <p>
                                        <b>Basic Customization:</b>{" "}
                                        {order.customization
                                          ? Object.keys(
                                            JSON.parse(order.customization)
                                          )
                                            .filter(
                                              (key) =>
                                                JSON.parse(
                                                  order.customization
                                                )[key]
                                            ) // Filter out keys with empty values
                                            .map((key) => (
                                              <span
                                                key={key}
                                                style={{ display: "block" }}
                                              >
                                                <strong>{key}:</strong>{" "}
                                                {JSON.parse(
                                                  order.customization
                                                )[key].toString()}
                                              </span>
                                            ))
                                          : "Not Available"}
                                      </p>
                                      <p>
                                        <b>Full Customization:</b>{" "}
                                        {order.full_customization
                                          ? Object.keys(
                                            JSON.parse(
                                              order.full_customization
                                            )
                                          ).map((key) => (
                                            <span
                                              key={key}
                                              style={{ display: "block" }}
                                            >
                                              <strong>{key}:</strong>{" "}
                                              {JSON.parse(
                                                order.full_customization
                                              )[key].toString()}
                                            </span>
                                          ))
                                          : "Not Available"}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>{order.quantity}</td>
                                {/* <td>Credit Card</td> */}
                                <td>{order.status_message}</td>
                                <td>Rs {order.selling_price}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}

                        <tr>
                          <td></td>
                          <td>
                            <h3>Shipping</h3>
                          </td>
                          <td>
                            <h3>Rs {getShippingAmount()}</h3>
                          </td>
                          <td></td>
                          <td>
                            <h3>Total</h3>
                          </td>
                          <td>
                            <h3>{orders.length ? orders[0]["pay_type"] === "PayPal" ? "$" : "Rs" : "Rs"} {getTotalAmount()}</h3>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Dialog
        className="manualShipping"
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Add Indian Shipping Details"}
        </DialogTitle>
        <DialogContent>
          <div className="closeBtn" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <form action="">
            <div className="row">
              <div className="col-md-12">
                <label for="">Enter Shipping Tracking Number</label>
                <input class="form-control" type="text" />
              </div>
              <div className="col-md-12">
                <label for="">Enter Shipping Tracking Number</label>
                <input class="form-control" type="text" />
              </div>
              <div className="col-md-12 text-right">
                <input class="blackBtn" type="submit" value="Submit" />
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Footer></Footer>
    </>
  );
}

export default OrdersDetail;

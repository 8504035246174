import React, { useEffect } from "react";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import CartImage from "../../images/trending1.png";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {/* <Header></Header> */}
      <div className="container">
        <div className="row">
          <div className="policyPages p-both-50">
            <h2>Privacy Policy</h2>
            <p>
              This Privacy Policy provides for the practices for handling and
              securing users’ Personal Information (defined hereunder) by
              Vastrakala.
            </p>

            <p>
              This Privacy Policy is applicable to any person (‘User’) who
              purchases, intend to purchase, or inquire about any product(s) or
              service(s) made available by Vastrakala through any of Vastrakala
              customer interface channels including its website & offline
              channels including call centers and offices (collectively referred
              herein as “Sales Channels”).
            </p>

            <p>
              For the purpose of this Privacy Policy, wherever the context so
              requires “you” or “your” shall mean User, and the term “we”, “us”,
              and “our” shall mean Vastrakala. For the purpose of this Privacy
              Policy, Website means the website(s), mobile site(s), and mobile
              app(s).
            </p>

            <p>
              By using or accessing the Website or other Sales Channels, the
              User hereby agrees with the terms of this Privacy Policy and the
              contents herein. If you disagree with this Privacy Policy please
              do not use or access our Website or other Sales Channels.
            </p>

            <p>
              This Privacy Policy does not apply to any website(s), mobile
              sites, and mobile apps of third parties, even if their
              websites/products are linked to our Website. Users should take
              note that information and privacy practices of Vastrakala business
              partners, advertisers, sponsors, or other sites to which
              Vastrakala provides hyperlink(s), may be materially different from
              this Privacy Policy. Accordingly, it is recommended that you
              review the privacy statements and policies of any such third
              parties with whom they interact.
            </p>

            <p>
              This Privacy Policy is an integral part of your User Agreement
              with Vastrakala and all capitalized terms used, but not otherwise
              defined herein, shall have the respective meanings as ascribed to
              them in the User Agreement.
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default PrivacyPolicy;

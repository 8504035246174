import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  SAVE_SHIPPING_INFO,
  ADD_TO_WISHLIST,
  LOGOUT_CART,
  REMOVE_WISHLIST_ITEM,
  UPDATE_CART,
  DELETE_CART,
  INCREASE_QUANTITY, DECREASE_QUANTITY
} from "../constants/cartConstants";

export const cartReducer = (
  state = { cartItems: [], wishlist: [], shippingInfo: [] },
  action
) => {
  switch (action.type) {
    case ADD_TO_CART:
      const item = action.payload;
      return {
        ...state,
        cartItems: [...item],
      };
    case UPDATE_CART:
      let new_product = action.payload
      var updatedList = state.cartItems.filter(function (dictionary) {
        // Replace the condition with your own logic for identifying the dictionary to remove
        return dictionary.product_id == new_product.product_id;
      });
      new_product["quantity"] = 1
      if (updatedList.length > 0) {
        return {
          ...state,
          cartItems: [...state.cartItems],
        };
      }
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };

    case DECREASE_QUANTITY:
      let decrease_product = action.payload
      var updatedList = state.cartItems.map(function (dictionary) {
        // Check if the id matches the targetId
        if (dictionary.product_id == decrease_product) {
          // Increase the quantity by 1
          return { ...dictionary, quantity: dictionary.quantity };
        }
        // If the id doesn't match, keep the dictionary unchanged
        return dictionary;
      });

      if (updatedList.length > 0) {
        return {
          ...state,
          cartItems: updatedList,
        };
      }
      return {
        ...state,
        cartItems: [...state.cartItems],
      };

    case INCREASE_QUANTITY:
      let increase_product = action.payload
      var updatedList = state.cartItems.map(function (dictionary) {
        // Check if the id matches the targetId
        if (dictionary.product_id === increase_product) {
          // Increase the quantity by 1
          return { ...dictionary, quantity: dictionary.quantity };
        }
        // If the id doesn't match, keep the dictionary unchanged
        return dictionary;
      });

      if (updatedList.length > 0) {
        return {
          ...state,
          cartItems: updatedList,
        };
      }
      return {
        ...state,
        cartItems: [...state.cartItems],
      };


    case DELETE_CART:
      let del_product = action.payload
      var updatedList = state.cartItems.filter(function (dictionary) {
        // Replace the condition with your own logic for identifying the dictionary to remove
        return dictionary.product_id !== del_product.product_id;
      });
      return {
        ...state,
        cartItems: [...updatedList],
      };
    case ADD_TO_WISHLIST:
      const items = action.payload;
      return {
        ...state,
        wishlist: [...items],
      };


    case SAVE_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: [...action.payload],
      };
    case LOGOUT_CART:
      return {
        ...state,
        cartItems: [],
        wishlist: [],
        shippingInfo: []
      };

    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import { AgGridReact } from 'ag-grid-react';
import { CsvExportParams } from "ag-grid-community";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Header from "./header";
import Footer from './footer';

function AddUser() {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");
  const [username, setUserName] = useState("");
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  useEffect(() => {
    fetch('/api/admin/products')
      .then(response => response.json())
      .then(data => {
        if (data?.products) {
          setRowData(data.products);
        } else {
          alert("Error");
        }
      });
  }, []);

  function deleteProduct(id) {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "id": id,
      })
    };
    fetch('/api/admin/products', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data?.products) {
          setRowData(flattenData(data.products));
          alert("Product Deleted ID: " + id);
        } else {
          alert("Error");
        }
      });
  }

  const columnDefs = [
    { headerName: "ID", field: "id" },
    { headerName: "User Name", field: "name" },
    { headerName: "User Email", field: "email" },
    { headerName: "Password", field: "password", filter: false },
    { headerName: "Mobile No", field: "mobile" },
    {
      headerName: "Action",
      cellRenderer: ActionRenderer,
      cellRendererParams: { deleteProduct },
      minWidth: 270,
      sortable: false,
      floatingFilter: false,
      filter: false
    },
  ];

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }
  const onFilterTextChange = (e) => {
    setSearchText(e.target.value);
    applyCategoryFilter(e.target.value);
  };

  const applyCategoryFilter = (value) => {
    gridApi.setQuickFilter(value);
  };

  function ActionRenderer(props) {
    return (
      <div className="actions">
        <button className="editBtn"><i className="fa-regular fa-pen-to-square"></i>Edit</button>
        <button className="dltBtn" onClick={() => props.data && deleteProduct(props.data.id)}><i className="fa-regular fa-trash-can"></i>Delete</button>
      </div>
    );
  }

  const defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
    sort: 'asc'
  };

  function flattenData(data) {
    const flattenedData = [];
    data.forEach(category => {
      // Add the category row
      flattenedData.push({ ...category, isCategory: true });

      // Add subcategory rows
      if (category.subcategories && category.subcategories.length > 0) {
        category.subcategories.forEach(subcategory => {
          flattenedData.push({ ...subcategory, isCategory: false, parentCategoryId: category.id });
        });
      }
    });
    return flattenedData;
  }
  const exportToCsv = () => {
    const params = {
      suppressQuotes: false, // Set to true if you want to suppress quoting values
      fileName: 'exported-products.csv',
      columnSeparator: ',', // Set the column separator (optional, default is ',')
    };

    gridApi.exportDataAsCsv(params);
  };
  function rowClassRules(params) {
    return params.data.isCategory ? { 'category-row': true } : null;
  }
  const showAddUserFormHandler = () => {
    setShowAddUserForm(!showAddUserForm); // Toggle the value
  };
  const handleUserAddition = () => {
    console.log(userEmail)
    console.log(userPassword);
    console.log(mobilenumber)
    console.log(username)
    showAddUserFormHandler(false)
    setUserEmail("");
    setUserPassword("");
    setMobileNumber("");
    setUserName("");
  }
  return (
    <>
      <Header></Header>
      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>All Category</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">All Category</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <section className="section addProduct">
              <div>
                <form className="mb-9">
                  <div className="g-5 row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="categoryFilter">
                                <div className="catiSearch">
                                  <input
                                    type="text"
                                    placeholder="Search Product..."
                                    value={searchText}
                                    onChange={onFilterTextChange}
                                  />
                                </div>
                                <button className="blackBtn" onClick={(e) => { e.preventDefault(); showAddUserFormHandler() }}><i className="fa-solid fa-plus"></i> Add User</button>
                              </div>
                            </div>
                          </div>
                          {showAddUserForm && (
                            <div className="loginFormContainer">
                              <div className="card">
                                <div className="card-body">
                                  <div className="pt-4 pb-2">
                                    <h5 className="card-title text-center pb-0 fs-4">User details</h5>
                                    <p className="text-center small">Enter User Credentials</p>
                                  </div>
                                  <div className="loginForm">
                                    <div className="row g-3 needs-validation"
                                    >
                                      <div className="col-md-12">
                                        <label for="yourUsername" className="form-label">
                                          Email
                                        </label>
                                        <div className="input-group has-validation">
                                          <input
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            placeholder="Email"
                                            required
                                            value={userEmail}
                                            onChange={(e) => setUserEmail(e.target.value)}
                                          />
                                          <div className="invalid-feedback">
                                            Please enter your username.
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <label for="yourPassword" className="form-label">
                                          Password
                                        </label>
                                        <input
                                          type="password"
                                          placeholder="Password"
                                          className="form-control"
                                          required
                                          value={userPassword}
                                          onChange={(e) => setUserPassword(e.target.value)}
                                        />
                                        <div className="invalid-feedback">
                                          Please enter your password!
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <label for="yourPassword" className="form-label">
                                          Mobile Number
                                        </label>
                                        <input
                                          type="number"
                                          placeholder="mobilenumber"
                                          className="form-control"
                                          required
                                          value={mobilenumber}
                                          onChange={(e) => setMobileNumber(e.target.value)}
                                        />
                                        <div className="invalid-feedback">
                                          Please enter Users Mobile Number!
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <label for="yourPassword" className="form-label">
                                          Username
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Username"
                                          className="form-control"
                                          required
                                          value={username}
                                          onChange={(e) => setUserName(e.target.value)}
                                        />
                                        <div className="invalid-feedback">
                                          Please enter users username!
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <button className="loginBtn" type="button" onClick={handleUserAddition}>
                                          Add user
                                        </button>
                                      </div>
                                      <div className="col-md-12">
                                        <p className="forPas" onClick={(e) => { e.preventDefault(); showAddUserFormHandler(false) }} style={{ cursor: "pointer" }}>
                                          Cancel
                                        </p>
                                      </div>
                                    </div >
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="tableOuter adminTables">
                              <div className="ag-theme-alpine" style={{ height: '500px', width: '100%' }}>
                                <AgGridReact
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  rowData={rowData}
                                  onGridReady={onGridReady}
                                  pagination={true}
                                  paginationPageSize={10}
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default AddUser;

import React, { useState, useEffect } from "react";
import "./orderDetails.css";
import { Link } from "react-router-dom";
import "../../Style.css";

function OrderDetails({ location }) {
  const [orders, setOrders] = useState([]);
  const [address, setAddress] = useState([]);
  const [dollar, setDollar] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    getorders()
  }, []);

  async function getorders() {
    try {
      var res2 = await fetch(`/api/admin/marque`);
      let data2 = await res2.json();
      setDollar(data2[4].value);
      if (location?.state?.id) {
        var res = await fetch(`/api/admin/orders/${location?.state?.id}`);
        if (res.ok) {
          let data = await res.json();
          console.log("data", data)
          setOrders(data.orders)
          setAddress(data.shipping)
        } else {
          let data = await res.json();
          setOrders([])
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  const getTotalAmount = () => {
    return orders.reduce((total, row) => total + row.amount_charged, 0);
  };

  const getItemAmount = () => {
    return orders.reduce((total, row) => total + row.selling_price, 0);
  };
  const getShippingAmount = () => {
    if (orders.length)
      return orders.reduce(
        (total, row) => Math.floor(total + (row.pay_type === "PayPal" ? row.amount_charged * dollar : row.amount_charged) - row.selling_price),
        0
      );
  };

  return (
    <>
      <div className="UserOrderDetails p-both-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Order Details</h2>
              <p>Ordered on  {orders.length ? orders[0].modified : ""}  Order# {location?.state?.id} </p>
            </div>
            <div className="col-12 col-lg-8">
              <table>
                <thead>
                  <tr>
                    <td style={{ Width: "300px" }}>
                      Shipping Address
                    </td>
                    <td style={{ Width: "30%" }}>Payment Methods</td>
                    <td>Order Summary</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><b>{address?.first_name} {address?.last_name}</b><br />
                      {address?.address1} {address?.address2}<br />
                      {address?.city},<br />
                      {address?.state}<br />
                      {address?.zip}, {address?.country}<br />
                      {address?.s_mobile}</td>
                    <td>BHIM UPI</td>
                    <td>
                      <div className="row">
                        <div className="col-6">Item(s) Subtotal:</div>
                        <div className="col-6">Rs {getItemAmount()}</div>
                        <div className="col-6">Shipping:</div>
                        <div className="col-6">Rs {getShippingAmount()}</div>
                        <div className="col-6">Total:</div>
                        <div className="col-6">{orders.length ? orders[0]["pay_type"] === "PayPal" ? "$" : "Rs" : "Rs"} {getTotalAmount()}</div>
                        <div className="col-6">Grand Total:</div>
                        <div className="col-6">{orders.length ? orders[0]["pay_type"] === "PayPal" ? "$" : "Rs" : "Rs"} {getTotalAmount()}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Link to="/" className="contiShop">
                <i className="fa-solid fa-arrow-left-long"></i> Continue
                Shopping
              </Link>
            </div>
            <div className="col-12 col-lg-4">
              <div className="userOrderTable">
                <table width="100%">
                  <thead>
                    <tr>
                      <th className="text-left">Products</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length > 0 ? orders.map((order, index) => {
                      return <tr key={index}>
                        <td><div className="cartProductDetail">
                          <div className="cpImage">
                            <img src={`${process.env.REACT_APP_BASE_URL}${order.image}`} />
                          </div><div className="cpdata">
                            <a className="ppTitle">{order.name}</a>
                            <p><b>Category:</b>  {order.category}</p>
                            <p><b>Color:</b> {order.color}</p>
                            <p><b>Size:</b> {order.size}</p>
                            <p><b>Quantity:</b> {order.quantity}</p>
                            <p>
                              <b>Basic Customization:</b> {order.customization ?
                                Object.keys(JSON.parse(order.customization))
                                  .filter((key) => JSON.parse(order.customization)[key]) // Filter out keys with empty values
                                  .map((key) => (
                                    <span key={key} style={{ display: 'block' }}>
                                      <strong>{key}:</strong> {JSON.parse(order.customization)[key].toString()}
                                    </span>
                                  ))
                                : "Not Available"}
                            </p>
                            <p>
                              <b>Full Customization:</b> {order.full_customization ?
                                Object.keys(JSON.parse(order.full_customization))
                                  .map((key) => (
                                    <span key={key} style={{ display: 'block' }}>
                                      <strong>{key}:</strong> {JSON.parse(order.full_customization)[key].toString()}
                                    </span>
                                  ))
                                : "Not Available"}
                            </p>


                          </div>
                        </div>
                        </td>
                      </tr>
                    }) : <></>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDetails;

import React, { useEffect } from "react";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import CartImage from "../../images/trending1.png";

function Shipping() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {/* <Header></Header> */}
      <div className="container">
        <div className="row">
          <div className="policyPages p-both-50">
            <h2>Shipping & Delivery</h2>

            <p>
              <b>Do you ship outside India?</b>
            </p>
            <p>
              Yes, we deliver to more than 200 countries in the world and offer
              different shipping options as applicable.
            </p>

            <p>
              <b>How much does shipping cost?</b>
            </p>
            <p>
              A delivery fee of Rs.200 will be charged for order values below Rs. 4000 for deliveries within India

            </p>

            <p>
              For shipments outside India, refer to the shipping charges below:
            </p>

            <table>
              <tr>
                <td>Country</td>
                <td>From</td>
                <td>To</td>
                <td>Shipping Charges</td>
              </tr>
              <tr>
                <td>USA</td>
                <td>USD 1</td>
                <td>USD 199</td>
                <td>USD 25</td>
              </tr>
              <tr>
                <td>Australia</td>
                <td>AUD 1</td>
                <td>AUD 307</td>
                <td>AUD 35</td>
              </tr>
              <tr>
                <td>Canada</td>
                <td>CAD 1</td>
                <td>CAD 273</td>
                <td>CAD 30</td>
              </tr>
              <tr>
                <td>Hong Kong</td>
                <td>HKD 1</td>
                <td>HKD 1653</td>
                <td>HKD 170</td>
              </tr>
              <tr>
                <td>Nepal Rupee</td>
                <td>NPR 1</td>
                <td>NPR 29307</td>
                <td>NPR 2950</td>
              </tr>
              <tr>
                <td>Saudi Arabia</td>
                <td>SAR 1</td>
                <td>SAR 806</td>
                <td>SAR 100</td>
              </tr>
              <tr>
                <td>Singapore</td>
                <td>SGD 1</td>
                <td>SGD 271</td>
                <td>SGD 35</td>
              </tr>
              <tr>
                <td>UAE</td>
                <td>AED 1</td>
                <td>AED 806</td>
                <td>AED 90</td>
              </tr>
              <tr>
                <td>UK</td>
                <td>GBP 1</td>
                <td>GBP 157</td>
                <td>GBP 20</td>
              </tr>
              <tr>
                <td>Rest of the World</td>
                <td>USD 1</td>
                <td>USD 199</td>
                <td>USD 25</td>
              </tr>
            </table>
            <p>Shipping charges may apply on certain remote international postal codes for all orders.</p>

            <p>
              <b>Are there any duties and taxes?</b>
            </p>
            <p>
              For customers within India, all prices specified on the website are inclusive of Indian taxes and GST.
            </p>

            <p>
              <b>How do I know my order has been placed?</b>
            </p>
            <p>
              Upon placing your order, you will see an order confirmation number on your screen. You will also receive an email confirmation from us. In case there are any issues with processing your order, you will be notified with an email.
            </p>
            <p>
              <b>How do I track my order?</b>
            </p>
            <p>
              Once you have placed an order, we will send you a tracking number/ AWB through an email. You will be able to view the date your item is in process to the date of shipment to the date of your item being in the process of delivery.
            </p>
            <p>
              <b>When will I receive my order?</b>
            </p>
            <p>
              The estimated delivery time of each product is mentioned on product page. Please take note of the dates given before you proceed to checkout. Most garments will be shipped within 15-20 Days of receiving the order. Accessories will be shipped within 1-2 weeks. However, this is subject to availability.

              Orders within India should reach the customer within 2-4 days after the date of dispatch. Orders outside India should reach the customer within 4-6 days after the date of dispatch.

            </p>
            <p>
              <b>Can I change my shipping address after my order has been placed?</b>
            </p>
            <p>
              If there is an error in the shipping address you specified at the time of checkout, please email us at info@vastrakala.com or call us immediately. We are unable to change a shipping address or redirect orders once items have been dispatched.
            </p>
            <p>
              <b>Are my items insured?</b>
            </p>
            <p>
              Yes, all our products are insured from the time they leave Vastrakala to the time they reach your delivery address. Once a package has been signed for at the delivery address, it is no longer insured. If a product is damaged upon arrival, either refuse to accept it or notify us via email or phone within 24 hours of receipt.
            </p>
            <p>
              <i>If you have any additional shipping relating queries, feel free to email us at info@vastrakala.com or call customer care at +919872407145 .</i>
            </p>


          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Shipping;

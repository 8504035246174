import React from 'react';
import ReactImageMagnify from 'react-image-magnify';

const MyReactImageMagnify = (props) => {
  return (
    <div>
      <ReactImageMagnify
        {...props}
        {...{
          enlargedImageContainerStyle: {
            zIndex: '1500',
          },
          enlargedImageContainerDimensions: {
            width: '100%',
            height: '100%',
          },
        }}
      />
    </div>
  );
};

export default MyReactImageMagnify;
import React, { useEffect } from "react";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import CartImage from "../../images/trending1.png";

function TermsOfService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Header></Header> */}
      <div className="container">
        <div className="row">
          <div className="policyPages p-both-50">
            <p>
              <b>1. Terms</b>
            </p>
            <p>
              By accessing this web site, you are agreeing to be bound by these
              web site Terms and Conditions of Use, all applicable laws and
              regulations, and agree that you are responsible for compliance
              with any applicable local laws. If you do not agree with any of
              these terms, you are prohibited from using or accessing this site.
              The materials contained in this web site are protected by
              applicable copyright and trade mark law.
            </p>

            <p>
              <b>2. Use License</b>
            </p>
            <p>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on company Web site for
              personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this
              license you may not:
            </p>

            <p>
              modify or copy the materials; use the materials for any commercial
              purpose, or for any public display (commercial or non-commercial);
              attempt to decompile or reverse engineer any software contained on
              company site; remove any copyright or other proprietary notations
              from the materials; or transfer the materials to another person or
              "mirror" the materials on any other server. This license shall
              automatically terminate if you violate any of these restrictions
              and may be terminated by company at any time. Upon terminating
              your viewing of these materials or upon the termination of this
              license, you must destroy any downloaded materials in your
              possession whether in electronic or printed format.
            </p>

            <p>
              <b>3. Disclaimer</b>
            </p>
            <p>
              The materials on company web site are provided as company makes no
              warranties, expressed or implied, and hereby disclaims and negates
              all other warranties, including without limitation, implied
              warranties or conditions of merchantability, fitness for a
              particular purpose, or non-infringement of intellectual property
              or other violation of rights. Further, company does not warrant or
              make any representations concerning the accuracy, likely results,
              or reliability of the use of the materials on its Internet web
              site or otherwise relating to such materials or on any sites
              linked to this site.
            </p>

            <p>
              <b>4.Cancellation Policy</b>
            </p>
            <p>
              You may cancel an order within 24 hours after placing it. No
              cancellations beyond 24 hours will be entertained barring
              extenuating circumstances Any accepted cancellation beyond 24
              hours will be exclusively at the discretion of www.vastrakala.com
              & refund would only in the form of credit voucher code You can
              email us at info@vastrakala.com for any cancellation request.
              Cancellation requests are not accepted through phone or online
              chat services. Products ordered during our Annual sale cannot be
              cancelled once an order is placed
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default TermsOfService;

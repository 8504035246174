import React, { Fragment, useEffect, useState } from "react";
import CategoryBanner from "../../images/categoryBanner.png";
import Footer from "../layout/Footer/Footer";
import Slider from '@material-ui/core/Slider';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart, addItemsToWishlist } from "../../actions/cartAction";
import CustomModal from '../layout/CustomModal'
import Loader from "../layout/Loader/Loader";
import { Link } from "react-router-dom";

const Search = (props) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(
    (state) => state.user
  );
  const { cartItems, wishlist } = useSelector((state) => state.cart);
  const cart_ids = cartItems.map(obj => obj.product_id);
  const wishlist_ids = wishlist.map(obj => obj.product_id);

  const [products, setProducts] = useState([]);
  const history = useHistory();
  const abortController = new AbortController();
  const { signal } = abortController;
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [banner, setBanner] = useState('');
  const [loading, setLoading] = useState(false);



  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const handleClick = (url, id) => {
    history.push(url, { id });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);

  const fetchSearchResults = async () => {
    try {
      // Replace 'YOUR_SEARCH_API_ENDPOINT' with the actual endpoint of your search API
      const response = await fetch(
        `/api/products/searchall?query=${props.match.params.query}`,
        { signal }
      );
      if (response.ok) {
        const data = await response.json();
        setProducts(data.products);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    fetchSearchResults()
  }, []);





  async function addItems(id, product) {
    dispatch(addItemsToCart(id, 1, isAuthenticated, product));
    setPopupContent("Product added to cart")
    setShowPopup(true)

  }

  async function addItemsWish(id) {
    if (isAuthenticated) {
      dispatch(addItemsToWishlist(id));
      setPopupContent("Product added to Wishlist")
      setShowPopup(true)
    } else {
      history.push("/LoginSignup");
    }
  }

  return (
    <>
      {/* <Header></Header> */}
      {loading ? <Loader /> : ""}
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />

      <div className="categoryBanner">
        <img src={banner ? process.env.REACT_APP_BASE_URL + banner : "../../../categoryBanner.jpg"} width="100%" />
      </div>
      <div className="categoryBreadcrum">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pagelinks">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                    {/* <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="7"
                        viewBox="0 0 5 7"
                        fill="none"
                      >
                        <path
                          d="M4.19983 3.23364L1.42249 0.368408L0.930298 0.825439L3.26819 3.23364L0.859985 5.69458L1.33459 6.16919L4.02405 3.39185L4.19983 3.23364Z"
                          fill="black"
                        />
                      </svg>
                    </span> */}
                  </li>
                  {/* <li>
                    <a href="#">{props.match.params.cat}</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="categoryPage p-both-50">
        <div className="container">

          <div className="row">

            <div className={products?.length === 0 ? "col-md-12" : "col-md-12"}>
              {products?.length ? (
                <>
                  <div className="row">
                    {products?.map((product) => {
                      let url = `/singleproduct/${product.name}/p/${product.product_id}`
                      return (<div className="col-md-3">
                        <a className="singleCatProduct">
                          <div className="prodTop">
                            <img src={process.env.REACT_APP_BASE_URL + product.image} width="100%" onClick={() => handleClick(url, product.product_id)} />
                            <div className="hoverButtons" >
                              {!cart_ids.includes(product.product_id.toString()) ?
                                <a onClick={(e) => addItems(product.product_id, product)} className="addcart">
                                  Add to Cart
                                </a> :
                                <Link to="../../cart" className="addcart">View cart
                                </Link>
                              }
                              {!wishlist_ids.includes(product.product_id.toString()) ?
                                <a onClick={(e) => addItemsWish(product.product_id)} className="addwish">
                                  Add to Wishlist
                                </a> :
                                <Link to="../../wishlist" className="addwish">View Wishlist
                                </Link>
                              }
                            </div>
                          </div>
                          <h2 className="productTitle">
                            {product.name}
                            {/* ({product.color} - {product.size}) */}
                          </h2>
                          <div className="rating">
                            <ul>
                              <li>
                                <i class={`fa-${product.rating >= 1 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                              <li>
                                <i class={`fa-${product.rating >= 2 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                              <li>
                                <i class={`fa-${product.rating >= 3 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                              <li>
                                <i class={`fa-${product.rating >= 4 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                              <li>
                                <i class={`fa-${product.rating >= 5 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                            </ul>
                            <span className="RatingCount">({product.rating})</span>
                          </div>
                          <p className="productPrice">Rs. {product.selling_price}</p>
                        </a>
                      </div>)
                    })
                    }
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="noFound">
                      <h2>No products found</h2>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div >
      </div >
      <Footer></Footer>
    </>
  );
};

export default Search;


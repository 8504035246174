export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const DELETE_CART = "DELETE_CART";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";


export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";

export const LOGOUT_CART = "LOGOUT_CART";

export const REMOVE_WISHLIST_ITEM = "REMOVE_WISHLIST_ITEM";

export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";

export const SAVE_SHIPPING_INFO = "SAVE_SHIPPING_INFO";

import React from "react";
import { useState, useEffect } from "react";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import CartImage from "../../images/trending1.png";
import dummyUser from "../../images/dummyUser.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userAction";
import { logout_Cart } from "../../actions/cartAction";
import CustomModal from "../layout/CustomModal";
import AddressModal from "../layout/AddressModal";
import { loadingState, updateUserDetails } from "../../actions/userAction";
import { add_Addresses, get_Addresses, delete_Address, set_default_Address, removeItemfromWishlist } from "../../actions/cartAction";
import { Link } from "react-router-dom";
import Loader from "../layout/Loader/Loader";

function UserAccount({ history, location }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    getorders()
    dispatch(get_Addresses());
  }, []);

  useEffect(() => {
    if (location?.state?.page === 4) {
      toggleTab(4)
    }
  }, [location]);


  function deleteAddress(e, id) {
    e.preventDefault()
    dispatch(delete_Address(id));
  }


  function editAddress(e, index, id) {
    e.preventDefault()
    setAddressIndex(index)
    setAddressId(id)
    setShowAddressPopup(true)
  }

  function defaultAddress(e, id) {
    e.preventDefault()
    dispatch(set_default_Address(id));
  }

  const { shippingInfo } = useSelector(
    (state) => state.cart
  );

  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [toggleState, setToggleState] = useState(1);
  const [oldPassword, setOldPassword] = useState([]);
  const [newPassword, setNewPassword] = useState([]);
  const [newPassword2, setNewPassword2] = useState([]);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [mobile, setMobile] = useState(user.mobile);
  const [orders, setOrders] = useState([]);
  const [addressIndex, setAddressIndex] = useState('');
  const [addressId, setAddressId] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [loading, setLoading] = useState(false);


  const toggleTab = (index) => {
    setToggleState(index);
  };
  const dispatch = useDispatch();

  const { wishlist } = useSelector((state) => state.cart);

  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const [showPopup2, setShowPopup2] = useState(false);
  const [popupContent2, setPopupContent2] = useState("");

  const handlePopupClose2 = () => {
    setShowPopup2(false); // Close the popup
  };
  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const handleAddressPopupClose = () => {
    setShowAddressPopup(false); // Close the popup
  };


  const handleLogoutClick = () => {
    // Handle the "Logout" action
    console.log("Logout clicked");
    dispatch(logout());
    dispatch(logout_Cart());
  };

  async function updatePass(e) {
    e.preventDefault();
    if (oldPassword === "") {
      setPopupContent2("Old password is empty");
      setShowPopup2(true);
    } else if (newPassword === "" || newPassword2 === "") {
      setPopupContent2("New password is empty");
      setShowPopup2(true);
    } else if (oldPassword === newPassword) {
      setPopupContent2("Old and new password can't be same.");
      setShowPopup2(true);
    } else if (newPassword !== newPassword2) {
      setPopupContent2("New password are not matching");
      setShowPopup2(true);
    } else {
      setLoading(true);
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          oldPassword: Buffer.from(oldPassword).toString("base64"),
          newPassword: Buffer.from(newPassword).toString("base64"),
        }),
      };
      var res = await fetch("/api/account/password", requestOptions);
      if (res.ok) {
        setLoading(false);
        let data = await res.json();
        setOldPassword("")
        setNewPassword("")
        setNewPassword2("")
        setPopupContent(data.message);
        setShowPopup(true);
      } else {
        setLoading(false);
        let data = await res.json();
        setPopupContent2(data);
        setShowPopup2(true);
      }
    }
  }

  const updateDetails = async (e) => {
    e.preventDefault();
    if (!firstName) {
      setPopupContent2("First name can not be blank");
      setShowPopup2(true);
    } else if (!lastName) {
      setPopupContent2("Last name can not be blank");
      setShowPopup2(true);
    }
    // else if (mobile.length !== 10) {
    //   setPopupContent2("Mobile number should be 10 digits");
    //   setShowPopup2(true);
    // } 
    else {
      dispatch(loadingState(true));
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          phone: mobile,
        }),
      };
      var res = await fetch("/api/account/details", requestOptions);
      if (res.ok) {
        let data = await res.json();
        dispatch(updateUserDetails(data));
        dispatch(loadingState(false));
        setPopupContent("Details Updated");
        setShowPopup(true);
      } else {
        dispatch(loadingState(false));
        let data = await res.json();
        setPopupContent2(data);
        setShowPopup2(true);
      }
    }
  };

  async function getorders() {
    try {
      var res = await fetch("/api/checkout/payment/orders");
      if (res.ok) {
        let data = await res.json();
        console.log("data", data)
        setOrders(data.orders)
      } else {
        let data = await res.json();
        setOrders([])
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function removeItem_Wishlist(index, product_id) {
    dispatch(removeItemfromWishlist(product_id));
  }


  const handleClick = (url, id) => {
    history.push(url, { id });
  }



  return (
    <>
      {loading ? <Loader /> : <></>}
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <CustomModal
        type="warning"
        content={popupContent2}
        showPopup={showPopup2}
        onClose={handlePopupClose2}
      />
      <AddressModal
        showPopup={showAddressPopup}
        onClose={handleAddressPopupClose}
        index={addressIndex}
        id={addressId}
      />
      {/* <Header></Header> */}
      <div className="myAccount p-both-50">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="accountInfo">
                <img src={user.image ? user.image : dummyUser} />
                <h3>
                  Welcome <br></br>
                  <span>
                    {user.first_name} {user.last_name}
                  </span>
                </h3>
                <p>{user.email}</p>
              </div>
              <div className="accountTabbs">
                <ul>
                  <li>
                    <button
                      onClick={() => toggleTab(1)}
                      className={toggleState === 1 ? "active-tabs" : "tabs"}
                    >
                      <i className="fa-solid fa-user"></i> My Account
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => toggleTab(2)}
                      className={toggleState === 2 ? "active-tabs" : "tabs"}
                    >
                      <i className="fa-solid fa-lock"></i> Change Password
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => toggleTab(3)}
                      className={toggleState === 3 ? "active-tabs" : "tabs"}
                    >
                      <i className="fa-solid fa-location-dot"></i> Change/Add
                      Address
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => toggleTab(4)}
                      className={toggleState === 4 ? "active-tabs" : "tabs"}
                    >
                      <i className="fa-solid fa-heart"></i>My Wishlist
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => toggleTab(7)}
                      className={toggleState === 7 ? "active-tabs" : "tabs"}
                    >
                      <i className="fa-solid fa-box-open"></i>My Orders
                    </button>
                  </li>
                  {/* <li>
                    <button
                      onClick={() => toggleTab(5)}
                      className={toggleState === 5 ? "active-tabs" : "tabs"}
                    >
                      <i className="fa-solid fa-right-left"></i>Exchange &
                      Return
                    </button>
                  </li> */}
                  <li>
                    <button
                      onClick={() => handleLogoutClick()}
                      className={toggleState === 6 ? "active-tabs" : "tabs"}
                    >
                      <i className="fa-solid fa-power-off"></i>Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-9">
              <div className="accountData">
                <div
                  className={toggleState === 1 ? "activeContent" : "tabContent"}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <h2>Basic Information</h2>
                    </div>
                    <div className="col-md-12">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="name">First Name</label>
                            <input
                              type="text"
                              name="name"
                              id=""
                              value={firstName}
                              onInput={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="name">Last Name</label>
                            <input
                              type="text"
                              name="name"
                              id=""
                              value={lastName}
                              onInput={(e) => setLastName(e.target.value)}
                            />
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="email">Email Address</label>
                            <input
                              disabled
                              type="email"
                              name="email"
                              id=""
                              value={user.email}
                            />
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="phone">Phone Number</label>
                            <input
                              type="number"
                              placeholder="Phone Number"
                              name="phone"
                              id=""
                              value={mobile}
                              onInput={(e) => {
                                // e.target.value.length < 11 &&
                                setMobile(e.target.value);
                              }}
                            />
                          </div>
                          {/* <div className="col-md-12">
                        <label htmlFor="phone">Upload New Profile Image</label>
                        <input type="file" accept="image/*" name="phone" id="" />
                      </div> */}
                        </div>
                      </form>
                    </div>
                    <div className="col-md-12">
                      <div className="chanegDet">
                        <button
                          className="blackbtn"
                          onClick={(e) => updateDetails(e)}
                        >
                          Update Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={toggleState === 2 ? "activeContent" : "tabContent"}
                >
                  <h2>Change Password</h2>
                  <form onSubmit={(e) => updatePass(e)}>
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="Password">
                          Current password
                        </label>
                        <div className="input-group">
                          <input
                            required
                            type={showPassword1 ? "text" : "password"}
                            name="Password"
                            id=""
                            placeholder="Min 8 Character"
                            value={oldPassword}
                            onInput={(e) => setOldPassword(e.target.value)}
                          />
                          <i onClick={(e) => setShowPassword1(!showPassword1)} className={`fa-regular fa-eye${showPassword1 ? "-slash" : ""}`}></i>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="Password">New Password</label>
                        <div className="input-group">

                          <input
                            required
                            type={showPassword2 ? "text" : "password"}
                            name="Password"
                            id=""
                            placeholder="Min 8 Character"
                            value={newPassword}
                            onInput={(e) => setNewPassword(e.target.value)}
                          />
                          <i onClick={(e) => setShowPassword2(!showPassword2)} className={`fa-regular fa-eye${showPassword2 ? "-slash" : ""}`}></i>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="cPassword">Confirm New Password</label>
                        <div className="input-group">
                          <input
                            required
                            type={showPassword3 ? "text" : "password"}
                            name="cPassword"
                            id=""
                            placeholder="********"
                            value={newPassword2}
                            onInput={(e) => setNewPassword2(e.target.value)}
                          />
                          <i onClick={(e) => setShowPassword3(!showPassword3)} className={`fa-regular fa-eye${showPassword3 ? "-slash" : ""}`}></i>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="chanegDet">
                          <button className="blackbtn">Update Password</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={toggleState === 3 ? "activeContent" : "tabContent"}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <h2>Address</h2>
                    </div>
                    <div className="col-md-6">
                      <div className="chanegDet">
                        <button className="addBTn" onClick={(e) => editAddress(e, '', '')}><i className="fa-solid fa-plus"></i> Add New Address</button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <h4>Shipping Address</h4>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="">Full Name</label>
                          <input type="text" name="" id="" />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="">Phone Number</label>
                          <input
                            type="number"
                            name=""
                            id=""
                            placeholder="Phone Number"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="">Pincode</label>
                          <input type="number" name="" id="" />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="">Country</label>
                          <select name="" id="">
                            <option value="">Select Country</option>
                            <option value="">India</option>
                            <option value="">Canada</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="">State</label>
                          <select name="" id="">
                            <option value="">Select State</option>
                            <option value="">Punjab</option>
                            <option value="">Haryana</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="">City</label>
                          <input type="text" name="" id="" />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="">
                            House No., Building Name(Required){" "}
                          </label>
                          <input type="text" name="" id="" />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="">Shipping Address </label>
                          <input type="text" name="" id="" />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="">
                            Road Name, Area, Colony(Required){" "}
                          </label>
                          <input type="text" name="" id="" />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="">Land Mark / Famous Place </label>
                          <input type="text" name="" id="" />
                        </div>
                        <div className="col-md-12">
                          <div className="billShip">
                            <input
                              type="checkbox"
                              name="billShip"
                              id="billShip"
                            />
                            <label htmlFor="billShip">
                              Shipping Address is same as Billing Address
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="chanegDet">
                            <button className="blackbtn">Save Address</button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="mt-3"><hr /></div>
                    </div>
                    {/* <div className="col-md-12">
                      <h4>Current Address</h4>
                      <p>
                        Akshya Nagar 1st Block 1st Cross, Rammurthy nagar,
                        Bangalore-560016
                      </p>
                    </div>

                    <div className="col-md-12">
                      <h4>Other Addresses</h4>
                    </div> */}
                    {shippingInfo.length > 0 ? shippingInfo.map((address, index) => {
                      return <div key={index} className="col-md-6">
                        <div className="singleAddress defaultAddress">
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                <span className="addTitle">{address.first_name} {address.last_name}</span>
                                {`${address.address1} ${address.address2}, 
                                ${address.city}, 
                                ${address.state},
                                ${address.zip}, ${address.country}`}
                              </p>
                              <p>
                                {address.mobile}
                              </p>
                            </div>
                            <div className="col-md-12">
                              <div className="addressActions d-flex gap16">
                                <a onClick={(e) => editAddress(e, index, address.id)}>Edit</a>
                                {address.default === "Y" ? "" : <a href="" onClick={(e) => deleteAddress(e, address.id)}>Remove</a>}
                                {address.default === "Y" ? <span className="DTag"><i className="fa-solid fa-check"></i></span> : <a href="" onClick={(e) => defaultAddress(e, address.id)}>Set as Default</a>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }) : ""}

                  </div>
                </div>
                <div
                  className={toggleState === 4 ? "activeContent" : "tabContent"}
                >
                  <h2>My Wishlist</h2>
                  <div className="wishProductList row">
                    {wishlist.length === 0 ? (
                      <tr>
                        <td>
                          <div className="cartProductDetail">
                            Wishlist is Empty
                          </div>
                        </td>
                      </tr>
                    ) : (
                      wishlist?.map((product, index) => {
                        return (
                          <div className="col-6 singleWishCard col-md-3" key={index}>
                            <a href="#" onClick={() => handleClick(`/singleproduct/${product.name}/p/${product.product_id}`, product.product_id)}>
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL + product.image
                                }
                              />
                              <p>{product.name}</p>
                              <p>Rs-{product.selling_price}</p>

                            </a>
                            <button onClick={() => removeItem_Wishlist(index, product.product_id)} className="blackbtn">Remove</button>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
                <div
                  className={toggleState === 7 ? "activeContent" : "tabContent"}
                >

                  <div className="row">
                    <div className="col-6">
                      <h2>My Orders</h2>
                    </div>
                    <div className="col-6 text-right">
                      <select name="" id="">
                        <option value="">Select</option>
                        <option value="">Shipped</option>
                        <option value="">Cancelled</option>
                        <option value="">Pending</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <div className="user_order_accordian">

                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="cartTable">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th>Order Number</th>
                              <th>Product</th>
                              <th>Order Placed</th>
                              {/* <th>Shipped To</th> */}
                              <th>Status</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orders.length > 0 ? orders.map((order, index) => {
                              return (<tr key={index} onClick={(e) => handleClick(`/orderdetails`, order.id)}>
                                <td>#{order.id}</td>
                                <td>
                                  <div className="cartProductDetail">
                                    <div className="cpImage">
                                      <a>
                                        <img src={`${process.env.REACT_APP_BASE_URL}${order.image}`} />
                                      </a>
                                    </div>
                                    <div className="cpdata">
                                      <a className="ppTitle">
                                        {order.name}
                                      </a>
                                      <p>
                                        <b>Category:</b> {order.category}
                                      </p>
                                      <p>
                                        <b>Color:</b> {order.color}
                                      </p>
                                      <p>
                                        <b>Size:</b> {order.size}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>{order.modified}</td>
                                {/* <td>Ankit Kapoor(Home)</td> */}
                                <td>{order.status_message}</td>
                                <td>Rs {order.selling_price}</td>

                              </tr>)
                            }) : ""}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={toggleState === 5 ? "activeContent" : "tabContent"}
                >
                  <div className="row retEx">
                    <div className="col-md-12">
                      <h2>Exchange & Return</h2>
                    </div>
                    <div className="col-md-12">
                      <input type="text" placeholder="Search Product" />
                    </div>
                    <div className="col-md-12">
                      <div className="oScroll">
                        <table className="echangeTable">
                          <tr>
                            <th style={{ minWidth: "300px" }}>Product</th>
                            <th style={{ minWidth: "150px" }}>Price</th>
                            <th style={{ minWidth: "120px" }}>Status</th>
                            <th style={{ minWidth: "250px" }}>
                              Return & Exchange
                            </th>
                          </tr>
                          <tr>
                            <td>
                              <a href="#">
                                Royal Blue Straight Shirt with Lehenga in Hand
                                Embroidery and Baby Blue Dupatta VK0923496
                              </a>
                            </td>
                            <td>Rs-2900.00</td>
                            <td>Pending</td>
                            <td>Return</td>
                          </tr>
                          <tr>
                            <td>
                              <a href="#">
                                Royal Blue Straight Shirt with Lehenga in Hand
                                Embroidery and Baby Blue Dupatta VK0923496
                              </a>
                            </td>
                            <td>Rs-2900.00</td>
                            <td>Done</td>
                            <td>Exhanged</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default UserAccount;

import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { CsvExportParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Header from "./header";
import Footer from "./footer";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import CustomModal from "../layout/CustomModal";

function AllCategory() {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [deleteMultiple, setdeleteMultiple] = useState([]);

  const [searchText, setSearchText] = useState("");
  const history = useHistory();

  const [deleteID, setDeleteID] = useState("");
  const [deleteType, setDeleteType] = useState("");
  // const [searchText, setSearchText] = useState("");

  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const handlePopupClose = () => {
    setShowPopupSuccess(false); // Close the popup
  };

  const columnDefs = [
    {
      headerName: "",
      cellRenderer: checkboxRenderer,
      maxWidth: 50,
      sortable: false,
      floatingFilter: false,
      filter: false,
      headerTooltip: "action",
    },
    {
      headerName: "ID",
      field: "id",
      headerTooltip: "ID",
      maxWidth: 100,
      sortable: true,
      floatingFilter: false,
      filter: false,
    },
    {
      headerName: "Category Image",
      field: "image",
      cellRenderer: ImageRenderer,
      headerTooltip: "image",
      filter: false,
      maxWidth: 150,
    },
    {
      headerName: "Category Name",
      field: "name",
      headerTooltip: "categoryName",
    },
    // { headerName: "Category Description", field: "description", cellRenderer: descriptionRenderer, headerTooltip: "description", filter: false },
    // { headerName: "Tag", field: "tag", headerTooltip: "tag" },
    { headerName: "Type", field: "type", headerTooltip: "type" },
    {
      headerName: "Action",
      cellRenderer: ActionRenderer,
      cellRendererParams: { editCategory, deleteProduct },
      minWidth: 270,
      sortable: false,
      floatingFilter: false,
      filter: false,
      headerTooltip: "action",
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: false,
    flex: 1,
    floatingFilter: false,
    // sort: 'asc',
    resizable: true,
    minWidth: 150,
  };
  function editCategory(e, category) {
    e.preventDefault();
    history.push("/admin/addCategory", category);
  }
  useEffect(() => {
    getCategories();
  }, []);

  function getCategories() {
    fetch("/api/admin/category/all")
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          setRowData(flattenData(data));
        } else {
          setRowData([]);
        }
      });
  }

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }
  const onFilterTextChange = (e) => {
    setSearchText(e.target.value);
    applyCategoryFilter(e.target.value);
  };

  const applyCategoryFilter = (value) => {
    gridApi.setQuickFilter(value);
  };

  function confirm_deleteProduct(e, id) {
    e.preventDefault();
    setDeleteID(id);
    setDeleteType("single");
    setShowPopup(true);
  }

  function confirm_deleteAll() {
    setDeleteType("multiple");
    setShowPopup(true);
  }

  function deleteProduct() {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: deleteID,
      }),
    };
    fetch("/api/admin/category/" + deleteID, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          getCategories();
          setPopupContent("Category Deleted ID: " + deleteID);
          setShowPopupSuccess(true);
        } else {
          setPopupContent("Error");
          setShowPopupSuccess(true);
        }
      });
  }

  function ImageRenderer(field) {
    return (
      <img
        alt=""
        src={
          field.value
            ? `${process.env.REACT_APP_BASE_URL}${field.value}`
            : "../../No-Image.png"
        }
        className="CategoryImage"
      />
    );
  }

  function descriptionRenderer(field) {
    return <div dangerouslySetInnerHTML={{ __html: field.value }} />;
  }

  function ActionRenderer(field) {
    return (
      <div className="actions">
        <button className="viewbtn">
          <i className="fa-regular fa-eye"></i>View
        </button>
        <button
          className="editBtn"
          onClick={(e) => editCategory(e, field.data)}
        >
          <i className="fa-regular fa-pen-to-square"></i>Edit
        </button>
        <button
          className="dltBtn"
          onClick={(e) => confirm_deleteProduct(e, field.data.id)}
        >
          <i className="fa-regular fa-trash-can"></i>Delete
        </button>
      </div>
    );
  }

  function changedeleteMultiple(id) {
    let multiple = [...deleteMultiple];
    if (multiple.includes(id)) {
      // If the value is already selected, remove it
      const updatedValues = multiple.filter(
        (selectedValue) => selectedValue !== id
      );
      setdeleteMultiple(updatedValues);
    } else {
      // If the value is not selected, add it
      const updatedValues = [...deleteMultiple, id];
      setdeleteMultiple(updatedValues);
    }
  }

  function checkboxRenderer(field) {
    return (
      <div className="actions">
        <input
          type="checkbox"
          checked={deleteMultiple.includes(field.data.id)}
          onClick={(e) => changedeleteMultiple(field.data.id)}
        />
      </div>
    );
  }

  function flattenData(data) {
    const flattenedData = [];
    data.forEach((category) => {
      category.type = "Main Category";
      // Add the category row
      flattenedData.push({ ...category, isCategory: true });

      // Add subcategory rows
      if (category.subcategories && category.subcategories.length > 0) {
        category.subcategories.forEach((subcategory) => {
          subcategory.type = "- Sub Category";
          flattenedData.push({ ...subcategory, isCategory: false });
          subcategory.subcategories.forEach((subsubcategory) => {
            subsubcategory.type = "-- Sub Category I";
            flattenedData.push({ ...subsubcategory, isCategory: false });
          });
        });
      }
    });
    return flattenedData;
  }

  function rowClassRules(params) {
    return params.data.isCategory ? { "category-row": true } : null;
  }
  function DeleteAll() {
    console.log("deleteMultiple", deleteMultiple);
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: deleteMultiple,
      }),
    };
    fetch("/api/admin/category/all", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          getCategories();
          alert("Selected Categories deleted");
        } else {
          alert("Error");
        }
      });
  }

  const exportToCsv = () => {
    const params = {
      suppressQuotes: false, // Set to true if you want to suppress quoting values
      fileName: "exported-category.csv",
      columnSeparator: ",", // Set the column separator (optional, default is ',')
    };

    gridApi.exportDataAsCsv(params);
  };

  const [showPopup, setShowPopup] = useState(false);
  const handleClose = () => {
    setShowPopup(false);
  };
  const handleConfirm = () => {
    setShowPopup(false);
    if (deleteType === "single") {
      deleteProduct();
    } else {
      DeleteAll();
    }
  };

  return (
    <>
      <Header></Header>
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopupSuccess}
        onClose={handlePopupClose}
      />
      <Modal show={showPopup} onHide={handleClose} className="alertsPop">
        <div className="row">
          <div className="col-md-12">
            <div className="deleteIcon">
              <i className="fa-solid fa-trash-can"></i>
            </div>
          </div>
          <div className="col-md-12">
            <p>Are you sure you want to delete this category?</p>
          </div>
          <div className="col-md-12 mt-5">
            <div className="actionBtns">
              <button
                className="blackbtn"
                onClick={(e) => {
                  handleConfirm("Y");
                }}
              >
                Yes
              </button>
              <button className="outlinedBtn" onClick={handleClose}>
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>All Category</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">All Category</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <section className="section addProduct">
              <div>
                <form className="mb-9">
                  <div className="g-5 row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="categoryFilter">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="catiSearch">
                                      <input
                                        type="text"
                                        placeholder="Search Category..."
                                        value={searchText}
                                        onChange={onFilterTextChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 text-right">
                                    <div className="catTableBtns">
                                      <button
                                        className="blackBtn"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          exportToCsv();
                                        }}
                                      >
                                        <i className="fa-solid fa-file-export"></i>{" "}
                                        Export
                                      </button>
                                      <button
                                        className="outlinedBtn"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // DeleteAll();
                                          confirm_deleteAll();
                                        }}
                                      >
                                        <i className="fa-regular fa-trash-can"></i>{" "}
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="tableOuter adminTables">
                              <div
                                className="ag-theme-alpine"
                                style={{ height: "500px", width: "100%" }}
                              >
                                <AgGridReact
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  rowData={rowData}
                                  onGridReady={onGridReady}
                                  pagination={true}
                                  paginationPageSize={10}
                                  rowClassRules={rowClassRules}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default AllCategory;

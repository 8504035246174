import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Dashboard() {
  const history = useHistory();

  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [recentOrders, setRecentOrders] = useState([]);
  const [total_sales, settotal_sales] = useState(0);
  const [total_sales_paypal, settotal_sales_paypal] = useState(0);
  const [pending_orders, setpending_orders] = useState(0);
  const [new_orders, setnew_orders] = useState(0);
  const [new_customer, setnew_customer] = useState(0);

  async function getRecentOrders() {
    let res = await fetch("/api/admin/orders");
    if (res.ok) {
      let data = await res.json();
      setRecentOrders(data);
    }
  }
  async function getDashboard() {
    let res = await fetch("/api/admin/orders/dashboard");
    if (res.ok) {
      let data = await res.json();
      settotal_sales(data.total_sales_stripe);
      settotal_sales_paypal(data.total_sales_paypal);
      setpending_orders(data.pending_orders);
      setnew_orders(data.new_orders);
      setnew_customer(data.new_customer);
      // setRecentOrders(data);
    }
  }
  const [products, setProducts] = useState([]);

  async function getProducts(cat, sort, size, weight, category) {
    fetch(
      `/api/products/category/Bestseller/1?sort=&minPrice=0&maxPrice=200000`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        if (data) {
          setProducts(data.products);
        }
      });
  }

  useEffect(() => {
    getRecentOrders();
    getDashboard();
    getProducts();
  }, []);

  const handleClick = (url, id) => {
    history.push(url, { id });
  };

  return (
    <>
      <div className="adminbg">
        <Header></Header>
        {user.role === "admin" ? (
          <main id="main" className="main">
            <div className="container">
              <div className="row mb-2">
                <div className="col-md-6">
                  <div className="pagetitle">
                    <h1>Dashboard</h1>
                    <div className="adminBreadcrum">
                      <ul>
                        <li>
                          <a href="#">Home</a>
                        </li>
                        <li>
                          <a href="#">Dashboard</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 justify-content-end d-flex align-items-center">
                  <Link to="/admin/addProduct" className="whiteBtn">
                    Add Product
                  </Link>
                </div>
              </div>
              <div className="row">
                <section className="section dashboard">
                  <div className="row fiveCol">
                    <div className="col-lg-2 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">
                            Total Sales (Rs)
                            {/* <span>| Today</span> */}
                          </h5>

                          <div className="d-flex align-items-center">
                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                              <i className="fa-solid fa-indian-rupee-sign"></i>
                            </div>
                            <div className="ps-3">
                              <h6>{total_sales}</h6>
                              {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">
                            Total Sales ($)
                            {/* <span>| Today</span> */}
                          </h5>

                          <div className="d-flex align-items-center">
                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center"> 
                              <i className="fa-solid fa-dollar-sign"></i>
                            </div>
                            <div className="ps-3">
                              <h6>{total_sales_paypal}</h6>
                              {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <div className="card info-card customers-card">
                        <div className="card-body">
                          <h5 className="card-title">
                            Pending Orders 
                            {/* <span>| This Month</span> */}
                          </h5>

                          <div className="d-flex align-items-center">
                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                              <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                            </div>
                            <div className="ps-3">
                              <h6>{pending_orders}</h6>
                              {/* <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <div className="card info-card revenue-card">
                        <div className="card-body">
                          <h5 className="card-title">
                            New Orders 
                            {/* <span>| This Year</span> */}
                          </h5>

                          <div className="d-flex align-items-center">
                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                              <i className="fa-brands fa-opencart"></i>
                            </div>
                            <div className="ps-3">
                              <h6>{new_orders}</h6>
                              {/* <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-6">
                      <div className="card info-card revenue-card">
                        <div className="card-body">
                          <h5 className="card-title">
                            New Customers
                            {/* <span>| This Year</span> */}
                          </h5>

                          <div className="d-flex align-items-center">
                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                              <i className="fa-solid fa-user"></i>
                            </div>
                            <div className="ps-3">
                              <h6>{new_customer}</h6>
                              {/* <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card recent-sales overflow-auto">
                        <div className="filter">
                          <a
                            className="icon"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </a>
                        </div>

                        <div className="card-body">
                          <h5 className="card-title">Recent Orders</h5>
                          <div className="tableOflow">
                            <table className="table table-borderless datatable">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Customer</th>
                                  <th scope="col">Product</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {recentOrders.length > 0 &&
                                  recentOrders.map((recentOrder) => (
                                    <tr key={recentOrder.order_id}>
                                      <td scope="row">
                                        <Link
                                          to={{
                                            pathname: "/admin/ordersdetail",
                                            state: {
                                              id: recentOrder.order_id,
                                            },
                                          }}
                                        >
                                          {recentOrder.order_id}
                                        </Link>
                                      </td>
                                      <td>{`${recentOrder.first_name} ${recentOrder.last_name}`}</td>
                                      <td>
                                        <a href="#" className="text-primary">
                                          {recentOrder.name}
                                        </a>
                                      </td>
                                      <td>
                                        {recentOrder.pay_type === "PayPal"
                                          ? "$"
                                          : "Rs."}
                                        {recentOrder.amount_charged}
                                      </td>
                                      <td>
                                        <span
                                          className={`badge bg-${
                                            recentOrder.status === "paid"
                                              ? "success"
                                              : recentOrder.status === "failed"
                                              ? "danger"
                                              : "warning"
                                          }`}
                                        >
                                          {recentOrder.status}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Bestseller Products</h5>
                          <div className="tableOflow">
                            <table className="table table-borderless datatable">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Product Name</th>
                                  <th scope="col">Category</th>
                                  {/* <th scope="col">Regular Price</th>
                                <th scope="col">Selling Price</th>
                                <th scope="col">color</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {products?.length > 0 &&
                                  products.map((product) => (
                                    <tr key={product.order_id}>
                                      <td
                                        scope="row"
                                        onClick={() =>
                                          handleClick(
                                            `/singleproduct/${product.name}/p/${product.product_id}`,
                                            product.product_id
                                          )
                                        }
                                      >
                                        <a>{product.id}</a>
                                      </td>
                                      <td>{product.name}</td>
                                      <td>{product.category}</td>
                                      {/* <td>{product.regular_price}</td>
                                    <td>
                                      {product.selling_price}
                                    </td>
                                    <td>
                                      {product.color}
                                    </td> */}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-12">
                    <div className="card top-selling overflow-auto">
                      <div className="card-body pb-0">
                        <h5 className="card-title">Top Selling <span>| Today</span></h5>

                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th scope="col">Preview</th>
                              <th scope="col">Product</th>
                              <th scope="col">Price</th>
                              <th scope="col">Sold</th>
                              <th scope="col">Revenue</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-1.jpg" /></a></th>
                              <td><a href="#" className="text-primary fw-bold">Ut inventore ipsa voluptas nulla</a></td>
                              <td>$64</td>
                              <td className="fw-bold">124</td>
                              <td>$5,828</td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-2.jpg" /></a></th>
                              <td><a href="#" className="text-primary fw-bold">Exercitationem similique doloremque</a></td>
                              <td>$46</td>
                              <td className="fw-bold">98</td>
                              <td>$4,508</td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-3.jpg" /></a></th>
                              <td><a href="#" className="text-primary fw-bold">Doloribus nisi exercitationem</a></td>
                              <td>$59</td>
                              <td className="fw-bold">74</td>
                              <td>$4,366</td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-4.jpg" /></a></th>
                              <td><a href="#" className="text-primary fw-bold">Officiis quaerat sint rerum error</a></td>
                              <td>$32</td>
                              <td className="fw-bold">63</td>
                              <td>$2,016</td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-5.jpg" /></a></th>
                              <td><a href="#" className="text-primary fw-bold">Sit unde debitis delectus repellendus</a></td>
                              <td>$79</td>
                              <td className="fw-bold">41</td>
                              <td>$3,239</td>
                            </tr>
                          </tbody>
                        </table>

                      </div>

                    </div>
                  </div> */}
                    {/* <div className="col-md-4">
                    <div className="card">
                      <div className="card-body pb-0">
                        <h5 className="card-title">News &amp; Updates <span>| Today</span></h5>

                        <div className="news">
                          <div className="post-item clearfix">
                            <img src="assets/img/news-1.jpg" />
                            <h4><a href="#">Nihil blanditiis at in nihil autem</a></h4>
                            <p>Sit recusandae non aspernatur laboriosam. Quia enim eligendi sed ut harum...</p>
                          </div>

                          <div className="post-item clearfix">
                            <img src="assets/img/news-2.jpg" />
                            <h4><a href="#">Quidem autem et impedit</a></h4>
                            <p>Illo nemo neque maiores vitae officiis cum eum turos elan dries werona nande...</p>
                          </div>

                          <div className="post-item clearfix">
                            <img src="assets/img/news-3.jpg" />
                            <h4><a href="#">Id quia et et ut maxime similique occaecati ut</a></h4>
                            <p>Fugiat voluptas vero eaque accusantium eos. Consequuntur sed ipsam et totam...</p>
                          </div>

                          <div className="post-item clearfix">
                            <img src="assets/img/news-4.jpg" />
                            <h4><a href="#">Laborum corporis quo dara net para</a></h4>
                            <p>Qui enim quia optio. Eligendi aut asperiores enim repellendusvel rerum cuder...</p>
                          </div>

                          <div className="post-item clearfix">
                            <img src="assets/img/news-5.jpg" />
                            <h4><a href="#">Et dolores corrupti quae illo quod dolor</a></h4>
                            <p>Odit ut eveniet modi reiciendis. Atque cupiditate libero beatae dignissimos eius...</p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </section>
              </div>
            </div>
          </main>
        ) : (
          <div class="main access_denied">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="NoAccess text-center">
                    <i class="fa-solid fa-ban"></i>
                    <h1>You don't have access to this Page.</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer></Footer>
      </div>
    </>
  );
}

export default Dashboard;

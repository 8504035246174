import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import Loader from "../layout/Loader/Loader";
import "quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import CustomModal from '../layout/CustomModal'

function ModifyProduct(props) {
  const [desc, setDesc] = useState(props?.value ?? "");
  const [title, setTitle] = useState(props?.value ?? "");
  const [styelfit, setStyleAndFitTips] = useState(props?.value ?? "");
  const [cat, setCat] = useState(props?.value ?? "");
  const [subcat, setsubcat] = useState(props?.value ?? "");
  const [subsubcat, setsubsubcat] = useState(props?.value ?? "");
  const [stock, setStock] = useState(props?.value ?? "");
  const [regularPrice, setRegularPrice] = useState(props?.value ?? "");
  const [sellingPrice, setSellingPrice] = useState(props?.value ?? "");
  const [images, setImages] = useState(props?.value ?? "");
  const [size, setSize] = useState(props?.value ?? "");
  const [isTrending, setisTrending] = useState(props?.value ?? "Y");
  const [weight, setWeight] = useState(props?.value ?? "");
  const [styleNo, setStyleNo] = useState(props?.value ?? "");
  const [file, setFile] = useState("../images/dummy-image-square.jpg");
  const [files, setFiles] = useState([
    "../images/dummy-image-square.jpg",
    "../images/dummy-image-square.jpg",
  ]);
  const [showfiles, setshowFiles] = useState([
    "../images/dummy-image-square.jpg",
    "../images/dummy-image-square.jpg",
  ]);
  const [loading, setLoading] = useState(false);
  const [allCategories, setallCategories] = useState([]);
  const [allSubCategories, setallSubCategories] = useState([]);
  const [allSubSubCategories, setallSubSubCategories] = useState([]);
  const [colorVariants, setColorVariants] = useState({});
  const [newColorVariants, setNewColorVariants] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };


  const addColorVariant = () => {
    // Automatically add size variations for the newly added color
    const newSizes = Array.from({ length: 1 }, () => ({
      size: "",
      quantity: '',
      tooltip: '',
    }));
    const newColorVariant = {
      name: "",
      regularPrice: '',
      discount: '',
      salePrice: '',
      image: [],
      images: [],
      sizes: newSizes,
      video: ''
    };

    setNewColorVariants([...newColorVariants, newColorVariant]);
  };
  const handleColorVariantChange = async (index, value, column, id) => {
    const updatedColorVariants = structuredClone(colorVariants);
    if (column === "images") {
      updatedColorVariants[index][column] = [...updatedColorVariants[index][column], ...value]
    }
    else if (column === "image") {
      if (value && value.length > 0) {
        setLoading(true);
        const file = value[0];
        var formData = new FormData();
        formData.append("image", file);
        formData.append("parent_id", props?.location?.state);
        formData.append("variant", colorVariants[index].name);
        let res = await fetch("/api/admin/products/variant/images", {
          body: formData,
          method: "post",
        });

        updatedColorVariants[index][column] = value;
        setLoading(false);
        getProduct(props?.location?.state)
        setNewColorVariants([])
      }
    }
    else
      updatedColorVariants[index][column] = value;
    setColorVariants(updatedColorVariants);
  };

  const handlenewColorVariantChange = async (index, value, column, id) => {
    const updatedColorVariants = structuredClone(newColorVariants);
    if (column === "images") {
      updatedColorVariants[index][column] = [...updatedColorVariants[index][column], ...value]
    }
    else if (column === "image") {
      if (value && value.length > 0)
        updatedColorVariants[index][column] = value;
    }
    else
      updatedColorVariants[index][column] = value;
    setNewColorVariants(updatedColorVariants);
  };

  const removeGalleryImage = (index, i) => {
    const updatedColorVariants = structuredClone(colorVariants);
    updatedColorVariants[index]["images"].splice(i, 1);
    setColorVariants(updatedColorVariants);
  };


  const downloadGalleryImage = async (file) => {
    const response = await fetch(file);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'downloaded-image.jpg'; // The name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

  };


  const removeNewGalleryImage = (index, i) => {
    const updatedColorVariants = structuredClone(newColorVariants);
    updatedColorVariants[index]["images"].splice(i, 1);
    setNewColorVariants(updatedColorVariants);
  };



  const handleSizeVariantChange = (key, index, value, column) => {
    const updatedColorVariants = structuredClone(colorVariants);
    updatedColorVariants[key]["sizes"][index][column] = value;
    setColorVariants(updatedColorVariants);
  };


  const handleNewSizeVariantChange = (key, index, value, column) => {
    const updatedColorVariants = structuredClone(newColorVariants);
    updatedColorVariants[key]["sizes"][index][column] = value;
    setNewColorVariants(updatedColorVariants);
  };

  function getCategory() {
    fetch(`/api/admin/category/parent`)
      .then((response) => response.json())
      .then((data) => {

        if (data.length) {
          setallCategories(data);
        }
      });
  }

  function getSubCategory() {
    fetch(`/api/admin/category/` + cat)
      .then((response) => response.json())
      .then((data) => {

        if (data) {
          setallSubCategories(data);
        }
      });
  }

  function getSubSubCategory() {
    fetch(`/api/admin/category/` + subcat)
      .then((response) => response.json())
      .then((data) => {

        if (data) {
          setallSubSubCategories(data);
        }
      });
  }

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  useEffect(() => {
    getCategory();
    // addColorVariant()
    console.log("props", props)
    if (props?.location?.state) {
      getProduct(props?.location?.state)
    }
    // addColorVariant();
  }, []);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  function handleChange_allfiles(event) {
    setFiles(event.target.files);
    setshowFiles(Array.from(event.target.files));
  }

  async function publishProduct(isActive) {
    if (cat === "") {
      setPopupContent("Please select category")
      setShowPopup(true)

    } else if (subcat !== "" & subsubcat === "") {
      setPopupContent("Please select sub category")
      setShowPopup(true)
    } else {
      var sub_cat = ""
      if (subcat == "") {
        sub_cat = cat
      } else {
        sub_cat = subsubcat
      }
      setLoading(true);
      try {
        const requestOptions = {
          method: props.location?.state ? "PUT" : "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: title,
            description: Buffer.from(desc).toString('base64'),
            rich_description: Buffer.from(styelfit).toString('base64'),
            category: cat,
            active: isActive,
            trending: isTrending,
            weight: weight,
            style_no: styleNo,
            sub_category: sub_cat,
          }),
        };
        var url = "/api/admin/products";
        if (props.location?.state) {
          url = "/api/admin/products/" + props.location?.state;
        }
        var res = await fetch(url, requestOptions);
        var data = await res.json();

        if (data?.product?.id) {
          // var product_id = data.product.id;
          setLoading(false);
          setDesc("")
          setTitle("")
          setStyleAndFitTips("")
          setCat("")
          setsubcat("")
          setsubsubcat("")
          setisTrending("Y")
          setWeight("")
          setStyleNo("")
          setPopupContent("Product Updated Successfully")
          setShowPopup(true)

        }
      } catch (e) {
        setLoading(false);
        setPopupContent("Error in uploading product: " + e);
        setShowPopup(true)
      }
    }
  }

  function replaceImages(index, value, column) {
    if (value.length > 0) {
      const updatedColorVariants = structuredClone(colorVariants);
      updatedColorVariants[index][column] = value;
      setColorVariants(updatedColorVariants);
      updateVariantImages(updatedColorVariants[index].name, value);
    }
  }

  const removeColorVariant = (index) => {
    const updatedColorVariants = structuredClone(colorVariants);
    updatedColorVariants.splice(index, 1);
    setColorVariants(updatedColorVariants);
    // Remove the corresponding size variations
    const updatedSizes = [...sizes];
    updatedSizes.splice(index * 4, 4);
    setSizes(updatedSizes);
  };

  const handleSizeChange = (colorIndex, sizeIndex, property, value) => {
    const updatedColorVariants = structuredClone(colorVariants);
    // Ensure that the sizes array is initialized for the color variant
    if (!updatedColorVariants[colorIndex].sizes) {
      updatedColorVariants[colorIndex].sizes = [];
    }
    updatedColorVariants[colorIndex].sizes[sizeIndex][property] = value;
    setColorVariants(updatedColorVariants);
    console.log(colorVariants);
  };

  const handleRemoveSize = (colorIndex, sizeIndex) => {
    const updatedColorVariants = structuredClone(colorVariants);
    console.log("Before:", updatedColorVariants);
    updatedColorVariants[colorIndex].sizes.splice(sizeIndex, 1);
    console.log("After:", updatedColorVariants);
    setColorVariants(updatedColorVariants);
    console.log(colorVariants);
  };

  const removeSizeVariant = (index, i) => {
    const newColorVariant = structuredClone(colorVariants)
    newColorVariant[index].sizes.splice(i, 1);
    setColorVariants(newColorVariant);
  }

  const addSize = (colorIndex) => {
    if (colorIndex !== -1) {
      // If colorIndex is not null, update the specific color variant with a new size
      const newSize = { size: "", quantity: '', tooltip: '' };
      const updatedColorVariants = structuredClone(colorVariants);
      updatedColorVariants[colorIndex].sizes = [
        ...(updatedColorVariants[colorIndex].sizes || []), // Existing sizes
        newSize,
      ];
      setColorVariants(updatedColorVariants);
    } else {

      const newColorVariant = structuredClone(colorVariants)
      newColorVariant[colorIndex].sizes.push({ size: "", quantity: "", tooltip: '', })
      setColorVariants(newColorVariant);
    }
  };



  const addNewSize = (colorIndex) => {
    if (colorIndex !== -1) {
      // If colorIndex is not null, update the specific color variant with a new size
      const newSize = { size: "", quantity: '', tooltip: '' };
      const updatedColorVariants = structuredClone(newColorVariants);
      updatedColorVariants[colorIndex].sizes = [
        ...(updatedColorVariants[colorIndex].sizes || []), // Existing sizes
        newSize,
      ];
      setNewColorVariants(updatedColorVariants);
    } else {

      const newColorVariant = structuredClone(newColorVariants)
      newColorVariant[colorIndex].sizes.push({ size: "", quantity: "", tooltip: '', })
      setNewColorVariants(newColorVariant);
    }
  };



  // Function to generate a unique key for each size
  const generateSizeKey = (colorIndex, sizeIndex) =>
    `${colorIndex}-${sizeIndex}`;

  async function getProduct(id) {
    var res = await fetch(`/api/admin/products/${id}`);
    var data = await res.json();
    if (data?.product?.id) {
      setTitle(data.product.name)
      setDesc(data.product.description)
      setStyleAndFitTips(data.product.rich_description)
      setCat(data.product.category)
      setsubcat(data.product.sub_category)
      setWeight(data.product.weight)
      setColorVariants(data.product.colorVariants)
    } else {
      setPopupContent("Product not found")
      setShowPopup(true)
    }
  }

  async function updateVariantImages(id, images) {
    setLoading(true);
    if (props.location.state) {
      var product_id = props.location.state;
      var formData = new FormData();
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
      formData.append("parent_id", product_id);
      formData.append("variant", id);
      const res = await fetch("/api/admin/products/variant/galleryimages", {
        body: formData,
        method: "post",
      });
      if (res.ok) {
        setPopupContent("Variant images updated successfully.")
        setShowPopup(true)
        setLoading(false);
        getProduct(product_id)
        setNewColorVariants([])
      }
    } else {
      setLoading(false);
      setPopupContent("Incorrect Entry")
      setShowPopup(true)
    }
    setLoading(false);
  }


  async function insertVariant(index, e, id) {
    e.preventDefault()
    setLoading(true);
    if (props.location.state) {
      const variant = newColorVariants[index];
      var product_id = props.location.state;
      var formData = new FormData();
      formData.append("image", variant.image[0]);
      for (let i = 0; i < variant.images.length; i++) {
        formData.append("images", variant.images[i]);
      }
      formData.append("parent_id", product_id);
      formData.append("name", variant.name);
      formData.append("regularPrice", variant.regularPrice);
      formData.append("sellingPrice", Math.round(variant.regularPrice - (variant.regularPrice * (variant.discount / 100))));
      formData.append("sizes", JSON.stringify(variant.sizes));
      formData.append("video", variant.video);
      const res = await fetch("/api/admin/products/images", {
        body: formData,
        method: "post",
      });
      const data = await res.json();
      if (res.ok) {
        setPopupContent("Variant Inserted successfully.")
        setShowPopup(true)
        setLoading(false);
        getProduct(product_id)
        setNewColorVariants([])
      }
    } else {
      setLoading(false);
      setPopupContent("Incorrect Entry")
      setShowPopup(true)
    }
  }

  async function updateVariant(index, e, id) {
    e.preventDefault()
    const variant = colorVariants[index];
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "parent_id": props.location.state,
        "variant_id": index,
        "variant": variant
      }),
    };
    var url = "/api/admin/products/variant";
    var res = await fetch(url, requestOptions);
    if (res.ok) {
      setLoading(false);
      setPopupContent("Product Updated Successfully")
      setShowPopup(true)
    }
    setLoading(false);
  }



  function deleteVariant(index, e) {
    e.preventDefault()
    const newColorVariant = structuredClone(colorVariants)
    newColorVariant.splice(index, 1);
    setColorVariants(newColorVariant);
  }

  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
  }

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Header></Header>
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-6">
              <div className="pagetitle">
                <h1>Add Product</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Add Product</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 justify-content-end d-flex align-items-center gap-2">
              {/* <button type="button" className="whiteBtn">
                {" "}
                Discard
              </button> */}
              {/* <button
                type="button"
                className="whiteBtn"
                onClick={() => {
                  publishProduct("N");
                }}
              >
                {" "}
                Save draft
              </button> */}
              {/* <button
                type="submit"
                className="whiteBtn"
                onClick={() => {
                  publishProduct("Y");
                }}
              >
                {" "}
                {buttonName} {" Product"}
              </button> */}
            </div>
          </div>
          <div className="row">
            <section className="section addProduct">
              <div>
                <form className="mb-9">
                  <div className="row">
                    <div className="col-xl-8 col-12">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Title</h5>
                          <input
                            disabled
                            value={title}
                            onInput={(e) => setTitle(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''))}
                            placeholder="Write title here..."
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/* <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Price</h5>
                          <div className="row">
                            <div className="col-lg-6 col-12">
                              <h5 className="mb-2 text-1000">Regular price</h5>
                              <input
                                value={regularPrice}
                                onInput={(e) => setRegularPrice(e.target.value)}
                                placeholder="₹"
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="col-lg-6 col-12">
                              <h5 className="mb-2 text-1000">Sale price</h5>
                              <input
                                value={sellingPrice}
                                onInput={(e) => setSellingPrice(e.target.value)}
                                placeholder="₹"
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="productsVarients">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">Product Variations</h5>

                            <div className="varientWrapper">
                              {Object.keys(colorVariants).map((v_key, index) => {
                                const variant = colorVariants[v_key];
                                return (
                                  <div key={index} className="row">
                                    <div className="col-md-12">
                                      <h5 className="card-title">Variations {index + 1}</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Regular price <span>(₹)</span>
                                          </h5>
                                          <input
                                            value={variant.regularPrice}
                                            onChange={(e) =>
                                              handleColorVariantChange(
                                                v_key,
                                                e.target.value,
                                                "regularPrice"
                                              )
                                            }
                                            placeholder="₹"
                                            className="form-control"
                                            type="number"
                                            pattern="[0-9]+"
                                            min={0}
                                            max={100}
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Video Link
                                          </h5>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ex: https://www.youtube.com/embed/YSWMbwQu?si=JNDvbd3Oa070"
                                            value={variant.video}
                                            onChange={(e) =>
                                              handleColorVariantChange(
                                                v_key,
                                                e.target.value,
                                                "video"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Discount (%)
                                          </h5>
                                          <input
                                            value={variant.discount}
                                            onInput={(e) =>
                                              handleColorVariantChange(
                                                v_key,
                                                e.target.value,
                                                "discount"
                                              )
                                            }
                                            placeholder="₹"
                                            type="number"
                                            pattern="[0-9]+"
                                            min={0}
                                            max={100}
                                            className="form-control"
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Sale price <span>(₹)</span>
                                          </h5>
                                          <input
                                            value={variant.regularPrice - (variant.regularPrice * (variant.discount / 100))}
                                            placeholder="₹"
                                            type="text"
                                            className="form-control"
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Color Name
                                          </h5>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ex: White | Red | Green"
                                            value={variant.name}
                                            onChange={(e) =>
                                              handleColorVariantChange(
                                                v_key,
                                                e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''),
                                                "name"
                                              )
                                            }
                                          />
                                        </div>

                                        {variant.sizes.map((size, i) => {
                                          return (
                                            <div key={i} className="row align-items-center">
                                              <div className="col-md-12">
                                                <h5 className="text-1000">Size {i + 1}</h5>
                                              </div>
                                              <div className="col-md-3">
                                                <input
                                                  placeholder="Ex: 32,34.."
                                                  type="text"
                                                  className="form-control"
                                                  value={size.size}
                                                  onChange={(e) =>
                                                    handleSizeVariantChange(
                                                      v_key, i,
                                                      e.target.value,
                                                      "size"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-3">
                                                <input
                                                  className="form-control"
                                                  type="number"
                                                  placeholder="Stock/Quantity"
                                                  value={size.quantity}
                                                  onChange={(e) =>
                                                    handleSizeVariantChange(
                                                      v_key, i,
                                                      e.target.value,
                                                      "quantity"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-4">
                                                <textarea className="SIzeTip form-control"
                                                  value={size.tooltip}
                                                  onChange={(e) =>
                                                    handleSizeVariantChange(
                                                      v_key, i,
                                                      e.target.value,
                                                      "tooltip"
                                                    )
                                                  }
                                                  placeholder="Tooltip Text"></textarea>
                                              </div>
                                              <div className="col-md-2">
                                                <div>
                                                  <i class="fa-solid fa-trash" onClick={() => removeSizeVariant(v_key, i)}></i>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                        <div className="col-md-12">
                                          <div className="addMoreSize">
                                            <a className="adColBtn" onClick={() => addSize(v_key)}><i class="fa-solid fa-plus"></i> Add Size</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="colMainImage">
                                            <h5 className="mb-2 text-1000">
                                              Product Image
                                            </h5>
                                            <div className="gallImagess" >
                                              <label htmlFor="ggImage2" className="ppIamge" style={{ display: variant.image.length > 0 ? "none" : "flex" }}>
                                                <span className="delImage">
                                                  <i className="fa-solid fa-xmark"></i>
                                                </span>
                                                <i className="fa-solid fa-plus"></i>
                                              </label>
                                              <input
                                                id="ggImage2"
                                                type="file"
                                                accept="image/jpeg, image/png, image/jpg"
                                                onChange={(e) =>
                                                  handleColorVariantChange(
                                                    v_key,
                                                    e.target.files,
                                                    "image",
                                                    variant.id
                                                  )
                                                }
                                              />
                                              <div className="sp_image">
                                                {Array.isArray(variant.image) ? variant.image.map((file) => {
                                                  return (
                                                    <img
                                                      src={
                                                        typeof file == "string"
                                                          ? process.env.REACT_APP_BASE_URL + file
                                                          : URL.createObjectURL(file)
                                                      }
                                                      width="100%"
                                                    />
                                                  );
                                                }) : <img
                                                  src={variant.image}
                                                  width="100%"
                                                />
                                                }
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="colMainImage">
                                            <h5 className="mb-2 text-1000">
                                              Gallery Images
                                            </h5>
                                            <div className="">
                                              <div className="variant_images">
                                                {variant?.images?.map((file, i) => {
                                                  return (
                                                    <div className="p_r" key={i}>
                                                      <img
                                                        src={
                                                          typeof file == "string"
                                                            ? process.env.REACT_APP_BASE_URL + file
                                                            : URL.createObjectURL(file)
                                                        }
                                                        width="100%"
                                                      />
                                                      {/* <i className="fa-solid fa-download p_a" onClick={() => downloadGalleryImage(process.env.REACT_APP_BASE_URL + file)}></i> */}
                                                    </div>
                                                  );
                                                })}

                                                <div className="gallImagess">
                                                  <label htmlFor="ggImage">
                                                    <span className="delImage">
                                                      <i className="fa-solid fa-xmark"></i>
                                                    </span>
                                                    <i className="fa-solid fa-plus"></i>
                                                    this action will replace all gallery images
                                                  </label>
                                                  <input
                                                    id="ggImage"
                                                    multiple
                                                    type="file"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    onChange={(e) =>
                                                      replaceImages(v_key, Array.from(e.target.files), "images")
                                                    }
                                                  />
                                                </div>

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 text-right">
                                      <div>
                                        <button type="submit" className="blackbtn"
                                          onClick={(e) => updateVariant(v_key, e, variant.id)}>
                                          Update variant
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <hr />

                                    </div>

                                    {/* <div className="col-md-6">
                                      <div className="addMoreSize">
                                        <a className="adColBtn" onClick={() => addSize(index)}><i class="fa-solid fa-plus"></i> Add Size</a>
                                      </div>
                                    </div> */}
                                  </div>
                                )
                              })}
                            </div>
                            <br></br>
                            <div className="varientWrapper">
                              {newColorVariants.map((variant, index) => {
                                return (
                                  <div key={index} className="row">
                                    <div className="col-md-12">
                                      <h5 className="card-title"> New Variation {index + 1}</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Regular price <span>(₹)</span>
                                          </h5>
                                          <input
                                            value={variant.regularPrice}
                                            onChange={(e) =>
                                              handlenewColorVariantChange(
                                                index,
                                                e.target.value,
                                                "regularPrice"
                                              )
                                            }
                                            placeholder="₹"
                                            className="form-control"
                                            type="number"
                                            pattern="[0-9]+"
                                            min={0}
                                            max={100}
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Video Link
                                          </h5>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ex: https://www.youtube.com/embed/YSWMbwQu?si=JNDvbd3Oa070"
                                            value={variant.video}
                                            onChange={(e) =>
                                              handlenewColorVariantChange(
                                                index,
                                                e.target.value,
                                                "video"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Discount (%)
                                          </h5>
                                          <input
                                            value={variant.discount}
                                            onInput={(e) =>
                                              handlenewColorVariantChange(
                                                index,
                                                e.target.value,
                                                "discount"
                                              )
                                            }
                                            placeholder="₹"
                                            type="number"
                                            pattern="[0-9]+"
                                            min={0}
                                            max={100}
                                            className="form-control"
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Sale price <span>(₹)</span>
                                          </h5>
                                          <input
                                            value={variant.regularPrice - (variant.regularPrice * (variant.discount / 100))}
                                            placeholder="₹"
                                            type="text"
                                            className="form-control"
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Color Name
                                          </h5>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ex: White | Red | Green"
                                            value={variant.name}
                                            onChange={(e) =>
                                              handlenewColorVariantChange(
                                                index,
                                                e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''),
                                                "name"
                                              )
                                            }
                                          />
                                        </div>

                                        {variant.sizes.map((size, i) => {
                                          return (
                                            <div key={i} className="row align-items-center">
                                              <div className="col-md-12">
                                                <h5 className="text-1000">Size {i + 1}</h5>
                                              </div>
                                              <div className="col-md-3">
                                                <input
                                                  placeholder="Ex: 32,34.."
                                                  type="text"
                                                  className="form-control"
                                                  value={size.size}
                                                  onChange={(e) =>
                                                    handleNewSizeVariantChange(
                                                      index, i,
                                                      e.target.value,
                                                      "size"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-3">
                                                <input
                                                  className="form-control"
                                                  type="number"
                                                  placeholder="Stock/Quantity"
                                                  value={size.quantity}
                                                  onChange={(e) =>
                                                    handleNewSizeVariantChange(
                                                      index, i,
                                                      e.target.value,
                                                      "quantity"
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-4">
                                                <textarea className="SIzeTip form-control"
                                                  value={size.tooltip}
                                                  onChange={(e) =>
                                                    handleNewSizeVariantChange(
                                                      index, i,
                                                      e.target.value,
                                                      "tooltip"
                                                    )
                                                  }
                                                  placeholder="Tooltip Text"></textarea>
                                              </div>
                                              <div className="col-md-2">
                                                <div>
                                                  <i class="fa-solid fa-trash" onClick={() => removeSizeVariant(index, i)}></i>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                        <div className="col-md-12">
                                          <div className="addMoreSize">
                                            <a className="adColBtn" onClick={() => addNewSize(index)}><i class="fa-solid fa-plus"></i> Add Size</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="colMainImage">
                                            <h5 className="mb-2 text-1000">
                                              Product Image
                                            </h5>
                                            <div className="gallImagess" >
                                              <label htmlFor="ggImage2" className="ppIamge" style={{ display: variant.image.length > 0 ? "none" : "flex" }}>
                                                <span className="delImage">
                                                  <i className="fa-solid fa-xmark"></i>
                                                </span>
                                                <i className="fa-solid fa-plus"></i>
                                              </label>
                                              <input
                                                id="ggImage2"
                                                type="file"
                                                accept="image/jpeg, image/png, image/jpg"
                                                onChange={(e) =>
                                                  handlenewColorVariantChange(
                                                    index,
                                                    Array.from(e.target.files),
                                                    "image",
                                                    variant.id
                                                  )
                                                }
                                              />
                                              <div className="sp_image">
                                                {variant.image.map((file) => {
                                                  return (
                                                    <img
                                                      src={
                                                        typeof file == "string"
                                                          ? process.env.REACT_APP_BASE_URL + file
                                                          : URL.createObjectURL(file)
                                                      }
                                                      width="100%"
                                                    />
                                                  );
                                                })}
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="colMainImage">
                                            <h5 className="mb-2 text-1000">
                                              Gallery Images
                                            </h5>
                                            <div className="">
                                              <div className="variant_images">
                                                {variant?.images?.map((file, i) => {
                                                  return (
                                                    <div className="p_r" key={i}>
                                                      {<img alt="gallery"
                                                        src={URL.createObjectURL(file)}
                                                        width="100%"
                                                      />}
                                                      <i className="fa-solid fa-xmark p_a" onClick={() => removeNewGalleryImage(index, i)}></i>
                                                    </div>
                                                  );
                                                })}

                                                <div className="gallImagess">
                                                  <label htmlFor="ggImage">
                                                    <span className="delImage">
                                                      <i className="fa-solid fa-xmark"></i>
                                                    </span>
                                                    <i className="fa-solid fa-plus"></i>
                                                  </label>
                                                  <input
                                                    id="ggImage"
                                                    multiple
                                                    type="file"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    onChange={(e) =>
                                                      handlenewColorVariantChange(
                                                        index,
                                                        Array.from(e.target.files),
                                                        "images"
                                                      )
                                                    }
                                                  />
                                                </div>

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 text-right">
                                      <div>
                                        <button type="submit" className="blackbtn"
                                          onClick={(e) => insertVariant(index, e, variant.id)}>
                                          Update new variant
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <hr />

                                    </div>

                                    {/* <div className="col-md-6">
                                      <div className="addMoreSize">
                                        <a className="adColBtn" onClick={() => addSize(index)}><i class="fa-solid fa-plus"></i> Add Size</a>
                                      </div>
                                    </div> */}
                                  </div>
                                )
                              })}
                            </div>
                            <a className="addAnother" onClick={addColorVariant}><i className="fa-solid fa-plus"></i> Add Another Variation</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default ModifyProduct;

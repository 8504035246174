import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import CustomModal from "../layout/CustomModal";
import Resizer from "react-image-file-resizer";

function EditHome() {
  const [banners, setBanners] = useState([]);
  const [showEditSection, setShowEditSection] = useState(false);
  const [mobilebanners, setMobileBanners] = useState([]);
  const [instaImage, setInstaImage] = useState([]);
  const [cartBanner, setCartBanner] = useState([]);
  const [menTag, setMenTag] = useState([]);
  const [womenTag, setWomenTag] = useState([]);
  const [kidsTag, setKidsTag] = useState([]);
  const [checkoutBanner, setCheckoutBanner] = useState([]);
  const [trendingVideos, setTrendingVideos] = useState([]);
  const [budget, setBudget] = useState([]);
  const [homeSection1, setHomeSection1] = useState({});
  const [homeSection2, setHomeSection2] = useState({});
  const [desktopBanner, setDesktopBanner] = useState(false);
  const [desktopBannerLink, setDesktopBannerLink] = useState("");
  const [mobileBanner, setMobileBanner] = useState(false);
  const [mobileBannerLink, setMobileBannerLink] = useState("");
  const [marque1, setMarque1] = useState("");
  const [marque2, setMarque2] = useState("");
  const [instaId, setInstaId] = useState("");
  const [dollar, setDollar] = useState("");
  const [instaCount, setInstaCount] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  // edit budget section 
  const [catName, setCatName] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [image, setImage] = useState("");
  const [budgetId, setBudgetId] = useState("");
  const [category, setCategory] = useState("");

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  async function updateMarque(e, value, type) {
    e.preventDefault();
    const requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ value, type }),
    };
    var res = await fetch("/api/admin/marque", requestOptions);
    var data = await res.json();
    getMarque();
    setPopupContent("Updated Successfully");
    setShowPopup(true);
  }

  function getMarque() {
    fetch("/api/admin/marque")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setMarque1(data[0].value);
          setMarque2(data[1].value);
          setInstaCount(data[2].value);
          setInstaId(data[3].value);
          setDollar(data[4].value);
        }
      });
  }

  function getBanners() {
    fetch("/api/banner")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBanners(data);
        }
      });
  }
  function getMobileBanners() {
    fetch("/api/mobile-banner")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setMobileBanners(data);
        }
      });
  }

  function getInsta() {
    fetch("/api/instaImage")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setInstaImage(data);
        }
      });
  }

  function getTrendingVideos() {
    fetch("/api/trendingVideos")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setTrendingVideos([]);
          setTrendingVideos(data);
        }
      });
  }

  async function deleteBanner(id) {
    const requestOptions = {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    };
    var res = await fetch("/api/banner/" + id, requestOptions);
    var data = await res.json();
    getBanners();
    if (data.length > 0) {
      console.log("Banner deleted");
    }
  }

  async function deleteMobileBanner(id) {
    const requestOptions = {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    };
    var res = await fetch("/api/banner/" + id, requestOptions);
    var data = await res.json();
    getMobileBanners();
    if (data.length > 0) {
      console.log("Banner deleted");
    }
  }

  async function deleteInsta(id) {
    const requestOptions = {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    };
    var res = await fetch("/api/banner/" + id, requestOptions);
    var data = await res.json();
    getInsta();
    if (data.length > 0) {
      console.log("Banner deleted");
    }
  }

  async function deleteTrendingVideos(id) {
    const requestOptions = {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    };
    var res = await fetch("/api/banner/" + id, requestOptions);
    var data = await res.json();
    getTrendingVideos();
    if (data.length > 0) {
      console.log("Banner deleted");
    }
  }


  async function addBanner() {
    if (desktopBanner && desktopBannerLink === "") {
      setPopupContent("Please select a file");
      setShowPopup(true);
      return
    }
    var formData = new FormData();
    formData.append("image", desktopBanner);
    formData.append("url", desktopBannerLink);
    var res = await fetch("/api/banner", {
      body: formData,
      method: "post",
    });
    if (res.ok) {
      setDesktopBannerLink("");
      setPopupContent("Updated Successfully");
      setShowPopup(true);
      getBanners();
      console.log("Banner Added");
    }
  }

  async function addMobileBanner() {
    if (mobileBanner && mobileBannerLink === "") {
      setPopupContent("Please select a file");
      setShowPopup(true);
      return
    }
    var formData = new FormData();
    formData.append("image", mobileBanner);
    formData.append("url", mobileBannerLink);
    var res = await fetch("/api/mobile-banner", {
      body: formData,
      method: "post",
    });
    if (res.ok) {
      setMobileBannerLink("");
      setPopupContent("Updated Successfully");
      setShowPopup(true);
      getMobileBanners();
      console.log("Banner Added");
    }
  }

  async function addCartBanner(file) {
    var formData = new FormData();
    formData.append("image", file);
    var res = await fetch("/api/banner/cart", {
      body: formData,
      method: "post",
    });
    if (res.ok) {
      setPopupContent("Updated Successfully");
      setShowPopup(true);
      getCartBanner();
      console.log("Banner Added");
    }
  }

  async function addCheckoutBanner(file) {
    var formData = new FormData();
    formData.append("image", file);
    var res = await fetch("/api/banner/checkout", {
      body: formData,
      method: "post",
    });
    if (res.ok) {
      setPopupContent("Updated Successfully");
      setShowPopup(true);
      getCheckoutBanner();
      console.log("Banner Added");
    }
  }

  async function addTagImage(file, tag) {
    var formData = new FormData();
    formData.append("image", file);
    formData.append("tag", tag);
    var res = await fetch("/api/banner/tag", {
      body: formData,
      method: "post",
    });
    if (res.ok) {
      setPopupContent("Updated Successfully");
      setShowPopup(true);
      getTagsImages(tag);
    }
  }



  async function addInstaImage(file) {
    var formData = new FormData();
    formData.append("image", file);
    var res = await fetch("/api/instaImage", {
      body: formData,
      method: "post",
    });
    if (res.ok) {
      setPopupContent("Updated Successfully");
      setShowPopup(true);
      getInsta();
      console.log("Instagram Image Added");
    }
  }

  async function addTrendingVideos(file) {
    var formData = new FormData();
    formData.append("image", file);
    var res = await fetch("/api/trendingvideos", {
      body: formData,
      method: "post",
    });
    if (res.ok) {
      setPopupContent("Updated Successfully");
      setShowPopup(true);
      getTrendingVideos();
      console.log("Instagram Image Added");
    }
  }

  function getCartBanner() {
    fetch("/api/banner/cart")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCartBanner(data);
        }
      });
  }

  function getTagsImages(tag) {
    fetch(`/api/banner/tag/${tag}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (tag === "Men") {
            setMenTag(data);
          } else if (tag === "Women") {
            setWomenTag(data);
          }
          else if (tag === "Kids") {
            setKidsTag(data);
          }
        }
      });
  }

  function getCheckoutBanner() {
    fetch("/api/banner/checkout")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCheckoutBanner(data);
        }
      });
  }

  useEffect(() => {
    getBanners();
    getMobileBanners();
    getInsta();
    getTrendingVideos();
    getMarque();
    getCartBanner();
    getCheckoutBanner();
    getBudgetSection();
    getTagsImages("Men")
    getTagsImages("Women")
    getTagsImages("Kids")
    getHomeSections();
  }, []);

  function getBudgetSection() {
    fetch("/api/admin/budget")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBudget(data);
        }
      });
  }

  function getHomeSections() {
    fetch("/api/admin/home-sections")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setHomeSection1(data[0]);
          setHomeSection2(data[1]);
        }
      });
  }

  function handleChange(event) {
    if (event.target.files.length > 0) {
      const originalFile = event.target.files[0];
      setDesktopBanner(originalFile);
    } else {
      setDesktopBanner(false);
    }
    // addBanner(originalFile);
    // if (originalFile)
    //   Resizer.imageFileResizer(
    //     originalFile,
    //     1920, // max width
    //     730, // max height
    //     "png", // output format same as original file format
    //     70, // quality
    //     0, // rotation
    //     (uri) => {
    //       addBanner(uri);
    //     },
    //     'file' // output type
    //   );
  }

  function handleChange_insta(event) {
    const originalFile = event.target.files[0];
    addInstaImage(originalFile);
    // if (originalFile)
    //   Resizer.imageFileResizer(
    //     originalFile,
    //     400, // max width
    //     550, // max height
    //     "png", // output format same as original file format
    //     70, // quality
    //     0, // rotation
    //     (uri) => {
    //       addInstaImage(uri);
    //     },
    //     'file' // output type
    //   );
  }

  function handleChange_mobile(event) {
    if (event.target.files.length > 0) {
      const originalFile = event.target.files[0];
      setMobileBanner(originalFile);
    } else {
      setMobileBanner(false);
    }
    // if (originalFile)
    //   Resizer.imageFileResizer(
    //     originalFile,
    //     500, // max width
    //     700, // max height
    //     "png", // output format same as original file format
    //     70, // quality
    //     0, // rotation
    //     (uri) => {
    //       addMobileBanner(uri);
    //     },
    //     'file' // output type
    //   );
  }

  function handleChange_cart(event) {
    if (event.target.files.length > 0) {
      const originalFile = event.target.files[0];
      addCartBanner(originalFile);
    }
  }

  function handleChange_checkout(event) {
    if (event.target.files.length > 0) {
      const originalFile = event.target.files[0];
      addCheckoutBanner(originalFile);
    }
  }



  function handleChange_tag(event, tag) {
    if (event.target.files.length > 0) {
      const originalFile = event.target.files[0];
      addTagImage(originalFile, tag);
    }
  }



  function handleChange_videos(event) {
    addTrendingVideos(event.target.files[0]);
    // const originalFile = event.target.files[0];
    // console.log("originalFile")
    // if (originalFile)
    //   Resizer.imageFileResizer(
    //     originalFile,
    //     400, // max width
    //     550, // max height
    //     "mp4", // output format same as original file format
    //     70, // quality
    //     0, // rotation
    //     (uri) => {
    //       addTrendingVideos(uri);
    //     },
    //     'file' // output type
    //   );
  }

  const editClicked = (index) => {
    setCatName(budget[index].name)
    setCategory(budget[index].category)
    setMaxPrice(budget[index].max_price)
    setButtonText(budget[index].button_text)
    setImage(budget[index].image)
    setBudgetId(budget[index].id)
  }

  const handleChange_budget = async (event) => {
    if (event.target.files)
      setImage(event.target.files[0]);
    else
      setImage("")
  }

  const handleChange_sec1 = async (event) => {
    if (event.target.files)
      setHomeSection1({ ...homeSection1, image: event.target.files[0] });
  }

  const handleChange_sec2 = async (event) => {
    if (event.target.files)
      setHomeSection2({ ...homeSection2, image: event.target.files[0] });
  }

  const updateBudget = async () => {
    if (budgetId === "") {
      return
    }
    else if (catName !== "" && maxPrice !== "" && buttonText !== "") {
      var formData = new FormData();
      formData.append("image", image);
      formData.append("name", catName);
      formData.append("max_price", maxPrice);
      formData.append("button_text", buttonText);
      formData.append("id", budgetId);
      formData.append("category", category);
      var res = await fetch("/api/admin/budget", {
        body: formData,
        method: "post",
      });
      if (res.ok) {
        setCatName("")
        setMaxPrice("")
        setButtonText("")
        setImage("")
        setBudgetId("")
        setCategory("")
        setPopupContent("Updated Successfully");
        setShowPopup(true);
      }
      getBudgetSection();
      setShowEditSection(false);
    } else {
      setPopupContent("Please fill all values");
      setShowPopup(true);
    }
  }

  const updateSec1 = async () => {

    if (homeSection1.heading !== "" && homeSection1.sub_heading !== "") {
      var formData = new FormData();
      formData.append("image", homeSection1.image);
      formData.append("heading", homeSection1.heading);
      formData.append("sub_heading", homeSection1.sub_heading);
      formData.append("id", 1);
      var res = await fetch("/api/admin/home-section1", {
        body: formData,
        method: "post",
      });
      if (res.ok) {
        setPopupContent("Updated Successfully");
        setShowPopup(true);
        getHomeSections();
      }
    } else {
      setPopupContent("Please fill all values");
      setShowPopup(true);
    }
  }

  const updateSec2 = async () => {

    if (homeSection2.heading !== "" && homeSection2.sub_heading !== "" && homeSection2.sub_text !== "" && homeSection2.button_text !== "" && homeSection2.category !== "") {
      var formData = new FormData();
      formData.append("image", homeSection2.image);
      formData.append("heading", homeSection2.heading);
      formData.append("sub_heading", homeSection2.sub_heading);
      formData.append("sub_text", homeSection2.sub_text);
      formData.append("button_text", homeSection2.button_text);
      formData.append("category", homeSection2.category);
      formData.append("id", 2);
      var res = await fetch("/api/admin/home-section2", {
        body: formData,
        method: "post",
      });
      if (res.ok) {
        setPopupContent("Updated Successfully");
        setShowPopup(true);
        getHomeSections();
      }
    } else {
      setPopupContent("Please fill all values");
      setShowPopup(true);
    }
  }

  return (
    <>
      <Header></Header>

      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />

      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>Edit Home</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Dashboard</a>
                    </li>
                    <li>
                      <a href="#">EditHome</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Add Desktop Banners<span>(Size: 1920 x 730 pixels)</span>
                  </h5>
                  <div className="currentBanners">
                    <div className="row">
                      {banners?.map((banner) => {
                        return (
                          <div className="col-md-4">
                            <div className="singBan">
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL + banner.name
                                }
                              />
                              <i
                                className="fa-solid fa-circle-xmark"
                                onClick={(e) => deleteBanner(banner.id)}
                              ></i>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <h5 className="card-title">Upload New Banner</h5>
                  <input className="form-control mb-2" type="text" placeholder="Banner Link" value={desktopBannerLink}
                    onInput={(e) => setDesktopBannerLink(e.target.value)} />
                  <input
                    accept="image/*,.png,.gif,.jpeg,.jpg"
                    multiple=""
                    type="file"
                    tabindex="-1"
                    onChange={handleChange}
                  />
                  {/* <div
                    role="presentation"
                    tabindex="0"
                    className="mb-3 dropzone"
                  >
                   
                    <div className="text-600 fw-bold fs-9">
                      {" "}
                      Drag your photo here <span className="text-800">or </span>
                      <button type="button" className="p-0 btn btn-link">
                        {" "}
                        Browse from device
                      </button>
                      <br />
                      <img
                        className="mt-3"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAqCAMAAAAd31JXAAAARVBMVEUAAABueJFueJFueZFteJBweI9seJFtepJweI9ueJFwcI9ueJFueZFteZJvd5Bud5FueJFue5FuepFueZBueJBtd5BueJFT8Zn8AAAAFnRSTlMA339fn2B/H0C/EO9vP49Pr08v78+Pw4dpogAAARZJREFUOMvF1NluwyAQheFhiQl4jdPO+z9qQdgcSmjHyk3+C8uWPuGRF0jOeb/SxRTzGC5ajrm37DzYl0yeNZ4lu1k7p+tgudeUZz06Zv7ibjYty1Ur0cD9TLOucvlyXHTbPc87aZ3goqdIZ/GRADzS8nTN3kTrC5CtW6yTLfq4DaG1YdZ67dGB+fu3XZ8cU+bVjs3zxVcxdN/FHRY0YsFm6n2FZ+v94lp7pon0iZ3HTWoLWrBTmKi2oMDPavzaggKn1E1nXFnQA+OXMZyDBQXOrwoYVgECD8CNFXBtJVxbAXdsH4sWWLbAsgXOtuw7o/sHb+mwE53bpppML+y2oWxBYpZy+yhSVWZ8KInuVHLGq7/bpnC4H8tmOEloDtVDAAAAAElFTkSuQmCC"
                        width="40"
                        alt=""
                      />
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-12 text-right">
                      <button className="blackbtn mt-3" onClick={addBanner}>Update</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Add Mobile Banners<span>(Size: 500 x 700 pixels)</span>
                  </h5>
                  <div className="currentBanners">
                    <div className="row">
                      {mobilebanners?.map((banner) => {
                        return (
                          <div className="col-md-4">
                            <div className="singBan">
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL + banner.name
                                }
                              />
                              <i
                                className="fa-solid fa-circle-xmark"
                                onClick={(e) => deleteMobileBanner(banner.id)}
                              ></i>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <h5 className="card-title">Upload New Banner</h5>
                  <input className="form-control mb-2" type="text" placeholder="Banner Link" value={mobileBannerLink}
                    onInput={(e) => setMobileBannerLink(e.target.value)} />
                  <input
                    accept="image/*,.png,.gif,.jpeg,.jpg"
                    multiple=""
                    type="file"
                    tabindex="-1"
                    onChange={handleChange_mobile}
                  />
                  {/* <div
                    role="presentation"
                    tabindex="0"
                    className="mb-3 dropzone"
                  >
                    
                    <div className="text-600 fw-bold fs-9">
                      {" "}
                      Drag your photo here <span className="text-800">or </span>
                      <button type="button" className="p-0 btn btn-link">
                        {" "}
                        Browse from device
                      </button>
                      <br />
                      <img
                        className="mt-3"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAqCAMAAAAd31JXAAAARVBMVEUAAABueJFueJFueZFteJBweI9seJFtepJweI9ueJFwcI9ueJFueZFteZJvd5Bud5FueJFue5FuepFueZBueJBtd5BueJFT8Zn8AAAAFnRSTlMA339fn2B/H0C/EO9vP49Pr08v78+Pw4dpogAAARZJREFUOMvF1NluwyAQheFhiQl4jdPO+z9qQdgcSmjHyk3+C8uWPuGRF0jOeb/SxRTzGC5ajrm37DzYl0yeNZ4lu1k7p+tgudeUZz06Zv7ibjYty1Ur0cD9TLOucvlyXHTbPc87aZ3goqdIZ/GRADzS8nTN3kTrC5CtW6yTLfq4DaG1YdZ67dGB+fu3XZ8cU+bVjs3zxVcxdN/FHRY0YsFm6n2FZ+v94lp7pon0iZ3HTWoLWrBTmKi2oMDPavzaggKn1E1nXFnQA+OXMZyDBQXOrwoYVgECD8CNFXBtJVxbAXdsH4sWWLbAsgXOtuw7o/sHb+mwE53bpppML+y2oWxBYpZy+yhSVWZ8KInuVHLGq7/bpnC4H8tmOEloDtVDAAAAAElFTkSuQmCC"
                        width="40"
                        alt=""
                      />
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-12 text-right">
                      <button className="blackbtn mt-3" onClick={addMobileBanner}>Update</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Social Media</h5>
                  <div className="tableOuter adminTables">
                    <table className="table socilMedia">
                      <thead>
                        <tr>
                          <th>Menu Item</th>
                          <th>Link</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <i className="fa-brands fa-facebook-f"></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Paste Social Link"
                              className="form-control"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa-brands fa-instagram"></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Paste Social Link"
                              className="form-control"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa-brands fa-twitter"></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Paste Social Link"
                              className="form-control"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa-brands fa-youtube"></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Paste Social Link"
                              className="form-control"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button type="button" className="blackBtn">
                    Add New Social Media
                  </button>
                </div>
              </div>
            </div> */}

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Add/Edit instagram Images and Count
                    <span>(Size: Square)</span>
                  </h5>
                  <div className="currentBanners instaBanners">
                    <div className="row">
                      {instaImage?.map((banner) => {
                        return (
                          <div className="col-md-3">
                            <div className="singBan">
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL + banner.name
                                }
                                alt="banner"
                              />
                              <i
                                className="fa-solid fa-circle-xmark"
                                onClick={(e) => deleteInsta(banner.id)}
                              ></i>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <h5 className="card-title">Upload New Banner</h5>
                  <div
                    role="presentation"
                    tabindex="0"
                    className="mb-3 dropzone"
                  >
                    <input
                      accept="image/*,.png,.gif,.jpeg,.jpg"
                      multiple=""
                      type="file"
                      tabindex="-1"
                      style={{ display: "none" }}
                      onChange={handleChange_insta}
                    />
                    <div className="text-600 fw-bold fs-9">
                      {" "}
                      Drag your photo here <span className="text-800">or </span>
                      <button type="button" className="p-0 btn btn-link">
                        {" "}
                        Browse from device
                      </button>
                      <br />
                      <img
                        className="mt-3"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAqCAMAAAAd31JXAAAARVBMVEUAAABueJFueJFueZFteJBweI9seJFtepJweI9ueJFwcI9ueJFueZFteZJvd5Bud5FueJFue5FuepFueZBueJBtd5BueJFT8Zn8AAAAFnRSTlMA339fn2B/H0C/EO9vP49Pr08v78+Pw4dpogAAARZJREFUOMvF1NluwyAQheFhiQl4jdPO+z9qQdgcSmjHyk3+C8uWPuGRF0jOeb/SxRTzGC5ajrm37DzYl0yeNZ4lu1k7p+tgudeUZz06Zv7ibjYty1Ur0cD9TLOucvlyXHTbPc87aZ3goqdIZ/GRADzS8nTN3kTrC5CtW6yTLfq4DaG1YdZ67dGB+fu3XZ8cU+bVjs3zxVcxdN/FHRY0YsFm6n2FZ+v94lp7pon0iZ3HTWoLWrBTmKi2oMDPavzaggKn1E1nXFnQA+OXMZyDBQXOrwoYVgECD8CNFXBtJVxbAXdsH4sWWLbAsgXOtuw7o/sHb+mwE53bpppML+y2oWxBYpZy+yhSVWZ8KInuVHLGq7/bpnC4H8tmOEloDtVDAAAAAElFTkSuQmCC"
                        width="40"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Trending Videos <span>(Size: 1080 x 1920 pixels)</span>
                  </h5>
                  <div className="currentBanners instaBanners">
                    <div className="row">
                      {trendingVideos?.map((video, index) => {
                        return (
                          <div className="col-md-4" key={index}>
                            <div className="singBan">
                              <video controls>
                                <source
                                  src={
                                    process.env.REACT_APP_BASE_URL + video.name
                                  }
                                  type="video/mp4"
                                />
                              </video>
                              <i
                                className="fa-solid fa-circle-xmark"
                                onClick={(e) => deleteTrendingVideos(video.id)}
                              ></i>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <h5 className="card-title">Upload New Video</h5>
                  <div
                    role="presentation"
                    tabindex="0"
                    className="mb-3 dropzone"
                  >
                    <input
                      accept="video/*"
                      multiple=""
                      type="file"
                      tabindex="-1"
                      style={{ display: "none" }}
                      onChange={handleChange_videos}
                    />
                    <div className="text-600 fw-bold fs-9">
                      {" "}
                      Drag your photo here <span className="text-800">or </span>
                      <button type="button" className="p-0 btn btn-link">
                        {" "}
                        Browse from device
                      </button>
                      <br />
                      <img
                        className="mt-3"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAqCAMAAAAd31JXAAAARVBMVEUAAABueJFueJFueZFteJBweI9seJFtepJweI9ueJFwcI9ueJFueZFteZJvd5Bud5FueJFue5FuepFueZBueJBtd5BueJFT8Zn8AAAAFnRSTlMA339fn2B/H0C/EO9vP49Pr08v78+Pw4dpogAAARZJREFUOMvF1NluwyAQheFhiQl4jdPO+z9qQdgcSmjHyk3+C8uWPuGRF0jOeb/SxRTzGC5ajrm37DzYl0yeNZ4lu1k7p+tgudeUZz06Zv7ibjYty1Ur0cD9TLOucvlyXHTbPc87aZ3goqdIZ/GRADzS8nTN3kTrC5CtW6yTLfq4DaG1YdZ67dGB+fu3XZ8cU+bVjs3zxVcxdN/FHRY0YsFm6n2FZ+v94lp7pon0iZ3HTWoLWrBTmKi2oMDPavzaggKn1E1nXFnQA+OXMZyDBQXOrwoYVgECD8CNFXBtJVxbAXdsH4sWWLbAsgXOtuw7o/sHb+mwE53bpppML+y2oWxBYpZy+yhSVWZ8KInuVHLGq7/bpnC4H8tmOEloDtVDAAAAAElFTkSuQmCC"
                        width="40"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Marque 1</h5>
                  <div className="row">
                    <div className="col-md-12">
                      <textarea
                        value={marque1}
                        className="form-control"
                        onChange={(e) => setMarque1(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="col-md-12 text-right">
                      <button
                        className="blackbtn"
                        onClick={(e) => updateMarque(e, marque1, 1)}
                      >
                        Update
                      </button>
                    </div>
                  </div>

                  <h5 className="card-title">Marque 2</h5>
                  <div className="instaBanners">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="singBan">
                          <textarea
                            className="form-control"
                            textarea
                            value={marque2}
                            onChange={(e) => setMarque2(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12 text-right">
                        <button
                          className="blackbtn"
                          onClick={(e) => updateMarque(e, marque2, 2)}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Add Instagram Count</h5>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        className="form-control  mb-4"
                        type="text"
                        value={instaCount}
                        onChange={(e) => setInstaCount(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 text-right">
                      <button
                        type="button"
                        className="blackbtn"
                        onClick={(e) =>
                          updateMarque(e, instaCount, "InstaCount")
                        }
                      >
                        Update
                      </button>
                    </div>
                  </div>

                  <h5 className="card-title">Add Instagram Title</h5>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        className="form-control  mb-4"
                        type="text"
                        value={instaId}
                        onChange={(e) => setInstaId(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 text-right">
                      <button
                        type="button"
                        className="blackbtn"
                        onClick={(e) => updateMarque(e, instaId, "InstaId")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Dollar to INR</h5>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        className="form-control  mb-4"
                        type="text"
                        value={dollar}
                        onChange={(e) => setDollar(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 text-right">
                      <button
                        type="button"
                        className="blackbtn"
                        onClick={(e) => updateMarque(e, dollar, "dollar")}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="card-title">
                        Cart Banner <span>(Size: 1920 x 352 pixels)</span>
                      </h5>
                      <div className="singBan">
                        {cartBanner?.map((banner) => {
                          return (
                            <img
                              src={process.env.REACT_APP_BASE_URL + banner.name}
                            />
                          );
                        })}
                      </div>
                      <input
                        type="file"
                        className="form-control"
                        accept="image/*,.png,.jpeg,.jpg"
                        onChange={handleChange_cart}
                      />
                    </div>
                    <div className="col-md-6">
                      <h5 className="card-title">
                        Checkout Banner <span>(Size: 1920 x 352 pixels)</span>
                      </h5>
                      <div className="singBan">
                        {checkoutBanner?.map((banner) => {
                          return (
                            <img
                              src={process.env.REACT_APP_BASE_URL + banner.name}
                            />
                          );
                        })}
                      </div>
                      <input
                        type="file"
                        className="form-control"
                        accept="image/*,.png,.jpeg,.jpg"
                        onChange={handleChange_checkout}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Shop by Budget Section</h5>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="tableOflow dashboard">
                        <table className="table table-borderless budgetTable">
                          <thead>
                            <tr>
                              <th>Sno</th>
                              <th>Name</th>
                              <th>Image</th>
                              <th>Category</th>
                              <th>Max Price</th>
                              <th>Button text</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {budget?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.id}</td>
                                  <td>{item.name}</td>
                                  <td>
                                    <img
                                      src={
                                        process.env.REACT_APP_BASE_URL + item.image
                                      }
                                    />
                                  </td>
                                  {/* <td>{item.minPrice}</td> */}
                                  <td>{item.category}</td>
                                  <td>Under ₹{item.max_price}</td>
                                  <td>{item.button_text}</td>
                                  <td>
                                    <div className="actions">
                                      <button className="editBtn" onClick={e => { editClicked(index); setShowEditSection(true); }}>
                                        <i className="fa-regular fa-pen-to-square"></i>
                                        Edit
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>{
                    showEditSection ?
                      <div className="row">
                        <div className="col-md-12">
                          <h5 className="card-title">Edit Shop by Budget Section</h5>
                        </div>
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Enter Category Name"
                            className="form-control"
                            value={catName}
                            onChange={(e) => setCatName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            placeholder="Category"
                            className="form-control"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="number"
                            placeholder="Enter Max Price"
                            className="form-control"
                            value={maxPrice}
                            onChange={(e) => setMaxPrice(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="Text"
                            placeholder="Button Text"
                            className="form-control"
                            value={buttonText}
                            onChange={(e) => setButtonText(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <input type="file" className="form-control" onChange={handleChange_budget} />
                        </div>
                        <div className="col-md-2">
                          <div className="singBan">
                            {image !== "" ?
                              <img src={typeof image !== "string"
                                ? URL.createObjectURL(image)
                                : "https://vastrakala.com/static/media/women.7ec60f71.jpg"}></img>
                              : <img src="https://vastrakala.com/images/dummy-image-square.jpg" />}
                          </div>
                        </div>
                        <div className="col-md-12 text-right">
                          <button className="blackbtn" onClick={updateBudget}>
                            Update</button>
                        </div>
                      </div> : ''
                  }

                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Tags Images</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="singBan">
                        {menTag?.map((banner) => {
                          return (
                            <img
                              src={process.env.REACT_APP_BASE_URL + banner.name}
                            />
                          );
                        })}
                        <h4>Men</h4>
                        <input type="file"
                          accept="image/*,.png,.jpeg,.jpg"
                          onChange={e => { handleChange_tag(e, "Men") }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="singBan">
                        {womenTag?.map((banner) => {
                          return (
                            <img
                              src={process.env.REACT_APP_BASE_URL + banner.name}
                            />
                          );
                        })}
                      </div>
                      <h4>Women</h4>
                      <input type="file"
                        accept="image/*,.png,.jpeg,.jpg"
                        onChange={e => { handleChange_tag(e, "Women") }} />
                    </div>
                    <div className="col-md-6">
                      <div className="singBan">
                        {kidsTag?.map((banner) => {
                          return (
                            <img
                              src={process.env.REACT_APP_BASE_URL + banner.name}
                            />
                          );
                        })}
                      </div>
                      <h4>Kids</h4>
                      <input type="file"
                        accept="image/*,.png,.jpeg,.jpg"
                        onChange={e => { handleChange_tag(e, "Kids") }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Home Section 1 Edit</h5>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Heading"
                        className="form-control"
                        value={homeSection1?.heading}
                        onInput={(e) => setHomeSection1({ ...homeSection1, heading: e.target.value })}
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Sub Heading"
                        className="form-control"
                        value={homeSection1?.sub_heading}
                        onInput={(e) => setHomeSection1({ ...homeSection1, sub_heading: e.target.value })}
                      />
                    </div>
                    <div className="col-md-6">
                      <input type="file" className="form-control" onChange={handleChange_sec1} />
                    </div>
                    <div className="col-md-6">
                      <div className="singBan">
                        <label>Size: 850x530</label>
                        {homeSection1.image && homeSection1.image !== "" ?
                          <img src={typeof homeSection1?.image !== "string"
                            ? URL.createObjectURL(homeSection1?.image)
                            : process.env.REACT_APP_BASE_URL + homeSection1.image}></img>
                          : <img src="https://vastrakala.com/static/media/women.7ec60f71.jpg" />}
                      </div>
                    </div>
                    <div className="col-md-12 text-right">
                      <button className="blackbtn" onClick={updateSec1}>
                        Update</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Home Section 2 Edit</h5>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Heading"
                        className="form-control"
                        value={homeSection2?.heading}
                        onInput={(e) => setHomeSection2({ ...homeSection2, heading: e.target.value })}
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Sub Heading"
                        className="form-control"
                        value={homeSection2?.sub_heading}
                        onInput={(e) => setHomeSection2({ ...homeSection2, sub_heading: e.target.value })}
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Sub text"
                        className="form-control"
                        value={homeSection2?.sub_text}
                        onInput={(e) => setHomeSection2({ ...homeSection2, sub_text: e.target.value })}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="Text"
                        placeholder="Button Text"
                        className="form-control"
                        value={homeSection2?.button_text}
                        onInput={(e) => setHomeSection2({ ...homeSection2, button_text: e.target.value })}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="Text"
                        placeholder="Category"
                        className="form-control"
                        value={homeSection2?.category}
                        onInput={(e) => setHomeSection2({ ...homeSection2, category: e.target.value })}
                      />
                    </div>
                    <div className="col-md-6">
                      <input type="file" className="form-control" onChange={handleChange_sec2} />
                    </div>
                    <div className="col-md-6">
                      <div className="singBan">
                        <label>Size: 1920x700</label>
                        {homeSection2.image && homeSection2?.image !== "" ?
                          <img src={typeof homeSection2?.image !== "string"
                            ? URL.createObjectURL(homeSection2?.image)
                            : process.env.REACT_APP_BASE_URL + homeSection2.image}></img>
                          : <img src="https://vastrakala.com/static/media/women.7ec60f71.jpg" />}
                      </div>
                    </div>
                    <div className="col-md-12 text-right">
                      <button className="blackbtn" onClick={updateSec2}>
                        Update</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default EditHome;

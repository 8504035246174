import React, { Fragment, useState, useEffect } from "react";
import "./LoginSignUp.css";
import Loader from "../layout/Loader/Loader";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../src/images/VastrakalaLogoBlack.svg";
import CustomModal from "../layout/CustomModal";
import { loadingState } from "../../actions/userAction";
import "./ForgotPassword.css";

const LoginSignUp = ({ history, location }) => {
  const secret = process.env.REACT_APP_SECRET;
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [idDisabled, setidDisabled] = useState(false);
  const [idDisabled2, setidDisabled2] = useState(false);
  const [otp, setOTP] = useState("");

  const { error, message, loading } = useSelector(
    (state) => state.forgotPassword
  );

  const [email, setEmail] = useState("");
  const [showSentButton, setShowSentButton] = useState(true);

  const forgotPasswordSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadingState(true));
    // const myForm = new FormData();

    // myForm.set("email", email);
    // dispatch(forgotPassword(myForm));

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
      }),
    };
    var res = await fetch("/api/register/forgotPassword", requestOptions);
    if (res.ok) {
      var data = await res.json();
      dispatch(loadingState(false));
      setidDisabled(true);
      setPopupContent("OTP sent");
      setShowPopup(true);
      setShowSentButton(false);
    } else {
      let error = await res.text();
      setPopupContent2(error.replace(/"/g, ""));
      setShowPopup2(true);
      dispatch(loadingState(false));
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [showPopup2, setShowPopup2] = useState(false);
  const [popupContent2, setPopupContent2] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const handlePopupClose2 = () => {
    setShowPopup2(false); // Close the popup
  };

  const resetPassword = async (e) => {
    e.preventDefault();

    if (newPassword === "" || newPassword2 === "") {
      setPopupContent2("New password is empty");
      setShowPopup2(true);
    } else if (newPassword !== newPassword2) {
      setPopupContent2("New password are not matching");
      setShowPopup2(true);
    } else {
      dispatch(loadingState(true));
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: Buffer.from(newPassword).toString("base64"),
          email: Buffer.from(email).toString("base64"),
          otp: otp,
        }),
      };
      var res = await fetch("/api/register/reset", requestOptions);
      if (res.ok) {
        dispatch(loadingState(false));
        let data = await res.json();
        setPopupContent("Password updated.");
        setShowPopup(true);
        history.push("/LoginSignup");
      } else {
        dispatch(loadingState(false));
        let data = await res.json();
        setPopupContent2(data.message);
        setShowPopup2(true);
      }
    }
  };

  return (
    <>
      {/* <Header></Header> */}
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <CustomModal
        type="warning"
        content={popupContent2}
        showPopup={showPopup2}
        onClose={handlePopupClose2}
      />
      <div className="newLoginSignup">
        <div className="loginLeft">
          <Link to="../../../../">
            <img src={Logo} />
          </Link>
          <h2 className="title">
            Register & Be A Part Of The Vastrakala Circle!
          </h2>
          <br />
          <p>Enjoy exclusive benefits like:</p>
          <ul>
            <li>
              - Flat 10% Off on your first order* | Use code: <b>VASTRAKAL10</b>
            </li>
            <li>- Exclusive early collection showcase</li>
            <li>- Access amazing offers, discounts and more</li>
          </ul>
          <br />
          <p>Join Now!</p>
          <br />
          <p>OR</p>
          <br />
          <Link to="../../../../" className="blackbtn">
            Go Back To Homepage
          </Link>
        </div>
        <div className="loginRight">
          <Link className="ggBack" to="/loginSignup">
            <i class="fa-solid fa-arrow-left-long"></i> Go Back
          </Link>
          <div className="forgotPasswordBox text-center">
            <h2 className="forgotPasswordHeading">Forgot Password</h2>
            <p>
              Enter your registered email address below to recieve the
              verification code to change your password
            </p>
            <form
              className="forgotPasswordForm"
              onSubmit={forgotPasswordSubmit}
            >
              <div className="forgotPasswordEmail">
                <input
                  type="email"
                  placeholder="Email"
                  required
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={idDisabled}
                />
              </div>
              {showSentButton ? (
                <input
                  type="submit"
                  value="Send"
                  className="forgotPasswordBtn"
                />
              ) : (
                ""
              )}
              {!showSentButton ? (
                <>
                  <p>
                    As a next step, please verify your account by entering the
                    verification code shared to your registered email
                  </p>
                  <p className="noCode">
                    Didn't get code{" "}
                    <a
                      className="forgotPasswordBtn"
                      onClick={forgotPasswordSubmit}
                    >
                      Resend!
                    </a>
                  </p>
                </>
              ) : (
                ""
              )}
            </form>
            <form
              className="forgotPasswordForm"
              onSubmit={resetPassword}
              style={{ display: `${idDisabled ? "block" : "none"}` }}
            >
              <div className="forgotPasswordEmail">
                {/* <MailOutlineIcon /> */}
                <input
                  type="text"
                  name="OTP"
                  id=""
                  placeholder="OTP"
                  value={otp}
                  onInput={(e) => setOTP(e.target.value)}
                />
              </div>

              {/* <input
                type="button
                className="forgotPasswordBtn"
                value="Verify OTP"
              /> */}
              <br />
              <div className="forgotPasswordEmail">
                {/* <MailOutlineIcon /> */}
                <input
                  type={showPassword ? "text" : "password"}
                  name="Password"
                  id=""
                  placeholder="New Password Min 8 Character"
                  value={newPassword}
                  onInput={(e) => setNewPassword(e.target.value)}
                />
                <i
                  onClick={(e) => setShowPassword(!showPassword)}
                  className={`fa-regular fa-eye${showPassword ? "-slash" : ""}`}
                ></i>
              </div>
              <br></br>
              <div className="forgotPasswordEmail">
                {/* <MailOutlineIcon /> */}
                <input
                  type={showPassword2 ? "text" : "password"}
                  name="cPassword"
                  id=""
                  placeholder="Confirm New Password"
                  value={newPassword2}
                  onInput={(e) => setNewPassword2(e.target.value)}
                />
                <i
                  onClick={(e) => setShowPassword2(!showPassword2)}
                  className={`fa-regular fa-eye${
                    showPassword2 ? "-slash" : ""
                  }`}
                ></i>
              </div>
              <br></br>
              <input
                type="submit"
                value="Update Password"
                className="forgotPasswordBtn"
              />
            </form>
            <hr />
            <div className="logRegis">
              <Link to="/loginSignup">Login</Link>
              <p>|</p>
              <Link to="/loginSignup">Sign Up</Link>
            </div>
          </div>
        </div>
      </div>

      <Fragment>{loading ? <Loader /> : <Fragment></Fragment>}</Fragment>
    </>
  );
};

export default LoginSignUp;

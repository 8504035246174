import React, { useEffect, useRef } from "react";
// import CheckoutSteps from "../Cart/CheckoutSteps";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import "./payment.css";
import { createOrder, clearErrors } from "../../actions/orderAction";

const stripePromise = loadStripe("pk_test_51OdEdkSIPi4OIgwV2VUTAyJM9McchQXRgq7Nuk8Y1YsBBbbMIUJXlqOsAthcSbea3azqixGGeZSo6cy6ckHJ8aW800sQcLUPe0")

const Payment = ({ history }) => {
  const orderInfo = JSON.parse(localStorage.getItem("orderInfo"));
  const dispatch = useDispatch();
  const alert = useAlert();
  const payBtn = useRef(null);

  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);

  const paymentData = {
    amount: Math.round(orderInfo.totalPrice * 100),
  };

  // const options = {
  //   clientSecret: 'sk_test_51OdEdkSIPi4OIgwVebOVjtCW2HkCLXtgcFnemPXR3mIspzB5BAU41ycDNQj5ysQQaqLFktnPXNXFlXLd2tboI8XJ00klNK7cX9',
  // };

  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: orderInfo.subtotal,
    taxPrice: orderInfo.tax,
    shippingPrice: orderInfo.shippingCharges,
    totalPrice: orderInfo.totalPrice,
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    payBtn.current.disabled = true;

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        "/api/v1/payment/process",
        paymentData,
        config
      );

      const client_secret = data.client_secret;

      // if (!stripe || !elements) return;

      // const result = await stripe.confirmCardPayment(client_secret, {
      //   payment_method: {
      //     // card: elements.getElement(CardNumberElement),
      //     billing_details: {
      //       name: user.name,
      //       email: user.email,
      //       address: {
      //         line1: shippingInfo.address,
      //         city: shippingInfo.city,
      //         state: shippingInfo.state,
      //         postal_code: shippingInfo.pinCode,
      //         country: shippingInfo.country,
      //       },
      //     },
      //   },
      // });

      // if (result.error) {
      //   payBtn.current.disabled = false;

      //   alert.error(result.error.message);
      // } else {
      //   if (result.paymentIntent.status === "succeeded") {
      //     order.paymentInfo = {
      //       id: result.paymentIntent.id,
      //       status: result.paymentIntent.status,
      //     };

      //     dispatch(createOrder(order));

      //     history.push("/success");
      //   } else {
      //     alert.error("There's some issue while processing payment ");
      //   }
      // }
    } catch (error) {
      payBtn.current.disabled = false;
      alert.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, error, alert]);

  const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'inr',

    // Customizable with appearance API.
    // appearance: {/*...*/ },
  };

  return (
    <Elements stripe={stripePromise}>
    </Elements>
  );
};

export default Payment;

import React, { Fragment, useState, useEffect } from "react";
import Loader from "../layout/Loader/Loader";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../src/images/VastrakalaLogoBlack.svg";
import CustomModal from "../layout/CustomModal";
import { loadingState } from "../../actions/userAction";
function ForgotPass({ history, location }) {
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [idDisabled, setidDisabled] = useState(false);
  const [idDisabled2, setidDisabled2] = useState(false);
  const [otp, setOTP] = useState("");

  const { error, message, loading } = useSelector(
    (state) => state.forgotPassword
  );

  const [email, setEmail] = useState("");
  const [showSentButton, setShowSentButton] = useState(true);

  const forgotPasswordSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadingState(true));
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
      }),
    };
    var res = await fetch("/api/register/admin/forgotPassword", requestOptions);
    if (res.ok) {
      var data = await res.json();
      dispatch(loadingState(false));
      setidDisabled(true);
      setPopupContent("OTP sent");
      setShowPopup(true);
      setShowSentButton(false);
    } else {
      let error = await res.text();
      setPopupContent2(error.replace(/"/g, ""));
      setShowPopup2(true);
      dispatch(loadingState(false));
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [showPopup2, setShowPopup2] = useState(false);
  const [popupContent2, setPopupContent2] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const handlePopupClose2 = () => {
    setShowPopup2(false); // Close the popup
  };

  const resetPassword = async (e) => {
    e.preventDefault();

    if (newPassword === "" || newPassword2 === "") {
      setPopupContent2("New password is empty");
      setShowPopup2(true);
    } else if (newPassword !== newPassword2) {
      setPopupContent2("New password are not matching");
      setShowPopup2(true);
    } else {
      dispatch(loadingState(true));
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: Buffer.from(newPassword).toString("base64"),
          email: Buffer.from(email).toString("base64"),
          otp: otp,
        }),
      };
      var res = await fetch("/api/register/admin/reset", requestOptions);
      if (res.ok) {
        dispatch(loadingState(false));
        let data = await res.json();
        setPopupContent("Password updated.");
        setShowPopup(true);
        history.push("/admin");
      } else {
        dispatch(loadingState(false));
        let data = await res.json();
        setPopupContent2(data.message);
        setShowPopup2(true);
      }
    }
  };
  return (
    <>
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <CustomModal
        type="warning"
        content={popupContent2}
        showPopup={showPopup2}
        onClose={handlePopupClose2}
      />
      <div className="loginPage h100">
        <div className="row h100">
          <div className="col-md-3">
            <div className="loginleft">
              <Link to="/">
                <img src="./images/VastrakalaLogoWhite.svg" width="100%" />
              </Link>
              <div className="welcomeDiv">
                <h1>Welcome</h1>
                <p>to Vastrakala</p>
              </div>
              <p>Copyright @ 2024 Vastrakala</p>
            </div>
          </div>
          <div className="col-md-9">
            <div className="loginFormContainer">
              <div className="card">
                <div className="card-body">
                  <div class="pt-4 pb-2 text-center">
                    <h5 class="card-title text-center fs-4">Forgot Your Password</h5>
                    <p>Enter your registered email address below to recieve the verification code to change your password</p>
                  </div>
                  <div className="Forg_Pass_Form">
                    <form className="row g-3 needs-validation" onSubmit={forgotPasswordSubmit}>
                      <div className="col-md-12">
                        <div className="input-group has-validation">
                          <input
                          placeholder="Email"
                            type="text"
                            name="email"
                            className="form-control"
                            id=""
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={idDisabled}
                          />
                          <div className="invalid-feedback">
                            Please enter correct Email.
                          </div>
                        </div>
                      </div>

                      {showSentButton ? (
                        <div className="col-md-12 text-right">
                          <button className="loginBtn">Send OTP</button>
                        </div>
                      ) : (
                        ""
                      )}
                      {!showSentButton ? (
                        <><p>
                          As a next step, please verify your account by entering the
                          verification code shared to your registered email
                        </p><div className="noOtp"><p>Didn't get code <a onClick={forgotPasswordSubmit}>Resend!</a></p></div></>
                      ) : (
                        ""
                      )}
                    </form>

                    <form className="row g-3 needs-validation forgotPassForm" onSubmit={resetPassword}
                      style={{ display: `${idDisabled ? "block" : "none"}`, width: "100%" }} >
                      <div className="col-md-12">
                        <label for="updatedPass" className="form-label">
                          OTP
                        </label>
                        <div className="input-group has-validation">
                          <input
                            type="text"
                            name="OTP"
                            className="form-control"
                            required
                            value={otp}
                            onInput={(e) => setOTP(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            Please enter correct OTP.
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12 text-right">
                        <button className="loginBtn">Submit</button>
                      </div> */}
                      <div className="col-md-12">
                        <label for="updatedPass" className="form-label">
                          New Password
                        </label>
                        <div className="input-group has-validation position-relative">
                          <input
                            name="password"
                            className="form-control"
                            required
                            type={showPassword ? "text" : "password"}
                            value={newPassword}
                            onInput={(e) => setNewPassword(e.target.value)}
                          /><i
                            onClick={(e) => setShowPassword(!showPassword)}
                            className={`fa-regular fa-eye${showPassword ? "-slash" : ""}`}
                          ></i>
                          <div className="invalid-feedback">
                            Password must have atleat 8 character.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 input-group">
                        <label for="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <div className="input-group">

                        <input
                          type={showPassword2 ? "text" : "password"}
                          name="password"
                          className="form-control"
                          required
                          value={newPassword2}
                          onInput={(e) => setNewPassword2(e.target.value)}
                        />
                        <i
                          onClick={(e) => setShowPassword2(!showPassword2)}
                          className={`fa-regular fa-eye${showPassword2 ? "-slash" : ""}`}
                        ></i>
                        <div className="invalid-feedback">
                          Please enter correct password!
                        </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button className="loginBtn" type="submit">
                          Change Password
                        </button>
                      </div>
                    </form>
                    <div className="col-md-12">
                      <p className="forPas">
                        <Link to="/admin">Back To Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPass;

import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { logout } from "../../actions/userAction";
import { logout_Cart } from "../../actions/cartAction";

const ProtectedRoute = ({ isUser, component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { loading, isAuthenticated, user } = useSelector((state) => state.user);
  useEffect(() => {
    if (user && user.role !== "user") {
      dispatch(logout());
      dispatch(logout_Cart());
    }
    console.log(loading, isAuthenticated, user, isUser)
  }, []);


  return (
    <Fragment>
      {(loading === false || loading === undefined) && (
        <Route
          {...rest}
          render={(props) => {
            if (isAuthenticated === false
              || isAuthenticated === undefined) {
              return <Redirect to="/LoginSignUp" />;
            }

            if (isUser === true && user.role !== "user") {
              return <Redirect to="/LoginSignUp" />;
            }

            return <Component {...props} />;
          }}
        />
      )}
    </Fragment>
  );
};

export default ProtectedRoute;

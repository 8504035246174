import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import CustomModal from '../layout/CustomModal'
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

function Customers() {

  const [customers, setCustomers] = useState([]);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  const [expDate, setExpDate] = useState(formattedDate);

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const exportToExcel = () => {
    const table = document.getElementById("all_orders_table");
    const workbook = XLSX.utils.table_to_book(table);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "Customers.xlsx");
  };


  function getCustomers() {
    fetch("/api/admin/customers")
      .then((response) => response.json())
      .then((data) => {

        if (data) {
          setCustomers(data);
        }
      });
  }

  useEffect(() => {
    getCustomers();
  }, []);


  return (
    <>
      <Header></Header>

      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />

      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>All Customers</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">All Customers</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <h5 className="card-title">Customers</h5>
                    <div className="col-md-12 text-right">
                        <button className="blackBtn" onClick={exportToExcel}>
                          <i className="fa-solid fa-file-export"></i> Export
                        </button>
                      </div>
                    <div className="tableOuter adminTables">
                      <table className="phoenix-table fs-9 table"  id="all_orders_table">
                        <thead>
                          <tr>
                            <th className="">#</th>
                            <th className="">Name</th>
                            <th className="">Email Id</th>
                            <th className="">Mobile</th>
                            <th className="">Created Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customers.length > 0 ? customers.map((customer) => {
                            return (
                              <tr>
                                <td>{customer.id}</td>
                                <td>{customer.name} {customer.last_name}</td>
                                <td>{customer.email}</td>
                                <td>{customer.mobile}</td>
                                <td>{customer.created}</td>
                              </tr>
                            )
                          }) : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Customers;

import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CustomModal from "../CustomModal";

function Contact() {
  const [quesEmail, setQuesEmail] = useState("");
  const [quesName, setQuesName] = useState("");
  const [questionText, setQuestionText] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [showPopup2, setShowPopup2] = useState(false);
  const [popupContent2, setPopupContent2] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    if (!questionText) {
      setPopupContent2("Please enter Question.");
      setShowPopup2(true);
    } else if (!quesEmail) {
      setPopupContent2("Please enter Email ID.");
      setShowPopup2(true);
    } else if (!quesName) {
      setPopupContent2("Please enter user name.");
      setShowPopup2(true);
    } else {
      var res = await fetch("/api/admin/category/question", {
        body: JSON.stringify({
          email: quesEmail,
          name: quesName,
          question: questionText,
        }),
        headers: { "Content-Type": "application/json" },
        method: "post",
      });
      if (res.ok) {
        setQuestionText("");
        setQuesName("");
        setQuesEmail("");
        setPopupContent("Question Submitted");
        setShowPopup(true);
      }
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const handlePopupClose2 = () => {
    setShowPopup2(false); // Close the popup
  };

  return (
    <>
      {/* <Header></Header> */}
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <CustomModal
        type="warning"
        content={popupContent2}
        showPopup={showPopup2}
        onClose={handlePopupClose2}
      />
      <div className="contactBG">
        <div className="container bgcust">
          <div className="row justify-content-center">
            <div className="col-md-5" style={{ background: '#fff', border: '1px solid rgb(225 225 229)' }}>
              <div className="contLeftData">
                <h2 className="mt-5">Get In Touch</h2>
                <div className="conCard">
                  <div className="row">
                    <div className="col-md-1">
                      <i className="fa-solid fa-phone-volume"></i>
                    </div>
                    <div className="col-md-11">
                      <h4>Call Us</h4>
                      (India) <a href="tel:+91 987240 7145">+91 987240 7145</a>
                    </div>
                  </div>
                </div>
                <div className="conCard">
                  <div className="row">
                    <div className="col-md-1">
                      <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div className="col-md-11">
                      <h4>Mail Us</h4>
                      <a href="mailto:info@vastrakala.com">info@vastrakala.com</a>
                    </div>
                  </div>
                </div>
                <div className="conCard">
                  <div className="row">
                    <div className="col-md-1">
                      <i className="fa-solid fa-comment-dots"></i>
                    </div>
                    <div className="col-md-11">
                      <h4>Chat With US</h4>
                      <a href="mailto:info@vastrakala.com">info@vastrakala.com</a>
                    </div>
                  </div>
                </div>
                <div className="conCard">
                  <div className="row">
                    <div className="col-md-1">
                      <i className="fa-brands fa-whatsapp"></i>
                    </div>
                    <div className="col-md-11">
                      <h4>Whats App</h4>
                      <a href="tel:+91 987240 7145">+91 987240 7145</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5" style={{ background: '#F0F0F2', padding: '0' }}>
              <div className="ContactFormMain">
                <div className="formTop">
                  <p>For queries, feedback & assistance</p>
                  <h1>Vastrakala Support</h1>
                  <a href="mailto:info@vastrakala.com">info@vastrakala.com</a>
                </div>
                <form onSubmit={handleQuestionSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      {/* <label htmlFor="name">Enter Your Name</label> */}
                      <input type="text" name="name" value={quesName}
                        onInput={(e) => setQuesName(e.target.value)} placeholder="*Full Name" />
                    </div>
                    <div className="col-md-12">
                      {/* <label htmlFor="email">Enter Email Address</label> */}
                      <input type="email" name="email" id="" placeholder="*Email Address"
                        value={quesEmail}
                        onInput={(e) => setQuesEmail(e.target.value)} />
                    </div>
                    {/* <div className="col-md-6">
                        <label htmlFor="email">Enter Phone Number</label> 
                      <input type="number" name="email" id="" placeholder="*Telephone" />
                    </div> */}
                    <div className="col-md-12">
                      {/* <label htmlFor="email">Enter Message</label> */}
                      <textarea name="" cols="30" rows="8" placeholder="Enter Message"
                        value={questionText}
                        onChange={(e) => setQuestionText(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="blackbtn" >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Contact;

import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Footer from "../layout/Footer/Footer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart } from "../../actions/cartAction";
import CustomModal from "../layout/CustomModal";
import Loader from "../layout/Loader/Loader";

const Home = ({ history }) => {
  const dispatch = useDispatch();
  const { error, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const { cartItems, wishlist } = useSelector((state) => state.cart);
  const cart_ids = cartItems.map((obj) => obj.product_id);

  const [instaImage, setInstaImage] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  const base_url = process.env.REACT_APP_BASE_URL;
  const base_url_main = process.env.REACT_APP_BASE_URL_MAIN;
  const homeBannerMobile = {
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    autoplay: true,
    speed: 300,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const homeBanner = {
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    autoplay: true,
    speed: 300,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const category = {
    dots: false,
    infinite: true,
    speed: 300,
    arrows: true,
    autoplay: true,
    // adaptiveHeight: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  const collection = {
    dots: false,
    infinite: true,
    speed: 300,
    arrows: true,
    autoplay: true,
    adaptiveHeight: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  const trending = {
    dots: false,
    // infinite: true,
    speed: 300,
    autoplay: true,
    // adaptiveHeight: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  const reels = {
    dots: false,
    infinite: false,
    speed: 300,
    autoplay: true,
    arrows: true,
    // adaptiveHeight: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  const testimonial = {
    dots: false,
    // infinite: true,
    speed: 300,
    autoplay: true,
    arrows: true,
    centerMode: false,
    adaptiveHeight: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [banners, setBanners] = useState([]);
  const [mobilebanners, setMobileBanners] = useState([]);
  const [trendingVideos, setTrendingVideos] = useState([]);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [testimonials, setTestimonials] = useState([]);
  const [bestsellerCategories, setBestsellerCategories] = useState([]);
  const [budget, setBudget] = useState([]);
  const [marque1, setMarque1] = useState("");
  const [marque2, setMarque2] = useState("");
  const [instaId, setInstaId] = useState("");
  const [instaCount, setInstaCount] = useState("");
  const [homeSection1, setHomeSection1] = useState({});
  const [homeSection2, setHomeSection2] = useState({});
  const [loading, setLoading] = useState(false);

  function getBanners() {
    setLoading(true);
    fetch("/api/banner")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setLoading(false);
          setBanners(data);
          getProducts();
          getCategories();
          getTestimonials();
          getBestSellerCategories();
          getTrendingVideo();
          getInsta();
          getMarque();
          getBudget();
          getHomeSections();
        }
      });
  }

  function getBestSellerCategories() {
    fetch("/api/products/bestseller/categories")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBestsellerCategories(data.products);
        }
      });
  }

  function getTestimonials() {
    fetch("/api/testimonials")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setTestimonials(data);
        }
      });
  }

  function getMarque() {
    fetch("/api/marque")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setMarque1(data[0].value);
          setMarque2(data[1].value);
          setInstaCount(data[2].value);
          setInstaId(data[3].value);
        }
      });
  }

  function getBudget() {
    fetch("/api/budget")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBudget(data);
        }
      });
  }

  function getHomeSections() {
    fetch("/api/home-sections")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setHomeSection1(data[0]);
          setHomeSection2(data[1]);
        }
      });
  }

  function getMobileBanners() {
    setLoading(true);
    fetch("/api/mobile-banner")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setLoading(false);
          setMobileBanners(data);
          getProducts();
          getCategories();
          getTestimonials();
          getBestSellerCategories();
          getTrendingVideo();
          getInsta();
          getMarque();
          getBudget();
          getHomeSections();
        }
      });
  }

  function getTrendingVideo() {
    fetch("/api/trendingvideos/home")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setTrendingVideos(data);
        }
      });
  }

  function getProducts() {
    fetch("/api/products/trending")
      .then((response) => response.json())
      .then((data) => {
        if (data?.products) {
          setProducts(data.products);
        }
      });
  }

  async function addItems(id) {
    dispatch(addItemsToCart(id, 1, isAuthenticated));
    setPopupContent("Product added to cart");
    setShowPopup(true);
    // dispatch(getItems_Cart(isAuthenticated));
    // } else {
    //   history.push("/LoginSignup");
    // }
  }

  function getCategories() {
    fetch("/api/categories")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCategories(data);
        }
      });
  }

  function getInsta() {
    fetch("/api/instaImage/home")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setInstaImage(data);
        }
      });
  }

  const handleClick = (url, id) => {
    history.push(url, { id });
  };

  useEffect(() => {
    setLoading(false);
    if (window.innerWidth >= 760) {
      getBanners();
    } else {
      getMobileBanners();
    }

  }, []);

  async function sendEmail(e) {
    debugger
    e.preventDefault();
    if (mobileNumber.length >= 10) {
      var res = await fetch("/api/enquiry", {
        body: JSON.stringify({
          mobile: mobileNumber,
        }),
        headers: { "Content-Type": "application/json" },
        method: "post",
      });
      if (res.ok) {
        setMobileNumber("");
        setPopupContent("Enquiry Submitted");
        setShowPopup(true);
      }
    }
  }

  const handleSvgClick = (index, i) => {
    // Pause the currently playing video
    if (playingIndex !== null) {
      const currentlyPlayingVideo = document.getElementById(
        `video-${playingIndex}`
      );
      if (currentlyPlayingVideo) {
        currentlyPlayingVideo.pause();
      }
    }

    if (index === playingIndex) {
      setPlayingIndex(null);
    } else {
      if (i === 1) {
        setPlayingIndex(index);
        const videoElement = document.getElementById(`video-${index}`);
        if (videoElement) {
          videoElement
            .play()
            .catch((error) => console.error("Autoplay failed:", error));
        }
      }
    }
  };

  const videoRefs = useRef([]);

  const handleMouseEnter = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
    setPlayingIndex(index);
  };

  const handleMouseLeave = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].pause();
    }
    setPlayingIndex(null);
  };

  return (
    <>
      {/* <Header></Header> */}
      {loading ? <Loader /> : <></>}
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />

      <div className="homeSliderMobile">
        <Slider {...homeBannerMobile}>
          {mobilebanners.map((banner, index) => {
            return (
              <div key={index}>
                <a href={banner.url} className="BannerLink">
                  <img
                    src={banner.name ? base_url_main + banner.name : "No-Image.png"}
                    width="100%"
                    loading="eager"
                  />
                </a>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="homeSlider">
        <Slider {...homeBanner}>
          {banners.map((banner, index) => {
            return (
              <div key={index}>
                <a href={banner.url} className="BannerLink">
                  <img
                    src={banner.name ? base_url_main + banner.name : "No-Image.png"}
                    width="100%"
                    loading="eager"
                  />
                </a>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="categorySection">
        <div className="row align-items-center">
          <div className="col-12 col-lg-3">
            <div className="catData">
              <h2 className="title">Something New From Fashion</h2>
              <p>
                We believe in making clothes that celebrate the people who wear
                them.
              </p>
              {/* <a className="outlinedBtn">See All</a> */}
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="categorySlider p-both-50">
              <div className="row">
                <Slider {...category}>
                  {categories.map((category, index) => {
                    return (
                      <div key={index}>
                        <Link
                          className="CatLink"
                          to={{
                            pathname:
                              "/category/" + category.name + "/" + category.id,
                            state: {
                              id: category.id,
                              name: category.name,
                              page: 1,
                            },
                          }}
                        >
                          <div className="categoryCard">
                            <div className="catIamge">
                              <img
                                alt=""
                                src={
                                  category.image
                                    ? base_url + category.image
                                    : "No-Image.png"
                                }
                                className="categoryImage"
                                loading="lazy"
                              />
                              <button className="whiteBtn">Discover</button>
                            </div>
                            <h2 className="catName">{category.name}</h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: category.description,
                              }}
                            />
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sale_Slider">
        <marquee>
          <p>{marque2}</p>
        </marquee>
      </div>
      <div className="collection p-both-50">
        <div className="container">
          <div className="row">
            <div className="secTitles">
              <h2 className="title">Shop Under Budget</h2>
            </div>
          </div>
          <div className="row">
            <Slider {...collection}>
              {budget?.map((category, index) => {
                return (
                  <div key={index}>
                    <div className="singleCollection">
                      <Link
                        to={`/category/${category.category}?maxPrice=${category.max_price}`}
                      >
                        <div className="singleCollection">
                          <img
                            alt=""
                            src={
                              process.env.REACT_APP_BASE_URL + category.image
                            }
                            width="100%"
                            loading="lazy"
                          />
                          <div class="overlay"></div>
                          <div className="collectionData">
                            <h2>{category.name}</h2>
                            <p>Under {category.max_price}</p>
                            <Link
                              to={`/category/${category.category}?maxPrice=${category.max_price}`}
                              className="whiteBtn"
                            >
                              {category.button_text}
                            </Link>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="callToaction p-both-50">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                src={
                  homeSection1?.image
                    ? process.env.REACT_APP_BASE_URL + homeSection1?.image
                    : ""
                }
                alt="Home Section Image"
                width="100%"
                loading="lazy"
              />
            </div>
            <div className="col-md-6">
              <div className="greyBox">
                <h2 className="title">{homeSection1?.heading}</h2>
                <p>{homeSection1?.sub_heading}</p>
                <div className="startCall mt-3">
                  <form action="">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-8">
                        <input
                          className="form-control"
                          type="number"
                          value={mobileNumber}
                          onInput={(e) => setMobileNumber(e.target.value)}
                          placeholder="Enter Your Phone Number"
                        />
                      </div>
                      <div className="col-12 col-lg-4">
                        <button className="blackbtn" style={{ width: '100%' }} onClick={sendEmail}>Start Call Now</button>
                      </div>
                    </div>
                  </form>
                  <div className="afterForm" style={{ display: 'none' }}>
                    <h3>Thank You For Contacting Us</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="trending p-both-50">
        <div className="container">
          <div className="row">
            <div className="secTitles">
              <h2 className="title">Trending Products</h2>
              <p>Everyone loves coming back to these categories</p>
            </div>
          </div>
          <div className="row">
            <Slider {...trending}>
              {products.map((product, index) => {
                let url = `/singleproduct/${product.name}/p/${product.product_id}`;
                return (
                  <div key={index}>
                    <div className="singleTrending">
                      <img
                        src={
                          product.image
                            ? process.env.REACT_APP_BASE_URL + product.image
                            : "No-Image.png"
                        }
                        width="100%"
                        loading="lazy"
                      />
                      <div
                        onClick={() => handleClick(url, product.id)}
                        className="overlay"
                      ></div>
                      <div className="productDetails">
                        <h2 onClick={() => handleClick(url, product.id)}>
                          {product.name}
                          {/* ({product.color}) */}
                        </h2>
                        <p
                          className="price"
                          onClick={() => handleClick(url, product.id)}
                        >
                          {product.regular_price !== product.selling_price ? (
                            <span className="slashed">
                              Rs. {product.regular_price}
                            </span>
                          ) : (
                            ""
                          )}{" "}
                          Rs. {product.selling_price}
                        </p>
                        {!cart_ids.includes(product.product_id.toString()) ? (
                          <a
                            onClick={(e) => addItems(product.product_id)}
                            className="whiteBtn"
                          >
                            Add To Cart
                          </a>
                        ) : (
                          <Link to="../../cart" className="whiteBtn">
                            View cart
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="callToaction2 m-both-50">
        <img
          className="homeBan2"
          src={
            homeSection2?.image
              ? process.env.REACT_APP_BASE_URL + homeSection2?.image
              : ""
          }
          width="100%"
          alt="Home Section Image"
          loading="lazy"
        />
        <div className="container">
          <div className="row">
            <div className="call2Data">
              <h2>{homeSection2?.heading}</h2>
              <h3>{homeSection2?.sub_heading}</h3>
              <p>{homeSection2?.sub_text}</p>
              <Link
                to={`/category/${homeSection2?.category}`}
                className="whiteBtn"
              >
                {homeSection2.button_text}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="trendingLooks p-both-50">
        <div className="row">
          <div className="secTitles">
            <h2 className="title">Trending Looks To Watch</h2>
          </div>
        </div>
        <div className="row">
          <div className="realsContainer">
            <Slider {...reels}>
              {trendingVideos &&
                trendingVideos.map((video, index) => (
                  <div key={index}>
                    <div className="singleReal">
                      <video
                        loop
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                        ref={(el) => (videoRefs.current[index] = el)} // Assign ref
                        webkit-playsinline="true"
                        playsInline
                      >
                        <source
                          src={`${process.env.REACT_APP_BASE_URL}${video.name}`}
                          type="video/mp4"
                        />
                      </video>
                      <svg
                        onClick={() => handleMouseEnter(index)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="69"
                        height="69"
                        viewBox="0 0 69 69"
                        fill="none"
                      >
                        <circle cx="34.5" cy="34.5" r="34.5" fill="white" />
                        <path
                          d="M46 34.5L28.75 44.4593L28.75 24.5407L46 34.5Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="collection p-both-50">
        <div className="container">
          <div className="row">
            <div className="secTitles">
              <h2 className="title">Popular Picks</h2>
            </div>
          </div>
          <div className="row">
            <Slider {...collection}>
              {bestsellerCategories.length > 0 ? (
                bestsellerCategories.map((category) => {
                  return (
                    <div>
                      <div className="singleCollection">
                        <Link
                          to={{
                            pathname:
                              "/category/" + category.name + "/" + category.id,
                            state: {
                              id: category.id,
                              name: category.name,
                              page: 1,
                            },
                          }}
                        >
                          <img
                            alt=""
                            src={
                              category.image
                                ? base_url + category.image
                                : "No-Image.png"
                            }
                            width="100%"
                            loading="lazy"
                          />
                          <div class="overlay"></div>
                          <div className="collectionData">
                            <h2>{category.name}</h2>
                            <a className="whiteBtn">Shop Now</a>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>Popular Picks not found</div>
              )}
            </Slider>
          </div>
        </div>
      </div>
      <div className="ourTestimonial p-both-50">
        <div className="container">
          <div className="row">
            <div className="secTitles">
              <h2 className="title">What Our Clients Say</h2>
            </div>
          </div>
          <div className="row">
            <Slider {...testimonial}>
              {testimonials &&
                testimonials.map((testimonial, index) => {
                  return (
                    <div key={index}>
                      <div className="singleTestimonial">
                        <div className="row align-items-center">
                          <div className="col-md-4">
                            <img
                              src={
                                testimonial.image
                                  ? base_url + testimonial.image
                                  : "No-Image.png"
                              }
                              width="100%"
                              loading="lazy"
                            />
                          </div>
                          <div className="col-md-8">
                            <div className="testiData">
                              <p>{testimonial.description}</p>
                              <h2 className="cName">{testimonial.name}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="instagram p-both-50">
        <div className="container">
          <div className="greyBox">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="secTitles">
                  <h2 className="title">Follow us on Instagram</h2>
                  <p>
                    Experience the best of our brand on Instagram, follow us for
                    behind-the-scenes access.
                  </p>
                  <div className="followInsta">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="57"
                      height="57"
                      viewBox="0 0 57 57"
                      fill="none"
                    >
                      <circle
                        cx="28.5"
                        cy="28.5"
                        r="28.5"
                        fill="url(#paint0_linear_176_225)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_176_225"
                          x1="28.5"
                          y1="0"
                          x2="28.5"
                          y2="57"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#36126A" />
                          <stop offset="0.526042" stop-color="#F81362" />
                          <stop offset="1" stop-color="#FF914A" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <div className="intsaName">
                      <h2>@{instaId}</h2>
                      <p>{instaCount} Followers</p>
                    </div>
                    <a
                      href={`https://www.instagram.com/${instaId}/?hl=en`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button>Follow @{instaId}</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="instapicsGrid">
                  {instaImage.map((image, index) => {
                    return (
                      <img
                        key={index}
                        src={
                          image.name
                            ? process.env.REACT_APP_BASE_URL + image.name
                            : "No-Image.png"
                        }
                        width="100%"
                        loading="lazy"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Home;

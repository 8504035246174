import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import CustomModal from "../layout/CustomModal";

function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [file, setFile] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [popupType, setPopupType] = useState("success");

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  function getTestimonials() {
    fetch("/api/admin/testimonials")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setTestimonials(data);
        }
      });
  }

  async function addTestimonials() {
    if (name !== "" && desc !== "" && file) {
      var formData = new FormData();
      formData.append("image", file);
      formData.append("name", name);
      formData.append("desc", desc);
      var res = await fetch("/api/admin/testimonials", {
        body: formData,
        method: "post",
      });
      if (res.ok) {
        setDesc("");
        setFile("");
        setName("");
        getTestimonials();
        setPopupType("success");
        setPopupContent("Testimonial Added Successfully");
        setShowPopup(true);

        console.log("Testimonial Added");
      }
    } else {
      setPopupType("warning");
      setPopupContent("Please fill all values");
      setShowPopup(true);
    }
  }

  useEffect(() => {
    getTestimonials();
  }, []);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  async function deleteTestimonial(id) {
    const requestOptions = {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    };
    var res = await fetch("/api/admin/testimonials/" + id, requestOptions);
    var data = await res.json();
    getTestimonials();
    if (data.length > 0) {
      setPopupContent("Testimonial Deleted Successfully");
      setShowPopup(true);
    }
  }

  return (
    <>
      <Header></Header>

      <CustomModal
        type={popupType}
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />

      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>Testimonials</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Add Testimonials</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Add Testimonials</h5>
                  <div className="testiForm">
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Author Name"
                          required
                          value={name}
                          onInput={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="5"
                          placeholder="Message"
                          className="form-control"
                          required
                          value={desc}
                          onInput={(e) => setDesc(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="mt-3" style={{ display: "block" }}>Upload Client Picture</label>
                      <div
                        role="presentation"
                        tabindex="0"
                        className="mb-3 dropzone"
                      >
                        <input
                          required
                          accept="image/*,.png,.gif,.jpeg,.jpg"
                          multiple=""
                          type="file"
                          tabindex="-1"
                          style={{ display: "none" }}
                          onChange={handleChange}
                        />
                        <div className="text-600 fw-bold fs-9">
                          {" "}
                          Drag your photo here{" "}
                          <span className="text-800">or </span>
                          <button type="button" className="p-0 btn btn-link">
                            {" "}
                            Browse from device
                          </button>
                          <br />
                          <img
                            className="mt-3"
                            src={
                              typeof file !== "string"
                                ? URL.createObjectURL(file)
                                : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAqCAMAAAAd31JXAAAARVBMVEUAAABueJFueJFueZFteJBweI9seJFtepJweI9ueJFwcI9ueJFueZFteZJvd5Bud5FueJFue5FuepFueZBueJBtd5BueJFT8Zn8AAAAFnRSTlMA339fn2B/H0C/EO9vP49Pr08v78+Pw4dpogAAARZJREFUOMvF1NluwyAQheFhiQl4jdPO+z9qQdgcSmjHyk3+C8uWPuGRF0jOeb/SxRTzGC5ajrm37DzYl0yeNZ4lu1k7p+tgudeUZz06Zv7ibjYty1Ur0cD9TLOucvlyXHTbPc87aZ3goqdIZ/GRADzS8nTN3kTrC5CtW6yTLfq4DaG1YdZ67dGB+fu3XZ8cU+bVjs3zxVcxdN/FHRY0YsFm6n2FZ+v94lp7pon0iZ3HTWoLWrBTmKi2oMDPavzaggKn1E1nXFnQA+OXMZyDBQXOrwoYVgECD8CNFXBtJVxbAXdsH4sWWLbAsgXOtuw7o/sHb+mwE53bpppML+y2oWxBYpZy+yhSVWZ8KInuVHLGq7/bpnC4H8tmOEloDtVDAAAAAElFTkSuQmCC"
                            }
                            width="40"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="testiForm mt-3 text-right">
                        <button onClick={addTestimonials} className="blackBtn">
                          {" "}
                          Add Testimonial
                        </button>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <h5 className="card-title">Testimonials Table</h5>
                    <div className="tableOuter adminTables">
                      <table className="phoenix-table fs-9 table">
                        <thead>
                          <tr>
                            <th className="">Name</th>
                            <th className="">Message</th>
                            <th className="">Image</th>
                            <th className="">Created on</th>
                            <th className="">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {testimonials.map((testimonail) => {
                            return (
                              <tr>
                                <td>{testimonail.name}</td>
                                <td>{testimonail.description}</td>
                                <td>
                                  <img
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      testimonail.image
                                    }
                                    className="CategoryImage"
                                  />
                                </td>
                                <td>{testimonail.created}</td>
                                <td style={{ minWidth: "180px" }}>
                                  <button
                                    className="blackBtn"
                                    onClick={(e) => {
                                      deleteTestimonial(testimonail.id);
                                    }}
                                  >
                                    Delete Testimonial
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Testimonials;

import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
function Users() {

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [role, setRole] = useState("Editor");
  const [allUsers, setAllUsers] = useState([]);

  function getAllUsers() {
    fetch("/api/admin/users")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setAllUsers(data);
        }
      });
  }

  async function deleteUser(id) {
    const requestOptions = {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    };
    var res = await fetch("/api/admin/users/" + id, requestOptions);
    var data = await res.json();
    getAllUsers();
    if (data.length > 0) {
      console.log("User deleted");
    }
  }

  async function addUser() {
    if (name !== "" && lastName !== "" && email && pass !== "") {
      var res = await fetch("/api/admin/users", {
        body: JSON.stringify({
          email: email,
          password: Buffer.from(pass).toString("base64"),
          first_name: name,
          last_name: lastName,
          role: role,
        }),
        headers: { "Content-Type": "application/json" },
        method: "post",
      });
      if (res.ok) {
        setName("")
        setLastName("")
        setEmail("")
        setPass("")
        getAllUsers();
        alert("User Added");
      }
    } else {
      alert("Please fill all values")
    }
  }

  useEffect(() => {
    getAllUsers()
  }, []);

  return (
    <>
      <Header></Header>

      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>All Users</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">All Users</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Add User</h5>
                  <div className="AdminUserForm">
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Enter User Email ID"
                          value={email}
                          onInput={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter User Name"
                          value={name}
                          onInput={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Last Name"
                          value={lastName}
                          onInput={(e) => setLastName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter User Login Password"
                          value={pass}
                          onInput={(e) => setPass(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12">
                        <select name="" id="" className="form-select" value={role}
                          onChange={(e) => setRole(e.target.value)}>
                          <option selected="true" disabled="disabled">Assign Role</option>
                          <option value="Editor">Editor</option>
                          <option value="Employee">Employee</option>
                        </select>
                      </div>
                      <div className="col-md-12 text-right">
                        <button className="blackBtn" onClick={addUser}>Add User</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <h5 className="card-title">User Details Table</h5>
                    <div className="tableOuter adminTables">
                      <table className="phoenix-table fs-9 table">
                        <thead>
                          <tr>
                            <th className="">First Name</th>
                            <th className="">Last Name</th>
                            <th className="">Email</th>
                            <th className="">Role</th>
                            <th className="">Created on</th>
                            <th className="">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allUsers.map((user) => {
                            console.log(user)
                            return (
                              <tr>
                                <td>{user.first_name}</td>
                                <td>{user.last_name}</td>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>{user.created}</td>
                                <td><button className="blackBtn" onClick={(e) => { deleteUser(user.email) }}>Delete User</button></td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Users;

import React, { useState, useEffect } from "react";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What if I want to exchange or return my order?",
      answer: "Exchange and returns are available for products within 6 days of delivery. Items must be in original condition with all tags intact."
    },
    {
      question: "How can I see what information you hold on me?",
      answer: "You can write to us on info@vastrakala.com."
    },
    {
      question: "How do I opt out of marketing?",
      answer: "You can opt out of email marketing by clicking the unsubscribe link at the bottom of our emails. For any other marketing communication, please write to us on info@vastrakala.com."
    },
    {
      question: "Can I ask you to delete my information?",
      answer: "Yes, you can write to us on info@vastrakala.com."
    },
    {
      question: "How do I update my information?",
      answer: "You can update it at any time via your online account at Vastrakala.com."
    },
    {
      question: "Do I need to register in order to place an order?",
      answer: "We recommend that all our customers register with us to get the best service and offers available. However, you can also checkout as a guest or log in with Facebook or Instagram Account."
    },
    {
      question: "How do I register?",
      answer: "You can register by simply setting up an email address and a password. Sign in to view what is already in your shopping cart. You can also opt to sign in using Facebook, Google, or Instagram Account."
    },
    {
      question: "I forgot my password. How do I change it?",
      answer: "If you have forgotten your password, simply click on ‘Forgot password’ and you will be redirected to an email where you can reset your password."
    },
    {
      question: "How can I change my personal details or shipping address?",
      answer: "Log on to our website and click on ‘My Account’ to change your personal details or shipping address."
    },
    {
      question: "How do I purchase an item or several items at VastraKala?",
      answer: "Online shopping is quite an easy procedure. Start by choosing your items, add them to your shopping bag, and proceed to checkout. An email and text message will be sent to your email address and phone number to verify that you have purchased a product from VastraKala."
    },
    {
      question: "What size should I order?",
      answer: "The size conversion chart that is available on each product page will solve your sizing queries. All product sizes are mentioned as per the international sizing chart used by designers. However, sizes may vary across designers, and we can further help by providing you the exact product dimensions so that you can compare it with your own. If you have detailed questions about the sizing of a particular product, you can contact us at info@vastrakala.com."
    },
    {
      question: "Does VastraKala offer alterations?",
      answer: "We do not offer alteration as a service as of now. However, for specific cases, you can reach out to our customer care team and they would help you with the next steps."
    },
    {
      question: "I liked an outfit at a VastraKala store but it is not available online. How do I purchase it?",
      answer: "Please send an email regarding the garment that you are keen on purchasing at Info@vastrakala.com and we will respond within 48 hours."
    },
    {
      question: "Are the colors shown on the website accurate to the garments?",
      answer: "We strive to display the colors of the garment as accurately as possible on our website. However, we cannot guarantee that the colors of the garment will match the colors viewed on an electronic device. Sight variations may occur in hand-embroidered items."
    },
    {
      question: "Can I place an order for an item that is out of stock?",
      answer: "For a sold-out item, select the size of your choice and send us a request with your details and we will get back to you once the product is available."
    },
    {
      question: "How do I know if my order has been placed?",
      answer: "Once you have placed an order, you will receive an email confirmation and a text message stating that your order has been placed successfully."
    },
    {
      question: "How do I track my order?",
      answer: "Once you have placed an order, we will send you a tracking number /AWB through an email. You will be able to view the date your item is in process to the date of shipment to the date of your item being in the process of delivery."
    },
    {
      question: "Which items can be returned?",
      answer: "VastraKala has a simple and hassle-free return and/or exchange policy for certain products purchased from the Website. Apparel (men/women/kids) are eligible for return within 6 days from the date of delivery for orders from India and the Rest of the world. However, customized styles are non-returnable/non-exchangeable. Jewellery and accessories are non-returnable/non-exchangeable. Products on warehouse sale are non-returnable/non-exchangeable. For more details, click here (https://www.vastrakala.com/returns&exchanges). In case of any return/exchange/alteration request for international orders, a restocking or return handling fee of $50 or 20% of the product-selling value, whichever is lower, will be applicable. This will be deducted from the amount that is refunded to the wallet/original source of payment when executing the refund."
    },
    {
      question: "I haven’t received all items that I have purchased. When will it be delivered?",
      answer: "If you have purchased multiple items, there is a fair chance that the items will get dispatched on separate days. View ‘Track my orders’ to know the status of each of your items."
    },
    {
      question: "How long will it take to receive my order?",
      answer: "An estimated delivery time for each product is mentioned on every product page. Please check the delivery time period while you proceed to checkout, as each product varies. We are not responsible for delays caused by destination custom clearances. However, once you have placed your order, we can give you information on the tracking of your items by simply clicking on ‘Track my orders’ on the ‘My Account’ page. You will be able to view the date your item is in process to the date of shipment to the date of your item being in the process of delivery."
    },
    {
      question: "Do I need to sign for my order upon delivery?",
      answer: "Due to the value of the goods we sell, we request that you place your signature against your order once the items have been delivered successfully to your address."
    },
    {
      question: "What are the payment methods you accept?",
      answer: "We accept PayPal, Stripe, Razorpay, Visa, MasterCard, American Express, and Maestro credit and debit cards. Net Banking are other payment options."
    },
    {
      question: "When will I be charged?",
      answer: "If you pay by credit or debit cards, you will be charged immediately after your order is placed successfully."
    },
    {
      question: "Is it safe to use my credit card?",
      answer: "VastraKala accepts credit card payments through a secure online server, as we realize the importance of the security of your details."
    },
    {
      question: "What additional duties and taxes will I have to pay?",
      answer: "All product prices for customers in India are inclusive of all taxes and duties. For customers overseas, all product prices (on a Delivery Duty Unpaid basis) displayed are exclusive of all import duties."
    },
    {
      question: "Is my packaging insured?",
      answer: "All items are insured against accidental damage and theft while in transit from our store to the shipping address. However, once your items have been delivered to your address, they are no longer covered under insurance. Upon arrival, if your box is damaged, we recommend that you either refuse the delivery or make a note while signing for it that you are accepting a damaged box."
    },
    {
      question: "Can I return my outfit to a VastraKala store instead of shipping it back?",
      answer: "Yes, you can. Please send an email at Info@vastrakala.com that you would prefer returning an item to VastraKala. Please ensure that your item is returned along with the price tag and its original packaging. Our store manager will attend to you and will be notified about your arrival prior to the date of return. You are eligible for returns or exchange only once the item has been checked and verified."
    },
    {
      question: "I have returned an item. When will I see the credit in my refund?",
      answer: "Once you have returned an item, VastraKala will run through an inspection of the garment. If there has been any kind of damage caused or if it is soiled, we will not be able to accept the return. However, if the garment is devoid of any damages, we will provide you with the amount paid for the garment in the form of store credit."
    },
    {
      question: "My item has arrived damaged or incorrect. What do I do?",
      answer: "If we have made a mistake from our end by sending you an incorrect item, garment size, or damaged item, please send us an email at info@vastrakala.com with a photograph of the garment. We will return it to you with the correct item/garment size."
    },
    {
      question: "Does VastraKala have seasonal sales?",
      answer: "Yes, VastraKala has seasonal sales. Sign up for our newsletter to stay up to date with sale alerts."
    },
    {
      question: "Do you ship to my country? Which currency can I buy in?",
      answer: "We ship to 200 countries in the world and accept only Indian Rupees and US dollar currencies. (Note - For customers outside India, only US dollar currencies are accepted by default.)"
    },
    {
      question: "Still have a query?",
      answer: "Didn’t find an answer to a query that you were looking for? Kindly email info@vastrakala.com and we will get back to you within the next 48 hours. You can also contact us at customer care on 919872407145 from Monday-Friday (10am-10pm IST)."
    }
  ];


  return (
    <>
      {/* <Header></Header> */}
      <div className="container">
        <div className="row">
          <div className="policyPages p-both-50">
            <h2>Faqs (Frequently Asked Questions)</h2>
            <div className="accordion">
              {faqs.map((faq, index) => (
                <div key={index} className="accordion-item">
                  <h3 className="accordion-header" onClick={() => toggleAccordion(index)}>
                    {faq.question}
                    <span className="accordion-icon">
                      {activeIndex === index ? (
                        <i className="fas fa-minus"></i>
                      ) : (
                        <i className="fas fa-plus"></i>
                      )}
                    </span>
                  </h3>
                  <div className={`accordion-content ${activeIndex === index ? 'open' : ''}`}>
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FAQ;

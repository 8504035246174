import React from "react";
function confirmPopup() {
  return (
    <>
      <div className="confirm">
        <div className="container">
          <div className="row">
            <i className="fa-solid fa-xmark p_a"></i>

            <button>yes</button>
            <button>No</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default confirmPopup;

import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {/* <Header></Header> */}
      <div className="container">
        <div className="row">
          <div className="policyPages p-both-50">
            <h2>About Us</h2>
            <p>
              Welcome to Vastra Kala,Phagwara started in 2012 on social media
              (but working since 1971) in a world of Customization of ethnic
              wear fashion as per the occasions for Women, Men & Kids, where
              traditions are fused with modern aesthetics and infused with an
              ultra-modern spirit as per your requirement. We are trying to make
              Indian fashion approachable and affordable to all by eliminating
              the meticulous hopping between shops and painstaking efforts.
            </p>

            <p>
              With the platform, we provide you with gorgeous and trendy
              readymade or custom-made Indian fashion, reachable to you in just
              a matter of a few clicks. Indian wedding wear, ethnic wear like
              Salwar Suits, Palazzo Suits, Sharara Suits, Anarkalis, Lehengas
              etc, indo western Indian ethnic tunics,skirts,bottoms, Indian
              ethnic dresses, festive wear and co-ords set for Wo and Sherwanis
              , Kurta Pajamas, Indo western & other ethnic wear for men is sure
              to serve you a huge range of purposes and occasions. Our talented
              team is dedicated to maintaining exclusivity and offering you a
              range that covers every occasion from festival celebrations and
              weddings & to other parties.
            </p>

            <p>
              Go ahead! Pick your favorite ethnic wear clothing, add it to your
              cart, and experience a seamless checkout at www.vastrakala.com.
            </p>

            <p>Happy Shopping!!!</p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default About;

import React, { Fragment, useRef, useState, useEffect } from "react";
import "./LoginSignUp.css";
import Loader from "../layout/Loader/Loader";
import { Link } from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FaceIcon from "@material-ui/icons/Face";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PhoneIcon from "@material-ui/icons/Phone";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  login,
  register,
  Register_login, googleLogin
} from "../../actions/userAction";
import { useAlert } from "react-alert";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import CryptoJS from "crypto-js";
import Logo from "../../../src/images/VastrakalaLogoBlack.svg";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'
import CustomModal from '../layout/CustomModal'

const LoginSignUp = ({ history, location }) => {
  const secret = process.env.REACT_APP_SECRET;
  const dispatch = useDispatch();

  const alert = useAlert();
  const { error, loading, isAuthenticated, token } = useSelector(
    (state) => state.user
  );

  const loginTab = useRef(null);
  const registerTab = useRef(null);
  const registerForm = useRef(null);
  const otpTab = useRef(null);
  const switcherTab = useRef(null);
  const registerBtn = useRef(null);

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [signupError, setSignupError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    otp: "",
  });

  const { name, email, password, mobile, otp } = user;

  const [avatar, setAvatar] = useState("/Profile.png");
  const [avatarPreview, setAvatarPreview] = useState("/Profile.png");

  const loginSubmit = async (e) => {
    e.preventDefault();
    ;
    let cart_items = JSON.parse(localStorage.getItem("cartItems"));
    var idAndQuantityList = [];
    if (cart_items && cart_items.length > 0) {
      idAndQuantityList = cart_items.map(function (dictionary) {
        return { id: dictionary.id, quantity: dictionary.quantity };
      });
    }
    let value = await dispatch(
      login(
        Buffer.from(loginEmail).toString("base64"),
        Buffer.from(loginPassword).toString("base64"),
        idAndQuantityList
      )
    );
    if (value === 'success') {
      setPopupContent("Login Successful")
      setShowPopup(true)
    } else {
      setLoginError(value);
    }
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     "email": loginEmail,
    //     "password": loginPassword
    //   })
    // };
    // var res = await fetch('/api/login', requestOptions)
    // if (res.ok) {
    //   var data = await res.json();
    //   dispatch({ type: login, payload: data });
    //   alert.success("Login Success")
    // } else {
    //   let error = await res.json()
    //   alert.error(error.error)
    // }
  };

  const verfiyOTP = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        otp: otp,
        email: email,
      }),
    };
    var res = await fetch("/api/register/verify", requestOptions);
    if (res.ok) {
      setOtpError(true);
      var data = await res.json();
      dispatch(Register_login(data));
      switchTabs(e, "login");
      setPopupContent("OTP Verified")
      setShowPopup(true)
    }
    else {
      let error = await res.json();
      setOtpError(true);
    }
  };

  const registerSubmit = async (e) => {
    e.preventDefault();
    ;
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(password)) {
      // "password": CryptoJS.AES.encrypt(password, secret).toString(),
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: Buffer.from(password).toString("base64"),
          name: name,
          mobile: mobile,
        }),
      };
      var res = await fetch("/api/register", requestOptions);
      if (res.ok) {
        setSignupError(false);
        var data = await res.json();
        switchTabs(e, "otp");
        setPopupContent("OTP Sent")
        setShowPopup(true)
      }
      else if (res.status === 408) {
        let error = await res.text();
        setSignupErrorMessage("This email address is already registered. Please try some other email address or login with your existing login details.");
        setSignupError(true);
      }
      else {
        let error = await res.text();
        // alert.error(error);
        setSignupErrorMessage(`Minimum eight characters, at least one uppercase letter,
                      one lowercase letter, one number and one special character`)
        setSignupError(true);
      }
    } else {
      setSignupError(true);
    }
  };

  const registerDataChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      if (e.target.name === "otp") {
        setUser({ ...user, [e.target.name]: e.target.value.replace(/\D/g, '') });
      } else {
        setUser({ ...user, [e.target.name]: e.target.value.replace(/\s/g, '') });
      }

    }
  };

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isAuthenticated && token) {
      history.push(redirect);
    }
  }, [dispatch, error, alert, history, isAuthenticated, redirect]);

  useEffect(() => {
    otpTab.current.style.display = "none";
    registerTab.current.style.display = "none";
    loginTab.current.style.display = "flex";
  }, []);

  const switchTabs = (e, tab) => {
    if (tab === "login") {
      otpTab.current.style.display = "none";
      registerTab.current.style.display = "none";
      loginTab.current.style.display = "flex";
    }
    if (tab === "register") {
      registerTab.current.style.display = "block";
      registerForm.current.style.display = "block";
      loginTab.current.style.display = "none";
      otpTab.current.style.display = "none";
      registerBtn.current.style.display = "block";
    }
    if (tab === "otp") {
      otpTab.current.style.display = "block";
      registerForm.current.style.display = "none";
      loginTab.current.style.display = " none";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      let cart_items = JSON.parse(localStorage.getItem("cartItems"));
      var idAndQuantityList = [];
      if (cart_items && cart_items.length > 0) {
        idAndQuantityList = cart_items.map(function (dictionary) {
          return { id: dictionary.id, quantity: dictionary.quantity };
        });
      }
      let value = await dispatch(
        googleLogin(
          tokenResponse.access_token, idAndQuantityList
        )
      );
    },
    onError: tokenResponse => console.log(tokenResponse),
  });

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };


  return (
    <>
      {/* <Header></Header> */}
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <div className="newLoginSignup">
        <div className="loginLeft">
          <Link to="../../../../">
            <img src={Logo} />
          </Link>
          <h2 className="title">
            Register & Be A Part Of The Vastrakala Circle!
          </h2>
          <br />
          <p>Enjoy exclusive benefits like:</p>
          <ul>
            <li>
              Flat 10% Off on your first order* | Use code: <b>VASTRAKAL10</b>
            </li>
            <li>- Exclusive early collection showcase</li>
            <li>- Access amazing offers, discounts and more</li>
          </ul>
          <br />
          <p>Join Now!</p>
          <br />
          <p>OR</p>
          <br />
          <Link to="../../../../" className="blackbtn">
            Go Back To Homepage
          </Link>
        </div>
        <div className="loginRight">
          <div className="loginFormNew" ref={loginTab}>
            <div className="logHeader">
              <h2>Sign In</h2>
              <p>
                Sign in so you can save items to your wishlists, track your
                orders , and checkout faster!
              </p>
            </div>
            <form className="loginForm" onSubmit={loginSubmit}>
              <div className="loginEmail">
                {/* <MailOutlineIcon /> */}
                <input
                  type="email"
                  placeholder="Email"
                  required
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
              </div>
              <div className="loginPassword">
                {/* <LockOpenIcon /> */}
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
                <i className={`fa-solid fa-eye${showPassword ? "-slash" : ""}`} onClick={(e) => setShowPassword(!showPassword)}></i>
              </div>
              {loginError && (
                <span class="error-message" id="error_login">
                  {loginError}
                </span>
              )}
              <Link to="/ForgotPassword">Forget Password ?</Link>
              <input type="submit" value="Sign In" className="loginBtn" />
            </form>
            {/* <div>
              <hr />
            </div> */}
            <div className="createAccount_n">
              <div className="socialSignup">
                <i class="fa-brands fa-google" onClick={() => loginGoogle()}></i>
              </div>
              <hr />
              <h3>Sign Up</h3>
              <br />
              <button
                className="outlinedBtn"
                onClick={(e) => switchTabs(e, "register")}
              >
                Create An Account
              </button>
            </div>
          </div>
          <div className="registerFormNew" ref={registerTab}>
            <div className="logHeader">
              <h2>Sign Up</h2>
              <p>
                Welcome to Vastrakala! It's quick and easy to set up an account
              </p>
            </div>
            <form
              ref={registerForm}
              className="signUpForm"
              encType="multipart/form-data"
              onSubmit={registerSubmit}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="signUpName">
                    {/* <PermIdentityIcon /> */}
                    <input
                      type="text"
                      placeholder="First Name"
                      required
                      name="name"
                      value={name}
                      onChange={registerDataChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="signUpEmail">
                    {/* <PermIdentityIcon /> */}
                    <input
                      type="text"
                      placeholder="Last Name"
                      required
                      name="mobile"
                      value={mobile}
                      onChange={registerDataChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="signUpEmail">
                    {/* <MailOutlineIcon /> */}
                    <input
                      type="email"
                      placeholder="Email Address"
                      required
                      name="email"
                      value={email}
                      onChange={registerDataChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="signUpPassword">
                    {/* <LockOpenIcon /> */}
                    <input
                      type={showPassword2 ? "text" : "password"}
                      placeholder="Password"
                      required
                      name="password"
                      value={password}
                      onChange={registerDataChange}
                    />
                    <i className={`fa-solid fa-eye${showPassword ? "-slash" : ""}`} onClick={(e) => setShowPassword2(!showPassword2)}></i>
                  </div>
                </div>
                <div className="col-md-12">
                  {signupError && (
                    <span class="error-message" id="error_signup">
                      {signupErrorMessage}
                    </span>
                  )}

                  <input
                    type="submit"
                    value="Register"
                    className="signUpBtn"
                    ref={registerBtn}
                  />
                </div>
                <div className="col-md-12">
                  <p className="byCon">
                    By continuing, I agree to the{" "}
                    <Link to="/TermsOfService" target="_blank">Terms of Use</Link> and{" "}
                    <Link to="/PrivacyPolicy" target="_blank">Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </form>
            <form
              className="signUpForm OTPForm"
              ref={otpTab}
              encType="multipart/form-data"
              onSubmit={verfiyOTP}
            >
              <div className="signUpPassword">
                {/* <LockOpenIcon /> */}
                <input
                  type="text"
                  placeholder="Enter OTP"
                  required
                  name="otp"
                  max="999999"
                  maxLength={6}
                  value={otp}
                  onChange={registerDataChange}
                />
              </div>
              <br></br>
              {otpError && (
                <>
                  <span class="error-message" id="error_signup">
                    Invalid OTP
                  </span>
                  <br></br>
                </>
              )}
              <input type="submit" value="Verify OTP" className="signUpBtn" />
            </form>

            <br />
            <div className="socialSignup">
              <i class="fa-brands fa-google" onClick={() => loginGoogle()}></i>
            </div>
            <br />
            <hr />
            <br />
            {/* ------------Paste Sign up form here------------ */}
            <div className="createAccount_n">
              <h3>Already have an account ?</h3>
              <br />
              <button
                className="outlinedBtn"
                onClick={(e) => switchTabs(e, "login")}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>

      <Fragment>{loading ? <Loader /> : <Fragment></Fragment>}</Fragment>
    </>
  );
};

export default LoginSignUp;

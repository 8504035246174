import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, Adminlogin, register } from "../../actions/userAction";
import { useAlert } from "react-alert";
import Loader from "../layout/Loader/Loader";
import CryptoJS from "crypto-js";
import CustomModal from '../layout/CustomModal'

function Login({ history, location }) {
  // const secret = process.env.REACT_APP_SECRET
  // const key = CryptoJS.enc.Hex.parse('2b7e151628a4d2a6abf7y58809cf4t3c');
  // const iv = CryptoJS.enc.Hex.parse('3ad77bb44d7a3s60a85ecaf32464ef97');
  const dispatch = useDispatch();
  const alert = useAlert();
  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const [showPassword, setShowPassword] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");


  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });

  const { name, email, password } = user;

  const [avatar, setAvatar] = useState("/Profile.png");
  const [avatarPreview, setAvatarPreview] = useState("/Profile.png");
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [loginError, setLoginError] = useState("");

  const loginSubmit = async (e) => {
    e.preventDefault();
    // console.log("btoa(loginPassword)", btoa(loginPassword), loginPassword)
    // let email = CryptoJS.AES.encrypt(btoa(loginEmail), key, { iv: iv }).toString()
    // let pass = await CryptoJS.AES.encrypt(loginPassword, key, { iv: iv }).toString()
    let value = await dispatch(Adminlogin(Buffer.from(loginEmail).toString('base64'), Buffer.from(loginPassword).toString('base64')));
    if (value === 'success') {
      setPopupContent("Login Successful")
      setShowPopup(true)
    } else {
      setLoginError(value);
    }
  };

  const registerSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("password", password);
    myForm.set("avatar", avatar);
    dispatch(register(myForm));
  };

  const registerDataChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  const redirect = location.search ? location.search.split("=")[1] : "/account";

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isAuthenticated) {
      history.push("/admin/dashboard");
    }
  }, [dispatch, error, alert, history, isAuthenticated, redirect]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };


  return (
    <>
      <CustomModal
        type="warning"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <Fragment>
        {loading ? (
          <Loader />
        ) : (
          <div className="loginPage h100">
            <div className="row h100">
              <div className="col-md-3">
                <div className="loginleft">
                  <a href="/">
                    <img src="./images/VastrakalaLogoWhite.svg" width="100%" />
                  </a>
                  <div className="welcomeDiv">
                    <h1>Welcome</h1>
                    <p>to Vastrakala</p>
                  </div>
                  <p>Copyright @ 2024 Vastrakala</p>
                </div>
              </div>
              <div className="col-md-9">
                <div className="loginFormContainer">
                  <div className="card">
                    <div className="card-body">
                      <div class="pt-4 pb-2">
                        <h5 class="card-title text-center pb-0 fs-4">Login to Admin Account</h5>
                        <p class="text-center small">Enter your username & password to login</p>
                      </div>
                      <div className="loginForm">
                        <form className="row g-3 needs-validation"
                          encType="multipart/form-data"
                          onSubmit={loginSubmit}>
                          <div className="col-md-12">
                            <label for="yourUsername" className="form-label">
                              Username
                            </label>
                            <div className="input-group has-validation">
                              <input
                                type="text"
                                name="username"
                                className="form-control"
                                placeholder="Email"
                                required
                                value={loginEmail}
                                onChange={(e) => setLoginEmail(e.target.value)}
                              />
                              <div className="invalid-feedback">
                                Please enter your username.
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 logPass">
                            <label for="yourPassword" className="form-label">
                              Password
                            </label>
                            <input
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              className="form-control"
                              required
                              value={loginPassword}
                              onChange={(e) => setLoginPassword(e.target.value)}
                            />
                            <i onClick={(e) => setShowPassword(!showPassword)} className={`fa-regular fa-eye${showPassword ? "-slash" : ""}`}></i>
                            <div className="invalid-feedback">
                              Please enter your password!
                            </div>

                          </div>
                          {loginError && (
                            <span class="error-message" id="error_login">
                              {loginError}
                            </span>
                          )}

                          {/* <div className="col-md-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="remember"
                                value="true"
                              />
                              <label className="form-check-label" for="rememberMe">
                                Remember me
                              </label>
                            </div>
                          </div> */}
                          <div className="col-md-12">
                            <button className="loginBtn" type="submit" >
                              Login
                            </button>
                          </div>
                          <div className="col-md-12">
                            <p className="forPas">
                              <a href="/ForgotPass">Forgot Password?</a>
                            </p>
                          </div>
                        </form >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>

    </>
  );
}

export default Login;

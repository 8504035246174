import {
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  REMOVE_CART_ITEM,
  UPDATE_CART,
  SAVE_SHIPPING_INFO,
  LOGOUT_CART,
  DELETE_CART,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY
} from "../constants/cartConstants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

// Add to Cart
export const getItems_Cart = (isAuthenticated) => async (dispatch, getState) => {
  if (isAuthenticated) {
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.get(
      `/api/items/cart`,
      config
    );
    dispatch({
      type: ADD_TO_CART,
      payload: data.data
    });
  } else {

  }
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// Add to Cart
export const addItemsToCart = (id, quantity, isAuthenticated, product, customization = {}) => async (dispatch, getState) => {
  if (isAuthenticated) {
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.post(
      `/api/items/cart/${id}`,
      { quantity, customization },
      config
    );
    dispatch({
      type: ADD_TO_CART,
      payload: data.data
    });
    if (data.message) return data.message
  } else {
    dispatch({
      type: UPDATE_CART,
      payload: product
    });
  }

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const addQuantityToCart = (id, isAuthenticated) => async (dispatch, getState) => {
  if (isAuthenticated) {
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.put(
      `/api/items/cart/i/${id}`,
      config
    );
    dispatch({
      type: ADD_TO_CART,
      payload: data.data
    });
    if (data.message) return data.message
  }
  else {
    dispatch({
      type: INCREASE_QUANTITY,
      payload: id
    });
  }

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};


export const decreaseQuantityToCart = (id, isAuthenticated) => async (dispatch, getState) => {
  if (isAuthenticated) {
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.put(
      `/api/items/cart/d/${id}`,
      config
    );
    dispatch({
      type: ADD_TO_CART,
      payload: data.data
    });
    if (data.message) return data.message
  }
  dispatch({
    type: DECREASE_QUANTITY,
    payload: id
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};


export const removefromCart = (id, isAuthenticated, product) => async (dispatch, getState) => {
  if (isAuthenticated) {
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.delete(
      `/api/items/cart/${id}`,
      config
    );
    if (data.message) alert(data.message)
    dispatch({
      type: ADD_TO_CART,
      payload: data.data
    });
  }
  else {
    dispatch({
      type: DELETE_CART,
      payload: product
    });
  }


  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};


// Add to Wishlist
export const addItemsToWishlist = (id) => async (dispatch, getState) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const { data } = await axios.post(
    `/api/items/wishlist/${id}`,
    config
  );

  dispatch({
    type: ADD_TO_WISHLIST,
    payload: data.data
  });

  localStorage.setItem("wishlist", JSON.stringify(getState().cart.wishlist));
  if (data.message) return data.message
};

export const removeItemfromWishlist = (id) => async (dispatch, getState) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const { data } = await axios.delete(
    `/api/items/wishlist/${id}`,
    config
  );
  dispatch({
    type: ADD_TO_WISHLIST,
    payload: data.data
  });

  localStorage.setItem("wishlist", JSON.stringify(getState().cart.wishlist));
  if (data.message) return data.message
};

export const getItems_Wishlist = (isAuthenticated) => async (dispatch, getState) => {
  if (isAuthenticated) {
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.get(
      `/api/items/wishlist`,
      config
    );
    dispatch({
      type: ADD_TO_WISHLIST,
      payload: data.data
    });
    if (data.message) return data.message
  } else {
    dispatch({
      type: ADD_TO_WISHLIST,
      payload: []
    })
  }

  localStorage.setItem("wishlist", JSON.stringify(getState().cart.wishlist));
};


export const moveItemToWishlist = (id) => async (dispatch, getState) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const { data } = await axios.put(
    `/api/items/wishlist/${id}`,
    config
  );
  dispatch({
    type: ADD_TO_WISHLIST,
    payload: data.data
  });
  dispatch(getItems_Cart())
  localStorage.setItem("wishlist", JSON.stringify(getState().cart.wishlist));
  if (data.message) return data.message
};


// SAVE SHIPPING INFO

export const get_Addresses = () => async (dispatch, getState) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const { data } = await axios.get(
    `/api/items/address`,
    config
  );
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data.data
  });
  localStorage.setItem("shippingInfo", JSON.stringify(data.data));
};



export const add_Addresses = (body) => async (dispatch, getState) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const { data } = await axios.post(
    `/api/items/address`,
    body,
    config
  );
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data.data
  });
  localStorage.setItem("shippingInfo", JSON.stringify(data.data));
};


export const update_Address = (index, body) => async (dispatch, getState) => {
  debugger
  const config = { headers: { "Content-Type": "application/json" } };
  if (index === '') {
    var { data } = await axios.post(
      `/api/items/address`,
      body,
      config
    );
    dispatch({
      type: SAVE_SHIPPING_INFO,
      payload: data.data
    });
    localStorage.setItem("shippingInfo", JSON.stringify(data.data));
    return
  } else {
    var { data } = await axios.put(
      `/api/items/address/${index}`,
      body,
      config
    );
    dispatch({
      type: SAVE_SHIPPING_INFO,
      payload: data.data
    });
    localStorage.setItem("shippingInfo", JSON.stringify(data.data));
    return
  }

};


export const delete_Address = (id) => async (dispatch, getState) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const { data } = await axios.delete(
    `/api/items/address/${id}`,
    config
  );
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data.data
  });
  localStorage.setItem("shippingInfo", JSON.stringify(data.data));
};


export const set_default_Address = (id) => async (dispatch, getState) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const { data } = await axios.post(
    `/api/items/address/default/${id}`,
    config
  );
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data.data
  });
  localStorage.setItem("shippingInfo", JSON.stringify(data.data));
};





export const saveShippingInfo = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  });

  localStorage.setItem("shippingInfo", JSON.stringify(data));
};



// Add to Cart
export const logout_Cart = () => async (dispatch, getState) => {
  dispatch({
    type: LOGOUT_CART,
  });
};
import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import Loader from "../layout/Loader/Loader";
import "quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CustomModal from '../layout/CustomModal'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function AddProduct(props) {
  const [desc, setDesc] = useState(props?.value ?? "");
  const [title, setTitle] = useState(props?.value ?? "");
  const [styelfit, setStyleAndFitTips] = useState(props?.value ?? "");
  const [cat, setCat] = useState(props?.value ?? "");
  const [subcat, setsubcat] = useState(props?.value ?? []);
  const [isTrending, setisTrending] = useState(props?.value ?? "Y");
  const [weight, setWeight] = useState(props?.value ?? "");
  const [styleNo, setStyleNo] = useState(props?.value ?? "");
  const [loading, setLoading] = useState(false);
  const [allCategories, setallCategories] = useState([]);
  const [allSubCategories, setallSubCategories] = useState([]);
  const [colorVariants, setColorVariants] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };
  const [buttonName, setButtonName] = useState(
    props.location?.state ? "Update " : "Publish "
  );


  const addColorVariant = () => {
    // Automatically add size variations for the newly added color
    const newSizes = Array.from({ length: 1 }, () => ({
      size: "",
      quantity: '',
      tooltip: '',
    }));
    const newColorVariant = {
      name: "",
      regularPrice: '',
      discount: '',
      salePrice: '',
      image: [],
      images: [],
      sizes: newSizes,
      video: ''
    };

    setColorVariants([...colorVariants, newColorVariant]);
  };
  const handleColorVariantChange = (index, value, column) => {
    const updatedColorVariants = [...colorVariants];
    if (column === "images" && value.length > 0) {

      // const compressedImages = [];
      // // Compress each image in the value array
      // value.forEach((originalFile, i) => {
      //   Resizer.imageFileResizer(
      //     originalFile,
      //     400, // max width
      //     550, // max height
      //     "png", // output format same as original file format
      //     70, // quality
      //     0, // rotation
      //     (uri) => {
      //       compressedImages[i] = uri;
      //       console.log("compressedImages", compressedImages)
      //       // Check if all images are compressed
      //       if (compressedImages.length === value.length) {
      //         // Update updatedColorVariants[index][column] with compressed images
      //         updatedColorVariants[index][column] = [...updatedColorVariants[index][column], ...compressedImages];
      //         setColorVariants(updatedColorVariants); // Update state after setting the compressed images
      //       }
      //     },
      //     'file' // output type
      //   );
      // });
      updatedColorVariants[index][column] = [...updatedColorVariants[index][column], ...value]
      setColorVariants(updatedColorVariants);
    }
    else if (column === "image") {
      // const originalFile = value[0];
      // if (originalFile)
      //   Resizer.imageFileResizer(
      //     originalFile,
      //     400, // max width
      //     550, // max height
      //     "png", // output format same as original file format
      //     70, // quality
      //     0, // rotation
      //     (uri) => {
      //       updatedColorVariants[index][column] = [uri];
      //       setFile(uri);
      //       setColorVariants(updatedColorVariants);
      //     },
      //     'file' // output type
      //   );
      updatedColorVariants[index][column] = value;
      setColorVariants(updatedColorVariants);
    }
    else {
      updatedColorVariants[index][column] = value;
      setColorVariants(updatedColorVariants);
    }
  };

  const removeGalleryImage = (index, i) => {
    const updatedColorVariants = [...colorVariants];
    updatedColorVariants[index]["images"].splice(i, 1);
    setColorVariants(updatedColorVariants);
  };



  const handleSizeVariantChange = (index, i, value, column) => {
    const updatedColorVariants = [...colorVariants];
    updatedColorVariants[index]["sizes"][i][column] = value;
    setColorVariants(updatedColorVariants);
  };

  function getCategory() {
    fetch(`/api/admin/category/parent`)
      .then((response) => response.json())
      .then((data) => {

        if (data.length) {
          setallCategories(data);
        }
      });
  }

  function getSubCategory() {
    debugger
    fetch(`/api/admin/category/` + cat)
      .then((response) => response.json())
      .then((data) => {
        setsubcat([])
        if (data.length) {
          setallSubCategories(data);
        } else {
          setallSubCategories([])
        }
      });
  }

  const handleChange_category = (event) => {
    debugger
    const {
      target: { value },
    } = event;
    setsubcat(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    getCategory();
    addColorVariant()
    console.log("props", props)
    if (props?.location?.state) {
      getProduct(props?.location?.state)
    }
    // addColorVariant();
  }, []);

  useEffect(() => {
    if (cat) {
      getSubCategory();
    }
    else {
      setallSubCategories([])
    };
  }, [cat]);

  async function publishProduct(isActive) {
    if (cat === "") {
      setPopupContent("Please select category")
      setShowPopup(true)
    } else if (subcat.length === 0) {
      setPopupContent("Please select sub category")
      setShowPopup(true)
    } else {
      setLoading(true);
      try {
        const requestOptions = {
          method: props.location?.state ? "PUT" : "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: title,
            description: Buffer.from(desc).toString('base64'),
            rich_description: Buffer.from(styelfit).toString('base64'),
            category: cat,
            active: isActive,
            trending: isTrending,
            weight: weight,
            style_no: styleNo,
            sub_category: subcat,
          }),
        };
        var url = "/api/admin/products";
        if (props.location?.state) {
          url = "/api/admin/products/" + props.location?.state;
        }
        var res = await fetch(url, requestOptions);
        var data = await res.json();

        if (data?.product?.id) {
          var product_id = data.product.id;
          await Promise.all(
            colorVariants.map(async (variant, index) => {
              var formData = new FormData();
              formData.append("image", variant.image[0]);
              for (let i = 0; i < variant.images.length; i++) {
                formData.append("images", variant.images[i]);
              }
              formData.append("parent_id", product_id);
              formData.append("name", variant.name);
              formData.append("regularPrice", variant.regularPrice);
              formData.append("sellingPrice", Math.round(variant.regularPrice - (variant.regularPrice * (variant.discount / 100))));
              formData.append("sizes", JSON.stringify(variant.sizes));
              formData.append("video", variant.video);
              res = await fetch("/api/admin/products/images", {
                body: formData,
                method: "post",
              });
              data = await res.json();
              if (res.ok && index === colorVariants.length - 1) {
                setLoading(false);
                setDesc("")
                setTitle("")
                setStyleAndFitTips("")
                setCat("")
                setsubcat([])
                setisTrending("Y")
                setWeight("")
                setStyleNo("")
                setallSubCategories([])
                const newSizes = Array.from({ length: 1 }, () => ({
                  size: "",
                  quantity: '',
                  tooltip: '',
                }));
                const newColorVariant = {
                  name: "",
                  regularPrice: '',
                  discount: '',
                  salePrice: '',
                  image: [],
                  images: [],
                  sizes: newSizes,
                  video: ''
                };
                setColorVariants([newColorVariant]);
                setPopupContent("Product updated successfully.")
                setShowPopup(true)
              }
            })
          );
        } else {
          setLoading(false);
          setPopupContent("Incorrect Entry")
          setShowPopup(true)
        }
      } catch (e) {
        setLoading(false);
        setPopupContent("Error in uploading product: " + e);
        setShowPopup(true)
      }
    }
  }

  const removeColorVariant = (index) => {
    const updatedColorVariants = [...colorVariants];
    updatedColorVariants.splice(index, 1);
    setColorVariants(updatedColorVariants);
    // Remove the corresponding size variations
    const updatedSizes = [...sizes];
    updatedSizes.splice(index * 4, 4);
    setSizes(updatedSizes);
  };

  const handleSizeChange = (colorIndex, sizeIndex, property, value) => {
    const updatedColorVariants = [...colorVariants];
    // Ensure that the sizes array is initialized for the color variant
    if (!updatedColorVariants[colorIndex].sizes) {
      updatedColorVariants[colorIndex].sizes = [];
    }
    updatedColorVariants[colorIndex].sizes[sizeIndex][property] = value;
    setColorVariants(updatedColorVariants);
    console.log(colorVariants);
  };

  const handleRemoveSize = (colorIndex, sizeIndex) => {
    const updatedColorVariants = [...colorVariants];
    console.log("Before:", updatedColorVariants);
    updatedColorVariants[colorIndex].sizes.splice(sizeIndex, 1);
    console.log("After:", updatedColorVariants);
    setColorVariants(updatedColorVariants);
    console.log(colorVariants);
  };

  const removeSizeVariant = (index, i) => {
    const newColorVariant = [...colorVariants]
    newColorVariant[index].sizes.splice(i, 1);
    setColorVariants(newColorVariant);
  }

  const addSize = (colorIndex) => {
    if (colorIndex !== -1) {
      // If colorIndex is not null, update the specific color variant with a new size
      const newSize = { size: "", quantity: '', tooltip: '' };
      const updatedColorVariants = [...colorVariants];
      updatedColorVariants[colorIndex].sizes = [
        ...(updatedColorVariants[colorIndex].sizes || []), // Existing sizes
        newSize,
      ];
      setColorVariants(updatedColorVariants);
    } else {

      const newColorVariant = [...colorVariants]
      newColorVariant[colorIndex].sizes.push({ size: "", quantity: "", tooltip: '' })
      setColorVariants(newColorVariant);
    }
  };

  // Function to generate a unique key for each size
  const generateSizeKey = (colorIndex, sizeIndex) =>
    `${colorIndex}-${sizeIndex}`;

  async function getProduct(id) {
    var res = await fetch(`/api/admin/products/${id}`);
    var data = await res.json();
    if (data?.product?.id) {
      setTitle(data.product.name)
      setDesc(data.product.description)
      setStyleAndFitTips(data.product.rich_description)
      setCat(data.product.category)
      setsubcat(data.product.sub_category)
      setWeight(data.product.weight)
      setColorVariants(data.product.colorVariants)
    } else {
      setPopupContent("Product not found")
      setShowPopup(true)
    }
  }

  function deleteVariant(index, e) {
    e.preventDefault()
    const newColorVariant = [...colorVariants]
    newColorVariant.splice(index, 1);
    setColorVariants(newColorVariant);
  }

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Header></Header>
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-6">
              <div className="pagetitle">
                <h1>Add Product</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Add Product</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 justify-content-end d-flex align-items-center gap-2">
              <button type="button" className="whiteBtn">
                {" "}
                Discard
              </button>
              <button
                type="button"
                className="whiteBtn"
                onClick={() => {
                  publishProduct("N");
                }}
              >
                {" "}
                Save draft
              </button>
              <button
                type="submit"
                className="whiteBtn"
                onClick={() => {
                  publishProduct("Y");
                }}
              >
                {" "}
                {buttonName} {" Product"}
              </button>
            </div>
          </div>
          <div className="row">
            <section className="section addProduct">
              <div>
                <form className="mb-9">
                  <div className="row">
                    <div className="col-xl-8 col-12">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Title</h5>
                          <input
                            value={title}
                            onInput={(e) => setTitle(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''))}
                            placeholder="Write title here..."
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/* <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Price</h5>
                          <div className="row">
                            <div className="col-lg-6 col-12">
                              <h5 className="mb-2 text-1000">Regular price</h5>
                              <input
                                value={regularPrice}
                                onInput={(e) => setRegularPrice(e.target.value)}
                                placeholder="₹"
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="col-lg-6 col-12">
                              <h5 className="mb-2 text-1000">Sale price</h5>
                              <input
                                value={sellingPrice}
                                onInput={(e) => setSellingPrice(e.target.value)}
                                placeholder="₹"
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Details</h5>
                          <ReactQuill
                            className="quillEditor"
                            value={desc}
                            onChange={(value) => { setDesc(value) }}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"],
                                ["blockquote", "code-block"],
                                [{ header: 1 }, { header: 2 }],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ script: "sub" }, { script: "super" }],
                                [{ indent: "-1" }, { indent: "+1" }],
                                [{ direction: "rtl" }],
                                [{ size: ["small", false, "large", "huge"] }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }],
                                [{ font: [] }],
                                [{ align: [] }],
                                ["clean"],
                              ],
                            }}
                          />
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Style & Fit Tips</h5>
                          <ReactQuill
                            value={styelfit}
                            onChange={(value) => setStyleAndFitTips(value)}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"],
                                ["blockquote", "code-block"],
                                [{ header: 1 }, { header: 2 }],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ script: "sub" }, { script: "super" }],
                                [{ indent: "-1" }, { indent: "+1" }],
                                [{ direction: "rtl" }],
                                [{ size: ["small", false, "large", "huge"] }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }],
                                [{ font: [] }],
                                [{ align: [] }],
                                ["clean"],
                              ],
                            }}
                          />
                        </div>
                      </div>
                      <div className="productsVarients">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">Product Variations</h5>

                            <div className="varientWrapper">
                              {colorVariants.map((variant, index) => {
                                return (
                                  <div key={index} className="row">
                                    <div className="col-md-12">
                                      <h5 className="card-title mt-2">Variations {index + 1}</h5>
                                      {index !== 0 && <button className="blackbtn mt-2" onClick={(e) => deleteVariant(index, e)}>Delete variant </button>}
                                    </div>
                                    <div className="col-md-6">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Regular price <span>(₹)</span>
                                          </h5>
                                          <input
                                            value={variant.regularPrice}
                                            onChange={(e) =>
                                              handleColorVariantChange(
                                                index,
                                                e.target.value,
                                                "regularPrice"
                                              )
                                            }
                                            placeholder="₹"
                                            className="form-control"
                                            type="number"
                                            pattern="[0-9]+"
                                            min={0}
                                            max={100}
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Video Link
                                          </h5>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ex: https://www.youtube.com/embed/YSWMbwQu?si=JNDvbd3Oa070"
                                            value={variant.video}
                                            onChange={(e) =>
                                              handleColorVariantChange(
                                                index,
                                                e.target.value,
                                                "video"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Discount (%)
                                          </h5>
                                          <input
                                            value={variant.discount}
                                            onInput={(e) =>
                                              handleColorVariantChange(
                                                index,
                                                e.target.value,
                                                "discount"
                                              )
                                            }
                                            placeholder="₹"
                                            type="number"
                                            pattern="[0-9]+"
                                            min={0}
                                            max={100}
                                            className="form-control"
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Sale price <span>(₹)</span>
                                          </h5>
                                          <input
                                            value={Math.round(variant.regularPrice - (variant.regularPrice * (variant.discount / 100)))}
                                            disabled
                                            placeholder="₹"
                                            type="text"
                                            className="form-control"
                                          />
                                        </div>
                                        <div className="col-md-12">
                                          <h5 className="mb-2 text-1000">
                                            Color Name
                                          </h5>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ex: White | Red | Green"
                                            value={variant.name}
                                            onChange={(e) =>
                                              handleColorVariantChange(
                                                index,
                                                e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''),
                                                "name"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="colMainImage">
                                            <h5 className="mb-2 text-1000">
                                              Product Image
                                            </h5>
                                            <div className="gallImagess" >
                                              <label htmlFor="ggImage2" className="ppIamge" style={{ display: variant.image.length > 0 ? "none" : "flex" }}>
                                                <span className="delImage">
                                                  <i className="fa-solid fa-xmark"></i>
                                                </span>
                                                <i className="fa-solid fa-plus"></i>
                                              </label>
                                              <input
                                                id="ggImage2"
                                                type="file"
                                                onChange={(e) =>
                                                  handleColorVariantChange(
                                                    index,
                                                    Array.from(e.target.files),
                                                    "image"
                                                  )
                                                }
                                              /></div>
                                            <div className="sp_image">
                                              {variant.image.map((file) => {
                                                return (
                                                  <img
                                                    src={
                                                      typeof file == "string"
                                                        ? file
                                                        : URL.createObjectURL(file)
                                                    }
                                                    width="100%"
                                                  />
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="colMainImage">
                                            <h5 className="mb-2 text-1000">
                                              Gallery Images
                                            </h5>
                                            <div className="">
                                              <div className="variant_images">
                                                {variant?.images?.map((file, i) => {
                                                  return (
                                                    <div className="p_r" key={i}>
                                                      {file.type.startsWith('image/') ? (
                                                        <img
                                                          src={
                                                            typeof file == "string"
                                                              ? file
                                                              : URL.createObjectURL(file)
                                                          }
                                                          width="100%"
                                                        />
                                                      ) : (
                                                        <video width="100%" height="100%" controls>
                                                          <source src={typeof file == "string"
                                                            ? file
                                                            : URL.createObjectURL(file)} type="video/mp4" />
                                                          Your browser does not support the video tag.
                                                        </video>
                                                      )}
                                                      <i className="fa-solid fa-xmark p_a" onClick={() => removeGalleryImage(index, i)}></i>
                                                    </div>
                                                  );
                                                })}

                                                <div className="gallImagess">
                                                  <label htmlFor="ggImage">
                                                    <span className="delImage">
                                                      <i className="fa-solid fa-xmark"></i>
                                                    </span>
                                                    <i className="fa-solid fa-plus"></i>
                                                  </label>
                                                  <input
                                                    id="ggImage"
                                                    multiple
                                                    type="file"
                                                    onChange={(e) =>
                                                      handleColorVariantChange(
                                                        index,
                                                        Array.from(e.target.files),
                                                        "images"
                                                      )
                                                    }
                                                  />
                                                </div>

                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <hr />

                                    </div>
                                    <div className="col-md-10">
                                      {variant.sizes.map((size, i) => {
                                        return (
                                          <div key={i} className="row align-items-center">
                                            <div className="col-md-12">
                                              <h5 className="text-1000">Size {i + 1}</h5>
                                            </div>
                                            <div className="col-md-3">
                                              <input
                                                placeholder="Ex: 32,34.."
                                                type="text"
                                                className="form-control"
                                                value={size.size}
                                                onChange={(e) =>
                                                  handleSizeVariantChange(
                                                    index, i,
                                                    e.target.value,
                                                    "size"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-3">
                                              <input
                                                className="form-control"
                                                type="number"
                                                placeholder="Stock/Quantity"
                                                value={size.quantity}
                                                onChange={(e) =>
                                                  handleSizeVariantChange(
                                                    index, i,
                                                    e.target.value,
                                                    "quantity"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-4">
                                              <textarea className="SIzeTip form-control"
                                                value={size.tooltip}
                                                onChange={(e) =>
                                                  handleSizeVariantChange(
                                                    index, i,
                                                    e.target.value,
                                                    "tooltip"
                                                  )
                                                }
                                                placeholder="Tooltip Text"></textarea>
                                            </div>
                                            <div className="col-md-2">
                                              <div>
                                                <i class="fa-solid fa-trash" onClick={() => removeSizeVariant(index, i)}></i>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                    <div className="col-md-2">
                                      <div className="addMoreSize">
                                        <a className="adColBtn" onClick={() => addSize(index)}><i class="fa-solid fa-plus"></i> Add Size</a>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            <a className="addAnother" onClick={addColorVariant}><i className="fa-solid fa-plus"></i> Add Another Variation</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      {/* <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Image</h5>
                          <div className="productMainImage">
                            <img
                              src={
                                typeof file == "string"
                                  ? file
                                  : URL.createObjectURL(file)
                              }
                              width="100%"
                            />
                          </div>
                          <input type="file" onChange={handleChange} />
                        </div>
                      </div> */}
                      {/* <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Gallery Images</h5>
                          <div className="galleryImages">
                            {showfiles.map((file) => {
                              return (
                                <img
                                  src={
                                    typeof file == "string"
                                      ? file
                                      : URL.createObjectURL(file)
                                  }
                                  width="100%"
                                />
                              );
                            })}
                          </div>
                          <input
                            multiple
                            type="file"
                            onChange={handleChange_allfiles}
                          />
                        </div>
                      </div> */}
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Organize</h5>
                          <div className="gx-3 gy-4 row">
                            <div className="col-xl-12 col-sm-6 col-12">
                              <div className="d-flex gap-2 mb-2">
                                <h5 className="mb-0 text-1000">Trending</h5>
                              </div>
                              <select
                                value={isTrending}
                                onChange={(e) => setisTrending(e.target.value)}
                                aria-label="trending"
                                className="form-select"
                              >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                              </select>
                            </div>
                            <div className="col-xl-12 col-sm-6 col-12">
                              <div className="d-flex gap-2 mb-2">
                                <h5 className="mb-0 text-1000">Category</h5>
                                <a className="fw-bold fs-9" href="/addCategory">
                                  Add new category
                                </a>
                              </div>
                              <select
                                value={cat}
                                onChange={(e) => setCat(e.target.value)}
                                aria-label="category"
                                className="form-select"
                              >
                                <option value="">Select</option>
                                {allCategories.map((category) => {
                                  return (
                                    <option value={category.id}>
                                      {category.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            {allSubCategories.length > 0 && (
                              <div className="col-xl-12 col-sm-6 col-12">
                                <div className="d-flex gap-2 mb-2">
                                  <h5 className="mb-0 text-1000">
                                    Sub Category
                                  </h5>
                                </div>

                                <FormControl sx={{ m: 1, width: 300 }}>
                                  <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                                  <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={subcat}
                                    onChange={handleChange_category}
                                    input={<OutlinedInput label="Category" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                    renderValue={(selected) =>
                                      selected.map((id) => {
                                        const category = allSubCategories.find((cat) => cat.id === id);
                                        return category ? category.name : id;
                                      }).join(', ')
                                    }
                                  >
                                    {allSubCategories.map((category) => (
                                      <MenuItem key={category.id} value={category.id}>
                                        <Checkbox checked={subcat.indexOf(category.id) > -1} />
                                        <ListItemText primary={category.name} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            )}
                            <div className="col-xl-12 col-sm-6 col-12">
                              <h5 className="mb-2 text-1000">
                                Product Estimated Weight <span>(kg)</span>
                              </h5>
                              <input
                                value={weight}
                                onInput={(e) => setWeight(e.target.value)}
                                type="number"
                                placeholder="0"
                                className="form-control"
                              />
                            </div>
                            <div className="col-xl-12 col-sm-6 col-12">
                              <h5 className="mb-2 text-1000">Style No.</h5>
                              <input
                                value={styleNo}
                                onInput={(e) => setStyleNo(e.target.value)}
                                placeholder="0"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default AddProduct;

import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { logout } from "../../actions/userAction";
import { logout_Cart } from "../../actions/cartAction";

const ProtectedRoute = ({ isAdmin, isEditor, isEmployee, component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { loading, isAuthenticated, user } = useSelector((state) => state.user);
  const allowedRoles = ["admin", "Editor", "Employee"];

  useEffect(() => {
    if (!allowedRoles.includes(user?.role)) {
      dispatch(logout());
      dispatch(logout_Cart());
    }
  }, [user, allowedRoles, dispatch]);

  return (
    <Fragment>
      {(loading === false || loading === undefined) && (
        <Route
          {...rest}
          render={(props) => {
            if (!isAuthenticated) {
              return <Redirect to="/admin" />;
            }
            if (user?.role === 'admin') {
              return <Component {...props} />;
            }

            if (!allowedRoles.includes(user?.role)) {
              return <Redirect to="/admin" />;
            }

            if (isEditor && user?.role === 'Editor') {
              return <Component {...props} />;
            }

            if (isEmployee && user?.role === 'Employee') {
              return <Component {...props} />;
            } else {
              return <Redirect to="/admin" />;
            }
          }}
        />
      )}
    </Fragment>
  );
};

export default ProtectedRoute;

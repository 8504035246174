import React, { Fragment, useEffect, useState } from "react";
import CategoryBanner from "../../images/categoryBanner.png";
import Footer from "../layout/Footer/Footer";
import Slider from '@material-ui/core/Slider';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart, addItemsToWishlist } from "../../actions/cartAction";
import CustomModal from '../layout/CustomModal'
import Loader from "../layout/Loader/Loader";
import { Link } from "react-router-dom";

const Category = (props) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(
    (state) => state.user
  );
  const { cartItems, wishlist } = useSelector((state) => state.cart);
  const cart_ids = cartItems.map(obj => obj.product_id);
  const wishlist_ids = wishlist.map(obj => obj.product_id);

  const [products, setProducts] = useState([]);
  const history = useHistory();
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(props.location.state?.page ?? 1);
  const [currentCategory, setCurrentCategory] = useState(props.match.params.cat);
  const [startItem, setStartItem] = useState(0);
  const [endItem, setEndItem] = useState(0);
  const [pageItems, setPageItems] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [isCategoryOpen, setCategoryOpen] = useState(true);
  const [isFabricOpen, setFabricOpen] = useState(true);
  const [isWorkDetailsOpen, setWorkDetailsOpen] = useState(true);
  const [isSizeOpen, setSizeOpen] = useState(true);
  const [isColorOpen, setColorOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [sizeFilter, setSizeFilters] = useState([])
  const [colorFilter, setColorFilters] = useState([])
  const [categoryFilter, setcategoryFilters] = useState([])
  const [weightFilter, setWeightFilters] = useState([{ min: 0, max: 200000 }])
  const [priceFilter, setPriceFilters] = useState([0, 200000])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedSize, setSelectedSize] = useState([])
  const [selectedColor, setSelectedColor] = useState([])
  const [selectedWeight, setSelectedWeight] = useState([])
  const abortController = new AbortController();
  const { signal } = abortController;
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [banner, setBanner] = useState('');
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [catId, setCatId] = useState(props.match.params?.id ? props.match.params?.id : '');

  const sizeOrder = ["XS", "S", "M", "L", "XL", "XXL"];

  // Sort the array based on the sizeOrder array
  // const sortedData = sizeFilter.sort((a, b) => {
  //   return sizeOrder.indexOf(a.size) - sizeOrder.indexOf(b.size);
  // });

  const sortedData = sizeFilter.sort((a, b) => {
    const aSize = a.size;
    const bSize = b.size;

    // Check if both sizes are in the sizeOrder array
    const aIndex = sizeOrder.indexOf(aSize);
    const bIndex = sizeOrder.indexOf(bSize);

    if (aIndex !== -1 && bIndex !== -1) {
      // If both sizes are in the sizeOrder array, sort by sizeOrder
      return aIndex - bIndex;
    } else if (aIndex === -1 && bIndex === -1) {
      // If both sizes are numbers, sort them numerically
      return parseInt(aSize) - parseInt(bSize);
    } else {
      // If one is a size and the other is a number, keep sizes first
      return aIndex !== -1 ? -1 : 1;
    }
  });




  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };
  // Our States 
  const [value, setValue] = React.useState([priceFilter[0], priceFilter[1]]);

  // Changing State when volume increases/decreases 
  const rangeSelector = (event, newValue) => {
    setValue(newValue);
  };

  const toggleSize = (sizes, size) => {
    if (sizes.includes(size)) {
      return sizes.filter(s => s !== size);
    } else {
      return [...sizes, size];
    }
  };


  const handleClick = (url, id) => {
    history.push(url, { id });
  }

  function updatePage(item) {
    setCurrentPage(item)
  }

  function prevPage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  function nextPage() {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  async function getProducts(cat, sort, size, weight, category, catId, color) {
    console.log("getproducts called")
    setLoading(true)
    var sizefilter = ""
    if (size?.length) sizefilter = `&size=${size}`
    var colorfilter = ""
    if (color?.length) colorfilter = `&color=${color}`
    var weightfilter = ""
    if (weight?.length) weightfilter = `&weight=${weight}`
    var categoryfilter = ""
    if (category?.length) categoryfilter = `&category=${category}`
    var view = "d"
    if (window.innerWidth < 1000) view = "m";

    setProducts([])
    if (currentCategory)
      fetch(`/api/products/category/${cat}/${currentPage}?id=${catId}&sort=${sort}${sizefilter}${weightfilter}${categoryfilter}${colorfilter}&minPrice=${value[0]}&maxPrice=${value[1]}&view=${view}`, { signal })
        .then(response => response.json())
        .then(data => {
          console.log("data", data)
          if (data) {
            setProducts(data.products)
            setTotalCount(data.totalCount);
            setTotalPages(data.totalPages);
            setStartItem((currentPage - 1) * (window.innerWidth < 1000 ? 30 : 30) + 1);
            setEndItem(Math.min(currentPage * (window.innerWidth < 1000 ? 30 : 30), data.totalCount));
            var pageItem = []
            for (let i = 1; i <= data.totalPages; i++) {
              pageItem.push(i);
            }
            setPageItems(pageItem)
          }
          setLoading(false)
        });
  }

  async function getFilters(cat) {
    if (cat !== "Bestseller") {
      var res = await fetch(`/api/products/filters/${cat}`)
      if (res.ok) {
        var data = await res.json()
        setSizeFilters(data.size)
        setColorFilters(data.color)
        setWeightFilters(data.weight)
        setPriceFilters([data.price[0].min, data.price[0].max,])
        setcategoryFilters(data.categories)
        setBanner(data.banner)
        if (data.price[0]?.min) {
          const params = new URLSearchParams(props.location.search);
          const maxPrice = params.get('maxPrice')
          if (maxPrice) {
            setValue([0, maxPrice]);
          }
          else {
            setValue([data.price[0].min, data.price[0].max,])
          }
        }
        // setPriceFilters(data.Price)
      }
    }
  }

  useEffect(() => {
    console.log("catId", catId)
    window.scrollTo(0, 0);
    setCurrentCategory(props.match.params.cat)
    setCatId(props.match.params?.id ? props.match.params?.id : '')
  }, [props]);


  useEffect(() => {
    try {
      if (props.location.search) {
        const params = new URLSearchParams(props.location.search);
        const maxPrice = params.get('maxPrice')
        setValue([0, maxPrice]);
      }
      let timeoutId = setTimeout(() => {
        getFilters(props.match.params.cat)
      }, 0);
      return () => {
        clearTimeout(timeoutId);
        abortController && abortController.abort();
      };
    }
    catch (e) {
      console.log(e)
    }
  }, [props]);

  useEffect(() => {
    console.log("getproducts invoce", value)
    try {
      let timeoutId = setTimeout(() => {
        getProducts(props.match.params.cat, sortBy, selectedSize, selectedWeight, selectedCategory, catId, selectedColor)
      }, 300);
      return () => {
        clearTimeout(timeoutId);
        // abortController && abortController.abort();
      };
    }
    catch (e) {
      console.log(e)
    }
  }, [sortBy, selectedSize, selectedWeight, value, currentPage, selectedCategory, selectedColor]);



  const toggleAccordion = (accordion) => {
    switch (accordion) {
      case 'category':
        setCategoryOpen(!isCategoryOpen);
        break;
      case 'fabric':
        setFabricOpen(!isFabricOpen);
        break;
      case 'workDetails':
        setWorkDetailsOpen(!isWorkDetailsOpen);
        break;
      case 'size':
        setSizeOpen(!isSizeOpen);
        break;
      case 'color':
        setColorOpen(!isColorOpen);
        break;
      default:
        break;
    }
  };

  const getAccordionIcon = (isOpen) => (isOpen ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>);


  const faqData = [
    {
      question: 'What is Lorem Ipsum?',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
      question: 'Why do we use it?',
      answer: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    },
    {
      question: 'Where does it come from?',
      answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.',
    },
    {
      question: 'Is it safe to use Lorem Ipsum?',
      answer: 'Yes, Lorem Ipsum is considered safe to use. It has been used as the industry standard dummy text ever since the 1500s.',
    },
    {
      question: 'Can I modify the Lorem Ipsum text?',
      answer: 'Yes, you are free to modify the Lorem Ipsum text to suit your needs. It is often used as a placeholder for content.',
    },
  ];

  const toggleFAQAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  async function addItems(id, product) {
    dispatch(addItemsToCart(id, 1, isAuthenticated, product));
    setPopupContent("Product added to cart")
    setShowPopup(true)

  }

  async function addItemsWish(id) {
    if (isAuthenticated) {
      dispatch(addItemsToWishlist(id));
      setPopupContent("Product added to Wishlist")
      setShowPopup(true)
    } else {
      history.push("/LoginSignup");
    }
  }

  return (
    <>
      {/* <Header></Header> */}
      {loading ? <Loader /> : ""}
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />

      <div className="categoryBanner">
        <img src={banner ? process.env.REACT_APP_BASE_URL + banner : ""} width="100%" />
      </div>
      <div className="categoryBreadcrum">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pagelinks">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="7"
                        viewBox="0 0 5 7"
                        fill="none"
                      >
                        <path
                          d="M4.19983 3.23364L1.42249 0.368408L0.930298 0.825439L3.26819 3.23364L0.859985 5.69458L1.33459 6.16919L4.02405 3.39185L4.19983 3.23364Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </li>
                  <li>
                    <a href="#">{props.match.params.cat}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="categoryPage p-both-50">
        <div className="container">

          <div className="row">
            {props.match.params.cat === "Bestseller" || products?.length === 0 ? "" :
              (
                <div className="col-md-3">
                  {/* <button>Filter</button> */}
                  <div className="sidebar">
                    <h2 className="sidebarTitle">Filter</h2>
                    {categoryFilter.length > 0 && (
                      <div className="filterCol">
                        <div className="coltitle" onClick={() => toggleAccordion('category')}>Sub Category <div className='accordiantoggle'>{getAccordionIcon(isCategoryOpen)}</div></div>
                        {isCategoryOpen && (
                          <div className="filterWrapper">
                            {categoryFilter.map((category, index) => {
                              return (
                                <div key={index} className="singleFilter">
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox_id"
                                    value={category.id}
                                    checked={selectedCategory.includes(category.id)} onChange={() => setSelectedCategory(prevSizes => toggleSize(prevSizes, category.id))}
                                  />
                                  <label for="checkbox_id">
                                    {category.name}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    )}
                    {sortedData.length > 0 && (
                      <div className="filterCol">
                        <div className="coltitle" onClick={() => toggleAccordion('size')}>Size <div className='accordiantoggle'>{getAccordionIcon(isSizeOpen)}</div></div>
                        {isSizeOpen && (
                          <div className="filterWrapper">
                            {sortedData.map((size => {
                              return (
                                <div className="singleFilter">
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox_id"
                                    value={size.size}
                                    checked={selectedSize.includes(size.size)} onChange={() => setSelectedSize(prevSizes => toggleSize(prevSizes, size.size))}
                                  />
                                  <label for="checkbox_id">
                                    {size.size}
                                    {/* <span className="pCount">({size.sum})</span> */}
                                  </label>
                                </div>
                              )
                            }))}
                          </div>
                        )}
                      </div>
                    )}
                    {colorFilter.length > 0 && (
                      <div className="filterCol">
                        <div className="coltitle" onClick={() => toggleAccordion('color')}>Color <div className='accordiantoggle'>{getAccordionIcon(isColorOpen)}</div></div>
                        {isColorOpen && (
                          <div className="filterWrapper">
                            {colorFilter.map((color => {
                              return (
                                <div className="singleFilter">
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox_id"
                                    value={color.name}
                                    checked={selectedColor.includes(color.name)} onChange={() => setSelectedColor(prevColor => toggleSize(prevColor, color.name))}
                                  />
                                  <label for="checkbox_id">
                                    {color.name}
                                    {/* <span className="pCount">({size.sum})</span> */}
                                  </label>
                                </div>
                              )
                            }))}
                          </div>
                        )}
                      </div>
                    )}
                    {weightFilter.length > 0 && (
                      <div className="filterCol dnone">
                        <div className="coltitle" onClick={() => toggleAccordion('fabric')}>Weight <div className='accordiantoggle'>{getAccordionIcon(isFabricOpen)}</div></div>
                        {isFabricOpen && (<div className="filterWrapper">
                          {weightFilter.map((weight => {
                            return (
                              <div className="singleFilter">
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  id="checkbox_id"
                                  value={weight.weight}
                                  checked={selectedWeight.includes(weight.weight)} onChange={() => setSelectedWeight(prevSizes => toggleSize(prevSizes, weight.weight))}
                                />
                                <label for="checkbox_id">
                                  {weight.weight}
                                  {/* <span className="pCount">({weight.sum})</span> */}
                                </label>
                              </div>
                            )
                          }))}
                        </div>)}
                      </div>
                    )}
                    <div className="filterCol">
                      <div className="coltitle" onClick={() => toggleAccordion('workDetails')}>Price range<div className='accordiantoggle'>{getAccordionIcon(isWorkDetailsOpen)}</div></div>
                      {isWorkDetailsOpen && (<div className="filterWrapper">
                        <div className="singleFilter priceFilter">
                          <Slider
                            min={priceFilter[0]}
                            max={priceFilter[1]}
                            value={value}
                            onChange={rangeSelector}
                            valueLabelDisplay="auto"
                            step={50}
                            disableSwap
                          />
                          <p>Your range of Price is between {value[0]} /- and {value[1]} /-</p>
                        </div>
                      </div>
                      )}
                    </div>
                  </div>
                </div>)
            }

            <div className={props.match.params.cat === "Bestseller" || products?.length === 0 ? "col-md-12" : "col-md-9"}>
              {products?.length ? (
                <>
                  <div className="gridTop">
                    <div className="gtLeft">
                      {props.match.params.cat === "Bestseller" ? <></> :
                        <p className="productsCount">Items {startItem}-{endItem} of {totalCount}</p>}
                    </div>
                    <select className="sBy" value={sortBy} onChange={e => setSortBy(e.target.value)}>
                      <option value="">Sort By Newest Arrivals</option>
                      <option value="Best">Sort By BestSelling</option>
                      <option value="LtH">Sort By Price Low to High</option>
                      <option value="HtL">Sort By Price High to Low</option>

                    </select>
                  </div>
                  <div className="row">
                    {products?.map((product) => {
                      let url = "";
                      if (props.match.params.cat !== "Bestseller") {
                        url = `/singleproduct/${product.name}/p/${product.product_id}/${props.match.params.cat}`
                      } else {
                        url = `/singleproduct/${product.name}/p/${product.product_id}`
                      }
                      return (<div className={`col-6 ${props.match.params.cat === "Bestseller" ? "col-md-3" : "col-lg-4"}`}>
                        <a className="singleCatProduct">
                          <div className="prodTop">
                            <img src={process.env.REACT_APP_BASE_URL + product.image} width="100%" onClick={() => handleClick(url, product.product_id)} />
                            <div className="hoverButtons" >

                              {!cart_ids.includes(product.product_id.toString()) ?
                                <a onClick={(e) => addItems(product.product_id, product)} className="addcart">
                                  Add to Cart
                                </a> :
                                <Link to="../../cart" className="addcart">View cart
                                </Link>
                              }

                              {!wishlist_ids.includes(product.product_id.toString()) ?
                                <a onClick={(e) => addItemsWish(product.product_id)} className="addwish">
                                  Add to Wishlist
                                </a> :
                                <Link to="../../wishlist" className="addwish">View Wishlist
                                </Link>
                              }


                            </div>
                          </div>
                          <h2 className="productTitle">
                            {product.name}
                            {/* ({product.color} - {product.size}) */}
                          </h2>
                          <div className="rating">
                            <ul>
                              <li>
                                <i class={`fa-${product.rating >= 1 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                              <li>
                                <i class={`fa-${product.rating >= 2 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                              <li>
                                <i class={`fa-${product.rating >= 3 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                              <li>
                                <i class={`fa-${product.rating >= 4 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                              <li>
                                <i class={`fa-${product.rating >= 5 ? "solid" : "regular"} fa-star`}></i>
                              </li>
                            </ul>
                            <span className="RatingCount">({product.rating})</span>
                          </div>
                          <p className="productPrice">Rs. {product.selling_price}</p>
                        </a>
                      </div>)
                    })
                    }
                  </div>
                  <div className="pagination">
                    <div className="row align-items-center">
                      {/* <div className="col-md-3">
                        <p className="productsCount">Items {startItem}-{endItem} of {totalCount}</p>
                      </div> */}
                      {pageItems.length > 1 ? <div className="col-md-12">
                        <div className="pagiWrap">
                          <span href="#" className="prev" onClick={prevPage}>Prev</span>
                          <ul>
                            {pageItems.map((item) => {
                              return (<li onClick={() => updatePage(item)}>
                                <a href="#" className={item == currentPage ? "active" : ""}>
                                  {item}
                                </a>
                              </li>)
                            })}
                          </ul>
                          <span href="#" className="next" onClick={nextPage} >Next</span>
                        </div>
                      </div> : ""}

                      {/* <div className="col-md-3">
                        <select className="sBy" value={sortBy} onChange={e => setSortBy(e.target.value)}>
                          <option value="">Sort By Newest Arrivals</option>
                          <option value="Best">Sort By BestSelling</option>
                          <option value="LtH">Sort By Price Low to High</option>
                          <option value="HtL">Sort By Price High to Low</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="catBottomLinks">
                    <a href="#">New Arrivals</a>
                    <a href="#">Bestsellers</a>
                    <a href="#">Sale</a>
                    <a href="#">Ready to Ship</a>
                    <a href="#">Shop Concepts</a>
                    <a href="#">Shop Stories</a>
                  </div> */}
                </>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="noFound">
                      {loading ? <h2>Loading...</h2> : <h2>No Products Found</h2>}


                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <div className="row">
            <div className="promoMsg">
              <h2>
                Buy Stylish Ladies Salwar Suits: Explore a Wide Range of
                Designs!
              </h2>
              <p>
                Want to look traditional without compromising on comfort? If
                yes, Salwar Suits are what you need. They are a special Indian
                ethnic wear, popular among women from Pakistani, India,
                Bangladesh, and other South Asian countries. The traditional
                favorite consists of a tunic-style top with loose-fitting pants
                and a long scarf called a dupatta. Interested in getting one for
                your wardrobe? Get salwar suits online in a hassle-free manner
                from Vastra Kala. We are popular for offering the most trendy
                salwar suit for women. Here, you can find traditional as well as
                contemporary ladies suits.
              </p>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="faqBox">
              <h2>FAQ's</h2>
              {faqData.map((item, index) => (
                <div key={index} className="faqItem">
                  <div className={`faqQuestion ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleFAQAccordion(index)}>
                    {item.question} <div className="faqAccordianSymbol">{activeIndex === index ? <i className="fa-solid fa-chevron-up"></i> : <i className="fa-solid fa-chevron-down"></i>}</div>
                  </div>
                  {activeIndex === index && (
                    <div className="faqAnswer">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div> */}
          {/* <div className="row">
            <div className="newlyStyle">
              <h2>Newly Arrived Styles with Price</h2>
              <ul>
                <li>
                  <h3>Embroidered Art Chanderi Silk Pakistani Suit in Pink</h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>
                    Digital Printed Art Muslin Silk Pakistani Suit in Teal Blue
                  </h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>Digital Printed Crepe Silk Suit in Olive Green</h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>Embroidered Rayon Suit in Dark Green</h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>Embroidered Cotton Suit in Light Purple</h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>Embroidered Art Silk Suit in Peach</h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>Woven Art Silk Jacquard Pakistani Suit in Mustard</h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>Printed Cotton Anarkali Suit in Light Green</h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>
                    Printed Pure Cotton Pakistani Suit in Light Moss Green
                  </h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>Embroidered Art Chanderi Silk Pakistani Suit in Pink</h3>
                  <p>Rs. 7,700.60</p>
                </li>
                <li>
                  <h3>Embroidered Art Chanderi Silk Pakistani Suit in Pink</h3>
                  <p>Rs. 7,700.60</p>
                </li>
              </ul>
            </div>
          </div> */}
        </div >
      </div >
      <Footer></Footer>
    </>
  );
};

export default Category;


import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userAction";
import { logout_Cart } from "../../actions/cartAction";
import { Link } from "react-router-dom";
import { loadingState } from "../../actions/userAction";

function Header() {
  const { user } = useSelector((state) => state.user);

  const [showOptions, setShowOptions] = useState(false);
  const [notificationCount, setNotificationCount] = useState(5); // Example count
  const [showNotificationTooltip, setShowNotificationTooltip] = useState(false);
  const notificationRef = useRef(null);
  const dispatch = useDispatch();

  const handleHover = () => {
    setShowOptions(true);
  };

  const handleMouseLeave = () => {
    setShowOptions(false);
    setShowNotificationTooltip(false);
  };

  const handleLogoutClick = () => {
    dispatch(logout());
    dispatch(logout_Cart());
  };

  const handleProfileClick = () => {
    setShowOptions(true);
  };

  const handleNotificationClick = () => {
    setShowNotificationTooltip(!showNotificationTooltip);
    // Add your logic for handling the notification click
    console.log("Notification clicked");
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setShowNotificationTooltip(false);
    }
  };

  useEffect(() => {
    dispatch(loadingState(false));
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="adminHeader">
        <div className="pSticky">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-4 col-md-2">
                <Link to="/admin">
                  <img src="../images/VastrakalaLogoWhite.svg" width="100%" />
                </Link>
              </div>
              <div className="col-8 col-md-10">
                <div className="adRight">
                  <ul className="adminMenu">
                    <li>
                      <Link to="/Admin/Dashboard">Dashboard</Link>
                    </li>
                    <li className="withSubmenu">
                      <Link to="#">
                        Product <i className="fa-solid fa-chevron-down"></i>
                      </Link>
                      <div className="adSubmenu">
                        <ul>
                          <li>
                            <Link to="/Admin/AddProduct">Add Product</Link>
                          </li>
                          <li>
                            <Link to="/Admin/AllProduct">All Product</Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="withSubmenu">
                      <a href="#">
                        Category <i className="fa-solid fa-chevron-down"></i>
                      </a>
                      <div className="adSubmenu">
                        <ul>
                          <li>
                            <Link to="/Admin/AddCategory">Add Category</Link>
                          </li>
                          <li>
                            <Link to="/Admin/AllCategory">All Category</Link>
                          </li>
                          <li>
                            <Link to="/Admin/sizeCustomization">Size Customization</Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Link to="../../../../admin/Orders">Orders</Link>
                    </li>
                    <li>
                      <Link to="../../../../admin/Users">Users</Link>
                    </li>
                    <li className="withSubmenu">
                      <a href="#">
                        Pages <i className="fa-solid fa-chevron-down"></i>
                      </a>
                      <div className="adSubmenu">
                        <ul>
                          <li>
                            <Link to="/Admin/EditHome">Home</Link>
                            <Link to="/Admin/Testimonials">Testimonials</Link>
                            <Link to="/Admin/discountCopouns">
                              Discount Coupons
                            </Link>
                            <Link to="/Admin/customers">
                              All Customers
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  {/* <div className="adminSearch">
                  <input type="text" name="" id="" placeholder="Search..." />
                  <i className="fa-solid fa-magnifying-glass"></i>
                </div> */}
                  <div className="adminNotification">
                    <div onClick={handleNotificationClick}>
                      <i className="fa-regular fa-bell"></i>
                      {notificationCount > 0 && showNotificationTooltip && (
                        <div
                          className="notificationTooltip"
                          ref={notificationRef}
                        >
                          {/* Your notification content goes here */}
                          You have {notificationCount} new notifications!
                        </div>
                      )}
                    </div>
                    {notificationCount > 0 && !showNotificationTooltip && (
                      <div className="notificationCount">
                        {notificationCount}
                      </div>
                    )}
                    {/* <ul>
                    <li><a href="#"><i className="fa-regular fa-bell"></i></a></li>
                    <li><a href="#"><i className="fa-regular fa-message"></i></a></li>
                  </ul> */}
                  </div>
                  <div
                    className="adminprof"
                    onMouseEnter={handleHover}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleProfileClick}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <img src="../images/profile-img.jpg" width="100%" /> */}
                    <p className="aName">
                      {user.first_name + " " + user.last_name}{" "}
                      <i className="fa-solid fa-chevron-down"></i>
                    </p>
                    {showOptions && (
                      <div className="userOptions">
                        <button onClick={handleLogoutClick}>Logout</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;

import React, { useState, useEffect, useRef } from "react";
import Header from "./header";
import Footer from "./footer";
import Loader from "../layout/Loader/Loader";
import CustomModal from "../layout/CustomModal";


export default function SizeCustomization() {
  const fileInputRef = useRef(null);
  const [customization, setCustomization] = useState([]);
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  // const [desc, setDesc] = useState("");
  const [file, setFile] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [popupType, setPopupType] = useState("success");
  const [loading, setLoading] = useState(false);


  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };

  function getCustomizations() {
    setLoading(true);
    fetch("/api/admin/customization")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCustomization(data);
        }
        setLoading(false);
      });
  }

  async function addCustomization() {
    setLoading(true);
    if (name !== "" && file && tag !== "") {
      var formData = new FormData();
      formData.append("image", file);
      formData.append("name", name);
      formData.append("tag", tag);
      var res = await fetch("/api/admin/customization", {
        body: formData,
        method: "post",
      });
      if (res.ok) {
        setFile("");
        setName("");
        setTag("");
        fileInputRef.current.value = null;
        getCustomizations();

        setPopupType("success");
        setPopupContent("Customization Added Successfully");
        setShowPopup(true);
      }
    } else {
      setPopupType("warning");
      setPopupContent("Please fill all values");
      setShowPopup(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    getCustomizations();
  }, []);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  async function deleteCustomizations(id) {
    const requestOptions = {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    };
    var res = await fetch("/api/admin/customization/" + id, requestOptions);
    var data = await res.json();
    getCustomizations();
    if (data.length > 0) {
      setPopupContent("Customization Deleted Successfully");
      setShowPopup(true);
    }
  }

  return (
    <>
      <CustomModal
        type={popupType}
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      {loading ? <Loader /> : <></>}

      <Header></Header>
      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>Size Customization</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Size Customization</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Add Size Customization</h5>
                  <div className="row">
                    <div className="col-md-12">
                      <label>Select Tag</label>
                      <select className="form-control" value={tag}
                        onChange={(e) => setTag(e.target.value)}>
                        <option value="">Select</option>
                        <option value="Men">Men</option>
                        <option value="Women">Women</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <label>Customization Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="For Example: Waist, Length etc"
                        value={name}
                        onInput={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Customization Modal Image</label>
                      <input type="file" className="form-control" onChange={handleChange} ref={fileInputRef} />
                      <div className="ModalIamge mt-2" style={{ display: "block" }}>
                        <img
                          src={
                            typeof file !== "string"
                              ? URL.createObjectURL(file)
                              : "https://vastrakala.com/images/dummy-image-square.jpg"
                          }
                          width="40"
                          alt=""
                        />
                        {/* <i className="fa-solid fa-circle-xmark"></i> */}
                      </div>
                    </div>
                    <div className="col-md-12 text-right">
                      <button className="blackBtn" onClick={addCustomization}>Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Size Customization Table</h5>
                  <div className="tableOflow dashboard CustSizeTable">
                    <table className="table datatable">
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>Sno.</th>
                          <th style={{ textAlign: "left", width: "40%" }}>
                            Customization Name
                          </th>
                          <th style={{ textAlign: "center", width: "10%" }}>
                            Tag
                          </th>
                          <th style={{ textAlign: "center", width: "20%" }}>
                            Modal Image
                          </th>
                          <th style={{ textAlign: "left", width: "20%" }}>
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          customization.length ? customization.map((item, index) => {
                            return (<><tr>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td style={{ textAlign: "center" }}>{item.tag}</td>
                              <td style={{ textAlign: "center" }}>
                                <img src={process.env.REACT_APP_BASE_URL + item.image} width="20" /></td>
                              <td>
                                <div className="sizeActions">
                                  {/* <div className="sEdit">
                                    <i className="fa-solid fa-pencil"></i>
                                  </div> */}
                                  <div className="sDelete">
                                    <i className="fa-solid fa-trash-can" onClick={(e) => {
                                      deleteCustomizations(item.id);
                                    }}></i>
                                  </div>
                                </div>
                              </td>
                            </tr></>)
                          }) : ""
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

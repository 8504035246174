import React, { useState, useEffect } from "react";
import axios from 'axios';
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";

function Failed({ history, location }) {

    async function VerifyOrder() {
        const params = new URLSearchParams(location.search);
        const order_id = params.get('order_id')
            ;
        try {
            const response = await fetch('/api/checkout/payment/cancel', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ order_id }), // Replace with the actual Payment Intent ID
            });

            if (response.ok) {
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error('Error verifying payment:', error);
        }
    }

    useEffect(() => {
        VerifyOrder()
    }, []);





    return (
        <>
            {/* <Header></Header> */}
            <div style={{ height: 500 }}>  Payment Failed</div >
            <Footer></Footer>
        </>

    )
}

export default Failed
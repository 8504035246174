import React, { useState, useEffect } from "react";
import "./Checkout.css";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import Visa from "../../images/visa.png";
import Stripe from "../../images/stripe.png";
import Razorpay_image from "../../images/razorpay.png";
import PayPal from "../../images/paypal.png";
import { useSelector, useDispatch } from "react-redux";
import { update_Address } from "../../actions/cartAction";
import axios from "axios";
import { Link } from "react-router-dom";
import AddressModal from "../layout/AddressModal";
import CustomModal from '../layout/CustomModal'
import Loader from "../layout/Loader/Loader";

function Checkout({ history }) {
  const dispatch = useDispatch();
  const { shippingInfo, cartItems, wishlist } = useSelector(
    (state) => state.cart
  );
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [tmc, settmc] = useState(false);
  const [tmc2, settmc2] = useState(true);
  const [firstName, setFirstName] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["first_name"] : ""
  );
  const [lastName, setLastName] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["last_name"] : ""
  );
  const [country, setCountry] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["country"] : "India"
  );
  const [city, setCity] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["city"] : ""
  );
  const [state, setState] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["state"] : ""
  );
  const [address1, setAddress1] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["address1"] : ""
  );
  const [address2, setAddress2] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["address2"] : ""
  );
  const [zip, setZip] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["zip"] : ""
  );
  const [mobileNo, setmobileNo] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["mobile"] : ""
  );
  const [email, setemail] = useState(
    shippingInfo.length > 0 ? shippingInfo[0]["email"] : ""
  );
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [maxDiscount, setMaxDiscount] = useState(0);
  const [defaultPayment, setDefaultPayment] = useState('PayPal');
  const [dollar, setDollar] = useState(0);
  const [checkoutBanner, setCheckoutBanner] = useState('');
  const [shippingChr, setShippingChr] = useState(0);
  const [shippingTax, setShippingTax] = useState(0);
  const [shippingError, setShippingError] = useState(false);
  const [loading, setLoading] = useState(false);

  function getDollarPrice() {
    fetch("/api/dollar")
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setDollar(data[0].value);
        }
      });
  }

  function getCheckoutBanner() {
    fetch("/api/banner/checkout")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCheckoutBanner(data[0]);
        }
      });
  }


  useEffect(() => {
    getCheckoutBanner();
    getDollarPrice();
  }, []);

  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.quantity * item.selling_price,
    0
  );

  const noItems = cartItems.reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  const itemsWeight = cartItems.reduce(
    (acc, item) => {
      return acc + parseInt(item.weight) * item.quantity
    },
    0
  );

  const [selectedAddress, setSelectedAddress] = useState(() => {
    const defaultAddress = shippingInfo.find(address => address.default === 'Y');
    return defaultAddress ? defaultAddress.id : null;
  });

  const [selectedCountry, setSelectedCountry] = useState(() => {
    const defaultAddress = shippingInfo.find(address => address.default === 'Y');
    return defaultAddress ? defaultAddress.country : null;
  });

  const [shippingCharges, setShippingCharges] = useState(selectedCountry !== "IN" ? shippingChr : 200 * noItems)
  // const shippingCharges = ;
  const tax = 0;
  // const tax = subtotal * 0.18;

  var discountAmount = (subtotal * discount) / 100;
  // Ensure discount doesn't exceed maximum discount limit
  if (discountAmount > maxDiscount) {
    discountAmount = maxDiscount;
  }
  // Calculate total price after discount
  var totalPriceAfterDiscount = subtotal - discountAmount;

  var totalPrice = totalPriceAfterDiscount + shippingCharges;// shippingCharges

  // Calculate original amount (excluding GST)
  const originalAmount = subtotal - tax;

  // const address = `${shippingInfo[0].address1}, ${shippingInfo[0].address2}, ${shippingInfo[0].city}, ${shippingInfo[0].state}, ${shippingInfo[0].zip}, ${shippingInfo[0].country}`;

  async function proceedToPayment() {
    try {
      if (shippingError) {
        setPopupContent('Address not deliverable')
        setShowPopup(true)
        return
      }
      var pay_amount = totalPrice;
      if (defaultPayment === 'Stripe' || defaultPayment === 'Razorpay') {
        pay_amount = totalPrice
      } else {
        pay_amount = (totalPrice / dollar).toFixed(2)
      }
      setLoading(true);
      const response = await axios.post("/api/checkout/payment/charge", {
        amount: pay_amount,
        cartItems: cartItems,
        coupon: code,
        discount: discountAmount,
        address: selectedAddress,
        customSizeData: JSON.parse(localStorage.getItem("customSizeData")),
        pay_type: defaultPayment,
      });
      if (defaultPayment === 'Razorpay') {
        const r_data = response.data;
        const options = {
          key: process.env.RAZORPAY_KEY_ID, // replace with your Razorpay key id
          amount: r_data.amount,
          currency: r_data.currency,
          name: 'Vastrakala',
          description: 'Total Amount for the order',
          order_id: r_data.id,
          handler: async (response) => {
            setLoading(true);
            const data = {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              order_id: r_data.order_id,
            };

            // Verify payment on backend
            const verify = await axios.post('/api/checkout/payment/verify', data);
            setLoading(false);
            if (verify.data.success) {
              window.location.href = verify.data.url;
            } else {
              window.location.href = verify.data.url
              // alert('Payment verification failed!');
            }
          },
          theme: {
            color: '#3399cc',
          },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        window.location.href = `${response.data.url}`;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  function validateAddress() {
    if (firstName == "") {
      return false;
    } else if (lastName == "") {
      return false;
    } else if (country == "") {
      return false;
    } else if (state == "") {
      return false;
    } else if (address1 == "") {
      return false;
    } else if (zip == "") {
      return false;
    } else if (mobileNo == "") {
      return false;
    } else if (email == "") {
      return false;
    } else {
      dispatch(
        update_Address('', {
          address1,
          address2,
          mobile: mobileNo,
          email,
          state,
          zip,
          country: country,
          first_name: firstName,
          last_name: lastName,
          city,
        })
      );
      return true;
    }
  }

  async function placeOrder() {
    // if (validateAddress()) {
    proceedToPayment();
    // } else {
    // alert("Please fill all required fileds");
    // }
  }

  async function applyCoupon() {
    try {
      const response = await axios.post("/api/checkout/payment/coupon", {
        code: code,
      });
      if (response?.data?.coupon) {
        setDiscount(response.data.coupon.discount);
        setMaxDiscount(response.data.coupon.max_discount);
      } else {
        setPopupContent(response.data.message)
        setShowPopup(true)
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function removeCoupon() {
    try {
      setDiscount(0);
      setMaxDiscount(0);
    } catch (error) {
      console.error(error);
    }
  }


  async function checkShipping(selectedAddress, country) {
    if (selectedAddress && country !== "IN") {
      try {
        settmc2(false);
        const response = await axios.get(`/api/checkout/payment/shipping/${selectedAddress}/${itemsWeight}`, {
          code: code,
        });
        if (response.status === 200) {
          settmc2(true);
          setShippingError(false);
          if (response?.data && response.data?.Details?.ShippingCharge) {
            setShippingChr(parseFloat(response.data.Details.ShippingCharge));
            setShippingCharges(parseFloat(response.data.Details.ShippingCharge));
            setShippingTax(parseFloat(response.data.Details.ShippingCharge));
          } else {
            setShippingChr(0);
            setShippingTax(0);
          }
        } else {
          settmc2(false);
          setShippingError(true);
          setShippingChr(0);
        }
      } catch (error) {
        settmc2(false);
        setShippingError(true);
        console.error(error);
        setShippingChr(0);
        setShippingCharges(0);
      }
    } else {
      settmc2(true);
      setShippingError(false);
      setShippingCharges(200 * noItems);
    }
  }

  useEffect(() => {
    checkShipping(selectedAddress);
  }, []);

  useEffect(() => {
    const defaultAddress = shippingInfo.find(address => address.default === 'Y');
    if (defaultAddress) {
      setSelectedAddress(defaultAddress.id);
      setSelectedCountry(defaultAddress.country);
    }
  }, [shippingInfo]);



  // const [selectedAddress, setSelectedAddress] = useState(false)
  function changeAddress(id, country) {
    setSelectedAddress(id)
    setSelectedCountry(country);
    checkShipping(id, country)
  }

  const [showAddressPopup, setShowAddressPopup] = useState(false);
  function editAddress(e, index) {
    e.preventDefault()
    setShowAddressPopup(true)
  }

  const handleAddressPopupClose = () => {
    setShowAddressPopup(false); // Close the popup
  };


  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };


  return (
    <>
      {loading ? <Loader /> : <></>}
      <CustomModal
        type="warning"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />

      <AddressModal
        showPopup={showAddressPopup}
        onClose={handleAddressPopupClose}
        index=''
        id=''

      />
      {/* <Header></Header> */}
      <div className="cartHeader text-center">
        {checkoutBanner && checkoutBanner.name && checkoutBanner.name !== "" &&
          <img src={process.env.REACT_APP_BASE_URL + checkoutBanner.name} />}
        <h2 className="pTitle">Checkout</h2>
      </div>
      <div className="categoryBreadcrum">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pagelinks">
                <ul>
                  <li>
                    <Link to="../../../">Home</Link>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="7"
                        viewBox="0 0 5 7"
                        fill="none"
                      >
                        <path
                          d="M4.19983 3.23364L1.42249 0.368408L0.930298 0.825439L3.26819 3.23364L0.859985 5.69458L1.33459 6.16919L4.02405 3.39185L4.19983 3.23364Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </li>
                  <li>
                    <a href="#">Checkout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="myCart p-both-50">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12">
              <div className="retCust">
                <p>
                  RETURNING CUSTOMER? CLICK HERE TO <a href="#">LOGIN</a>
                </p>
              </div>
            </div> */}
            <div className="col-md-8">
              <div className="checkoutForm">
                <div className="row">
                  <div className="col-6"><h2>Delivery</h2></div>
                  <div className="col-6 text-right">
                    <button onClick={(e) => editAddress(e, '')} className="addBTn"><i className="fa-solid fa-plus"></i> Add New Address</button>
                  </div>
                  <div className="col-md-12">
                    <div className="addressesContainer">
                      <div className="row">
                        {/* <div className="col-md-12 m-0">
                          <p className="font18 mt-3">Your addresses</p>
                        </div> */}
                        {shippingInfo.length > 0 ? shippingInfo.map((address, index) => {
                          return <div key={index} className="col-md-6">
                            <div className="singleAddressCheck">
                              <input
                                type="radio"
                                id={`address_${address.id}`}
                                name="fav_language"
                                value={address.id}
                                checked={address.id === selectedAddress}
                                onChange={() => changeAddress(address.id, address.country)}
                              />
                              <label htmlFor={`address_${address.id}`}>
                                <span className="addressTitle">{address.first_name} {address.last_name}</span>
                                {`${address.address1} ${address.address2}, 
                                ${address.city}, 
                                ${address.state},
                                ${address.zip}, ${address.country}`}
                                <p>
                                  {address.mobile}
                                </p>
                              </label>
                            </div>
                          </div>
                        }) : ""}

                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-md-6">
                  <Link to="../../cart" className="contiShop">
                    <i className="fa-solid fa-arrow-left-long"></i> Go To cart
                  </Link>
                </div>
                <div className="col-md-6">
                  <div className="createAcc">
                    {/* <a href="#" className="contiShop">
                      <i className="fa-solid fa-user"></i>Create An Account
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="cartSummary">
                <h2>Summary</h2>
                <table width={"100%"}>
                  <tr>
                    <td>Subtotal</td>
                    <td>Rs {originalAmount}  || $ {originalAmount && dollar ? (originalAmount / dollar).toFixed(2) : "..."}</td>
                  </tr>
                  <tr>
                    <td>Shipping Charges</td>
                    <td>{shippingError ? <tr>Address not deliverable</tr> : `Rs ${shippingCharges} || $ ${shippingCharges && dollar ? (shippingCharges / dollar).toFixed(2) : "..."}`}</td>
                  </tr>
                  {/* <tr>
                    <td>Estimated Tax</td>
                    <td>Rs {tax}</td>
                  </tr> */}
                  {discountAmount > 0 ? (
                    <tr>
                      <td>Copoun ({code})</td>
                      <td>Rs - {discountAmount} || $ {discountAmount && dollar ? (discountAmount / dollar).toFixed(2) : "..."}</td>
                      <td>
                        <button onClick={removeCoupon}>Delete</button>{" "}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  <tr>
                    <td>
                      <b>Order Total</b>
                    </td>
                    <td>
                      <b>Rs {totalPrice} || $ {totalPrice && dollar ? (totalPrice / dollar).toFixed(2) : "..."}</b>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="paymentMethod">
                <h3 className="mb-2">Have Discount Coupon ?</h3>
                <div className="row">
                  <div className="col-md-8">
                    <input
                      type="text"
                      required
                      className="form-control"
                      placeholder="Enter Coupon Code"
                      value={code}
                      onInput={(e) => setCode(e.target.value)}
                    />{" "}
                  </div>
                  <div className="col-md-4">
                    <button className="applyCop" onClick={applyCoupon}>
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div className="paymentMethod">
                <h3 className="mb-2">Choose Payment Method</h3>
                <div className="allPaymeth">
                  <ul>
                    <li>
                      <div className="singlePaymet">
                        <input type="radio" name="p1" id="PayPal" value="PayPal" checked={defaultPayment === "PayPal"} onChange={(e) => { setDefaultPayment("PayPal") }} />
                        <label htmlFor="PayPal"><img src={PayPal} /></label>
                        <div>$ {totalPrice && dollar ? (totalPrice / dollar).toFixed(2) : "..."}</div>
                      </div>
                    </li>
                    {/* <li>
                      <div className="singlePaymet">
                        <input type="radio" name="p1" id="Stripe" value="Stripe" checked={defaultPayment === "Stripe"} onChange={(e) => { setDefaultPayment("Stripe") }} />
                        <label htmlFor="Stripe"><img src={Stripe} /></label>
                        <div>Rs {totalPrice}</div>
                      </div>
                    </li> */}
                    <li>
                      <div className="singlePaymet">
                        <input type="radio" name="p1" id="Razorpay" value="Razorpay" checked={defaultPayment === "Razorpay"} onChange={(e) => { setDefaultPayment("Razorpay") }} />
                        <label htmlFor="Stripe"><img src={Razorpay_image} /></label>
                        <div>Rs {totalPrice}</div>
                      </div>
                    </li>
                    <li>
                      <button
                        className="blackbtn"
                        disabled={!tmc || !tmc2}
                        onClick={placeOrder}
                      >
                        Place Order
                      </button>
                    </li>
                    <li>
                      <div className="singlePaymet">
                        <input
                          type="checkbox"
                          name="p1"
                          id="tc"
                          checked={tmc}
                          onClick={(e) => settmc(!tmc)}
                        />
                        <label htmlFor="tc">
                          {" "}
                          I agree Terms and Conditions
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>

                <p>
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our privacy policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerTop">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="ftCard">
                <i class="fa-regular fa-paper-plane"></i>
                <p>We ship worldwide</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftCard">
                <i class="fa-brands fa-whatsapp"></i>
                <p>Chat on Whatsapp</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftCard">
                <i class="fa-solid fa-mobile-screen-button"></i>
                <p>
                  Call & Mail us to book a styling <br />
                  appointment +91 9872407145
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Checkout;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const CustomModal = ({ type, content, showPopup, onClose }) => {
    const handleClose = () => {
        onClose('N'); // Call the onClose callback when the popup is closed
    };

    const handleConfirm = (value) => {
        onClose(value); // Call the onClose callback when the popup is closed
    };

    return (
        <>
            <Modal show={showPopup} onHide={handleClose} className="alertsPop">
                {/* ---------Product Added Successfully------------------ */}
                {type === "success" ? (<div className="row">
                    <div className="col-md-12">
                        <div className="successIcon">
                            <i className="fa-solid fa-check"></i>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>{content}</p>
                    </div>
                </div>) : ""}

                {type === "warning" ? (<div className="row">
                    <div className="col-md-12">
                        <div className="deleteIcon">
                        <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>{content}</p>
                    </div>
                </div>) : ""}

                {type === "confirm" ? (<div className="row">
                    <div className="col-md-12">
                        <div className="deleteIcon">
                            <i className="fa-solid fa-trash-can"></i>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>{content}</p>
                    </div>
                    <div className="col-md-12 mt-5">
                        <div className="actionBtns">
                            <button className="blackbtn" onClick={(e) => { handleConfirm('Y') }}>Yes</button>
                            <button className="outlinedBtn" onClick={(e) => { handleConfirm('N') }}>No</button>
                        </div>
                    </div>
                </div>) : ""}
                {/* ---------------Do You want to delete the product-------------------- */}
                {/*  */}

                {/* ---------------Category Added------------------- */}
                {/* <div className="row">
                <div className="col-md-12">
                  <div className="successIcon">
                    <i className="fa-solid fa-check"></i>
                  </div>
                </div>
                <div className="col-md-12">
                  <p>Category Added Successfully</p>
                </div>
              </div> */}
            </Modal>
        </>
    );
};

export default CustomModal;

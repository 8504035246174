import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import CustomModal from '../layout/CustomModal'

function Discountcoupons() {

  const [coupons, setcoupons] = useState([]);
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [max, setMax] = useState("");
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  const [expDate, setExpDate] = useState(formattedDate);

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };


  function getcoupons() {
    fetch("/api/admin/coupons")
      .then((response) => response.json())
      .then((data) => {

        if (data) {
          setcoupons(data);
        }
      });
  }

  async function addcoupons() {

    if (code !== "") {
      var res = await fetch("/api/admin/coupons", {
        body: JSON.stringify({ code, discount, max, expDate }),
        headers: { "Content-Type": "application/json" },
        method: "post",
      });
      if (res.ok) {
        setCode("")
        setDiscount("")
        setMax("")
        getcoupons()
        setPopupContent("coupon Added Successfully")
        setShowPopup(true)
      }
    } else {
      setPopupContent("Please fill all values")
      setShowPopup(true)
    }
  }

  useEffect(() => {
    getcoupons();
  }, []);



  async function deletecoupon(id) {
    const requestOptions = {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    };
    var res = await fetch("/api/admin/coupons/" + id, requestOptions);
    var data = await res.json();
    getcoupons();
    if (data.length > 0) {
      setPopupContent("coupon Deleted Successfully")
      setShowPopup(true)
    }
  }



  return (
    <>
      <Header></Header>

      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />

      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="pagetitle">
                <h1>Coupons</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Add coupons</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Coupons</h5>
                  <div className="testiForm">
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Coupon Code"
                          required
                          value={code}
                          onInput={(e) => setCode(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="testiForm">
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Discount (%)"
                          required
                          value={discount}
                          onInput={(e) => setDiscount(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="testiForm">
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Max Discount"
                          required
                          value={max}
                          onInput={(e) => setMax(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="testiForm">
                    <div className="row">
                      <div className="col-md-12">
                        <p>Expiry Date</p>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Max Discount"
                          required
                          value={expDate}
                          onInput={(e) => setExpDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="testiForm">
                    <button onClick={addcoupons}
                      className="form-control blackBtn"
                    > Add coupon</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <h5 className="card-title">Active coupons</h5>
                    <div className="tableOuter adminTables">
                      <table className="phoenix-table fs-9 table">
                        <thead>
                          <tr>
                            <th className="">Coupon Code</th>
                            <th className="">Discount</th>
                            <th className="">Max Discount</th>
                            <th className="">Publish Date</th>
                            <th className="">Expiry Date</th>
                            <th className="">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {coupons.length > 0 ? coupons.map((coupon) => {
                            return (
                              <tr>
                                <td>{coupon.code}</td>
                                <td>{coupon.discount}</td>
                                <td>{coupon.max_discount}</td>
                                <td>{coupon.created_date}</td>
                                <td>{coupon.expiry_date}</td>
                                <td><button className="blackBtn" onClick={(e) => {
                                  deletecoupon(coupon.id)
                                }}>Delete coupon</button></td>
                              </tr>
                            )
                          }) : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Discountcoupons;

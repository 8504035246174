import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import Loader from "../layout/Loader/Loader";
import "quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import CustomModal from '../layout/CustomModal'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function ModifyProduct(props) {
  const history = useHistory();
  const [desc, setDesc] = useState(props?.value ?? "");
  const [title, setTitle] = useState(props?.value ?? "");
  const [styelfit, setStyleAndFitTips] = useState(props?.value ?? "");
  const [cat, setCat] = useState(props?.value ?? "");
  const [subcat, setsubcat] = useState(props?.value ?? []);
  const [isTrending, setisTrending] = useState(props?.value ?? "Y");
  const [weight, setWeight] = useState(props?.value ?? "");
  const [styleNo, setStyleNo] = useState(props?.value ?? "");
  const [loading, setLoading] = useState(false);
  const [allCategories, setallCategories] = useState([]);
  const [allSubCategories, setallSubCategories] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const handlePopupClose = () => {
    setShowPopup(false); // Close the popup
  };
  const [buttonName, setButtonName] = useState(
    props.location?.state ? "Update " : "Publish "
  );

  function getCategory() {
    fetch(`/api/admin/category/parent`)
      .then((response) => response.json())
      .then((data) => {

        if (data.length) {
          setallCategories(data);
        }
      });
  }

  function getSubCategory() {
    fetch(`/api/admin/category/` + cat)
      .then((response) => response.json())
      .then((data) => {

        if (data) {
          setallSubCategories(data);
        }
      });
  }

  useEffect(() => {
    getCategory();
    console.log("props", props)
    if (props?.location?.state) {
      getProduct(props?.location?.state)
    }
    // addColorVariant();
  }, []);

  useEffect(() => {
    if (cat) {
      getSubCategory();
    }
    else {
      setallSubCategories([])
    };
  }, [cat]);


  async function publishProduct(isActive) {
    if (cat === "") {
      setPopupContent("Please select category")
      setShowPopup(true)

    } else if (subcat.length === 0) {
      setPopupContent("Please select sub category")
      setShowPopup(true)
    } else {
      setLoading(true);
      try {
        const requestOptions = {
          method: props.location?.state ? "PUT" : "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: title,
            description: Buffer.from(desc).toString('base64'),
            rich_description: Buffer.from(styelfit).toString('base64'),
            category: cat,
            active: isActive,
            trending: isTrending,
            weight: weight,
            style_no: styleNo,
            sub_category: subcat,
          }),
        };
        var url = "/api/admin/products";
        if (props.location?.state) {
          url = "/api/admin/products/" + props.location?.state;
        }
        var res = await fetch(url, requestOptions);
        var data = await res.json();

        if (data?.product?.id) {
          // var product_id = data.product.id;
          setLoading(false);
          setDesc("")
          setTitle("")
          setStyleAndFitTips("")
          setCat("")
          setsubcat([])
          setisTrending("Y")
          setWeight("")
          setStyleNo("")
          setPopupContent("Product Updated Successfully")
          setShowPopup(true)
          setTimeout(function () {
            history.push('/admin/allproduct');
          }, 3000);
        }
      } catch (e) {
        setLoading(false);
        setPopupContent("Error in uploading product: " + e);
        setShowPopup(true)
      }
    }
  }


  async function getProduct(id) {
    var res = await fetch(`/api/admin/products/${id}`);
    var data = await res.json();
    if (data?.product?.id) {
      setTitle(data.product.name)
      setDesc(data.product.description)
      setStyleAndFitTips(data.product.rich_description)
      setCat(data.product.category)
      setsubcat(data.product.sub_categories.split(", ").map(Number))
      setWeight(data.product.weight)
      setStyleNo(data.product.style_no)
    } else {
      setPopupContent("Product not found")
      setShowPopup(true)
    }
  }


  const handleChange_category = (event) => {
    const {
      target: { value },
    } = event;
    setsubcat(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Header></Header>
      <CustomModal
        type="success"
        content={popupContent}
        showPopup={showPopup}
        onClose={handlePopupClose}
      />
      <main id="main" className="main">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-6">
              <div className="pagetitle">
                <h1>Add Product</h1>
                <div className="adminBreadcrum">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Add Product</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 justify-content-end d-flex align-items-center gap-2">
              <button type="button" className="whiteBtn">
                {" "}
                Discard
              </button>
              {/* <button
                type="button"
                className="whiteBtn"
                onClick={() => {
                  publishProduct("N");
                }}
              >
                {" "}
                Save draft
              </button> */}
              <button
                type="submit"
                className="whiteBtn"
                onClick={() => {
                  publishProduct("Y");
                }}
              >
                {" "}
                {buttonName} {" Product"}
              </button>
            </div>
          </div>
          <div className="row">
            <section className="section addProduct">
              <div>
                <form className="mb-9">
                  <div className="row">
                    <div className="col-xl-8 col-12">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Title</h5>
                          <input
                            value={title}
                            onInput={(e) => setTitle(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''))}
                            placeholder="Write title here..."
                            className="form-control"
                          />
                        </div>
                      </div>
                      {/* <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Price</h5>
                          <div className="row">
                            <div className="col-lg-6 col-12">
                              <h5 className="mb-2 text-1000">Regular price</h5>
                              <input
                                value={regularPrice}
                                onInput={(e) => setRegularPrice(e.target.value)}
                                placeholder="₹"
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="col-lg-6 col-12">
                              <h5 className="mb-2 text-1000">Sale price</h5>
                              <input
                                value={sellingPrice}
                                onInput={(e) => setSellingPrice(e.target.value)}
                                placeholder="₹"
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Details</h5>
                          <ReactQuill
                            className="quillEditor"
                            value={desc}
                            onChange={(value) => { setDesc(value) }}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"],
                                ["blockquote", "code-block"],
                                [{ header: 1 }, { header: 2 }],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ script: "sub" }, { script: "super" }],
                                [{ indent: "-1" }, { indent: "+1" }],
                                [{ direction: "rtl" }],
                                [{ size: ["small", false, "large", "huge"] }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }],
                                [{ font: [] }],
                                [{ align: [] }],
                                ["clean"],
                              ],
                            }}
                          />
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Style & Fit Tips</h5>
                          <ReactQuill
                            value={styelfit}
                            onChange={(value) => setStyleAndFitTips(value)}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"],
                                ["blockquote", "code-block"],
                                [{ header: 1 }, { header: 2 }],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ script: "sub" }, { script: "super" }],
                                [{ indent: "-1" }, { indent: "+1" }],
                                [{ direction: "rtl" }],
                                [{ size: ["small", false, "large", "huge"] }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }],
                                [{ font: [] }],
                                [{ align: [] }],
                                ["clean"],
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      {/* <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Image</h5>
                          <div className="productMainImage">
                            <img
                              src={
                                typeof file == "string"
                                  ? file
                                  : URL.createObjectURL(file)
                              }
                              width="100%"
                            />
                          </div>
                          <input type="file" onChange={handleChange} />
                        </div>
                      </div> */}
                      {/* <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Product Gallery Images</h5>
                          <div className="galleryImages">
                            {showfiles.map((file) => {
                              return (
                                <img
                                  src={
                                    typeof file == "string"
                                      ? file
                                      : URL.createObjectURL(file)
                                  }
                                  width="100%"
                                />
                              );
                            })}
                          </div>
                          <input
                            multiple
                            type="file"
                            onChange={handleChange_allfiles}
                          />
                        </div>
                      </div> */}
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Organize</h5>
                          <div className="gx-3 gy-4 row">
                            <div className="col-xl-12 col-sm-6 col-12">
                              <div className="d-flex gap-2 mb-2">
                                <h5 className="mb-0 text-1000">Trending</h5>
                              </div>
                              <select
                                value={isTrending}
                                onChange={(e) => setisTrending(e.target.value)}
                                aria-label="trending"
                                className="form-select"
                              >
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                              </select>
                            </div>
                            <div className="col-xl-12 col-sm-6 col-12">
                              <div className="d-flex gap-2 mb-2">
                                <h5 className="mb-0 text-1000">Category</h5>
                                <a className="fw-bold fs-9" href="/addCategory">
                                  Add new category
                                </a>
                              </div>
                              <select
                                value={cat}
                                onChange={(e) => setCat(e.target.value)}
                                aria-label="category"
                                className="form-select"
                              >
                                <option value="">Select</option>
                                {allCategories.map((category) => {
                                  return (
                                    <option value={category.id}>
                                      {category.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            {allSubCategories.length > 0 && (
                              <div className="col-xl-12 col-sm-6 col-12">
                                <div className="d-flex gap-2 mb-2">
                                  <h5 className="mb-0 text-1000">
                                    Sub Category
                                  </h5>
                                </div>

                                <FormControl sx={{ m: 1, width: 300 }}>
                                  <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                                  <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={subcat}
                                    onChange={handleChange_category}
                                    input={<OutlinedInput label="Category" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                    renderValue={(selected) =>
                                      selected.map((id) => {
                                        const category = allSubCategories.find((cat) => cat.id === id);
                                        return category ? category.name : id;
                                      }).join(', ')
                                    }
                                  >
                                    {allSubCategories.map((category) => (
                                      <MenuItem key={category.id} value={category.id}>
                                        <Checkbox checked={subcat.indexOf(category.id) > -1} />
                                        <ListItemText primary={category.name} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            )}

                            <div className="col-xl-12 col-sm-6 col-12">
                              <h5 className="mb-2 text-1000">
                                Product Estimated Weight <span>(kg)</span>
                              </h5>
                              <input
                                value={weight}
                                onInput={(e) => setWeight(e.target.value)}
                                type="number"
                                placeholder="0"
                                className="form-control"
                              />
                            </div>
                            <div className="col-xl-12 col-sm-6 col-12">
                              <h5 className="mb-2 text-1000">Style No.</h5>
                              <input
                                value={styleNo}
                                onInput={(e) => setStyleNo(e.target.value)}
                                placeholder="0"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}

export default ModifyProduct;

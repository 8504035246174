import React from "react";
function footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="FootData">
              <img src="../images/whiteLogo.png" />
              <p>© 2024 Vastra Kala. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default footer;

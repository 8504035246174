import React, { useState, useEffect } from "react";
import axios from 'axios';
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";

function Success({ history, location }) {

    async function VerifyOrder() {
        const params = new URLSearchParams(location.search);
        const order_id = params.get('order_id');
        const PayerID = params.get('PayerID');
        const paymentId = params.get('paymentId');
        var payload = null;
        try {
            if (PayerID && paymentId) {
                payload = JSON.stringify({ order_id, PayerID, paymentId })
            } else {
                payload = JSON.stringify({ order_id, PayerID, paymentId })
            }
            const response = await fetch('/api/checkout/payment/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: payload, // Replace with the actual Payment Intent ID
            });

            if (response.ok) {
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error('Error verifying payment:', error);
        }
    }


    useEffect(() => {
        VerifyOrder()
    }, []);





    return (<>
        {/* <Header></Header> */}
        <div style={{ height: 500 }}>Payment completed successfully</div>
        <Footer></Footer>
    </>
    )
}

export default Success